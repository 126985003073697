export default [
  {
    id: 1,
    name: "Costco - Etobicoke",
    address: "50 Queen Elizabeth Blvd, Etobicoke, ON M8Z 1M1",
    icon: "cart",
    lat: 43.623637870635996,
    lng: -79.50765967494333,
    link: "https://goo.gl/maps/nCu5hme5KCMhea4b6",
  },
  {
    id: 2,
    name: "Costco - Overlea",
    address: "42 Overlea Blvd, Toronto, ON M4H 1B6",
    icon: "cart",
    lat: 43.70751510672176,
    lng: -79.34835753651751,
    link: "https://goo.gl/maps/ao7xshyEbh7CzWg29",
  },
  {
    id: 3,
    name: "Costco - Richmond Hill",
    address: "35 John Birchall Rd, Toronto, ON L4S 0B2",
    icon: "cart",
    lat: 43.90188580961387,
    lng: -79.39076992890216,
    link: "https://goo.gl/maps/96b6qBPnj3izrPob9",
  },
  {
    id: 4,
    name: "Costco - Mississauga South",
    address: "3180 Laird Rd, Mississauga, ON L5L 6A5",
    icon: "cart",
    lat: 43.52122591206854,
    lng: -79.68900508843846,
    link: "https://goo.gl/maps/VEVozcuC3fxnCHgk9",
  },
  {
    id: 5,
    name: "Costco - Downsview",
    address: "100 Billy Bishop Way, Toronto, ON M3K 2C8",
    icon: "cart",
    lat: 43.73152586308908,
    lng: -79.45614496138266,
    link: "https://goo.gl/maps/HbsgoBGdzgxVtEBe7",
  },
  {
    id: 6,
    name: "Costco - Burlington",
    address: "1225 Brant St, Burlington, ON L7P 1X7",
    icon: "cart",
    lat: 43.344520330486915,
    lng: -79.82154055793816,
    link: "https://goo.gl/maps/EGb3CYgamRoAXcKo8",
  },
  {
    id: 7,
    name: "Costco - Montreal",
    address: "300 Rue Bridge Montreal, QC H3K 2C3",
    icon: "cart",
    lat: 45.485920868521106,
    lng: -73.55367388837472,
    link: "https://goo.gl/maps/uUVNv9K6TGf52XvTA",
  },
  {
    id: 8,
    name: "Costco - Sherbrooke",
    address: "3800 Bd de Monseigneur-Fortier, Sherbrooke, QC J1L 0C8",
    icon: "cart",
    lat: 45.42355772183683,
    lng: -71.96150786930879,
    link: "https://goo.gl/maps/3Uqk2eCJVbZkTnDNA",
  },
  {
    id: 9,
    name: "On The Move Organics",
    address: "521 Burbrook Place, London, ON N5W 4B5",
    icon: "cart",
    lat: 42.997627538895095,
    lng: -81.21691073078313,
    link: "https://goo.gl/maps/7D7FH1gRgG4qUgNU9",
  },
  {
    id: 10,
    name: "La Bottega",
    address: "64 George Street, Ottawa, ON K1N 5V9",
    icon: "cart",
    lat: 45.427019391643924,
    lng: -75.6918102730326,
    link: "https://g.page/LaBottegaNicastro?share",
  },
  {
    id: 11,
    name: "Today's Natural Solutions Whitby",
    address: "1618 Dundas Street East Whitby, ON L1N 2K8",
    icon: "cart",
    lat: 43.88783307937402,
    lng: -78.91118730191874,
    link: "https://goo.gl/maps/giHCxJ39xFhHXA1s7",
  },
  {
    id: 12,
    name: "Yummy Market Maple",
    address: "1390 Major MacKenzie Drive West, Maple, ON	L6A 4H6",
    icon: "cart",
    lat: 43.863709928541724,
    lng: -79.48382848657545,
    link: "https://g.page/yummy-market-maple?share",
  },
  {
    id: 13,
    name: "IGA Extra - St-Jérôme",
    address: "1005 boul Grand-Heron, Saint-Jérôme, QC	J7Y 3P2",
    icon: "cart",
    lat: 45.761165903853694,
    lng: -74.01531544603766,
    link: "https://goo.gl/maps/uehqcL7gAKtBujZVA",
  },
  {
    id: 14,
    name: "Metro Plus Fournier Cap-de-la-Madeleine",
    address: "850 boul Thibeau, Trois-Rivières, QC G8T 7A6",
    icon: "cart",
    lat: 46.38772729130729,
    lng: -72.55295600183666,
    link: "https://goo.gl/maps/PANgEHnmydVUaG8Y9",
  },
  {
    id: 15,
    name: "Bloor Street Market",
    address: "55 Bloor Street West, Toronto, ON	M4W 1A5",
    icon: "cart",
    lat: 43.66979731294023,
    lng: -79.38857065774562,
    link: "https://goo.gl/maps/Dongp4DrrWrPLktA6",
  },
  {
    id: 16,
    name: "Market Fresh Meat & Produce Guelph",
    address: "10 Paisley Street, Guelph, ON	N1H 2N6",
    icon: "cart",
    lat: 43.54503124145067,
    lng: -80.25259487309373,
    link: "https://g.page/MarketFreshGuelph?share",
  },
  {
    id: 17,
    name: "Cedar's & Co Food Market, Bank St",
    address: "1255 Bank Street, Ottawa, ON	K1S 3Y2",
    icon: "cart",
    lat: 45.39117324431678,
    lng: -75.67981407118162,
    link: "https://goo.gl/maps/fEWUj5mfnrxEDd62A",
  },
  {
    id: 18,
    name: "La Moisson",
    address: "360, rue Sicard, Sainte-Thérèse, QC	J7E 3X4",
    icon: "cart",
    lat: 45.64409759401047,
    lng: -73.8629709865173,
    link: "https://goo.gl/maps/AuSV9JtV5nbcniCm9",
  },
  {
    id: 19,
    name: "Vincenzo's Fine Food",
    address: "150 Caroline Street, Waterloo, ON	N2J 1R1",
    icon: "cart",
    lat: 43.459707782054835,
    lng: -80.51940458658835,
    link: "https://goo.gl/maps/PFTNxZL66t7RK2AWA",
  },
  {
    id: 20,
    name: "Battaglia Lorne Park Marketplace",
    address: "1150 Lorne Park Road, Mississauga, ON	L5H 3A5",
    icon: "cart",
    lat: 43.53076096226686,
    lng: -79.6154758865861,
    link: "https://goo.gl/maps/A13i2MvBJtCWmWZ17",
  },
  {
    id: 21,
    name: "The Market & Smør",
    address: "39 King Street East, Cobourg, ON	K9A 1K6",
    icon: "cart",
    lat: 43.96036143304055,
    lng: -78.16383490191643,
    link: "https://g.page/marketsmor?share",
  },
  {
    id: 22,
    name: "Simply Zen",
    address: "183 Ottawa Street North, Hamilton, ON	L8H 3Z4",
    icon: "cart",
    lat: 43.24793683541832,
    lng: -79.81782690193917,
    link: "https://goo.gl/maps/JVjv3pUCFwyTPzUH7",
  },
  {
    id: 23,
    name: "Metro - Rosemère",
    address: "348 chemin de la Grand Cote, Rosemère, QC	J7A 1K3",
    icon: "cart",
    lat: 45.63690052683071,
    lng: -73.79130010186168,
    link: "https://goo.gl/maps/HGtdV6YWAoZYz9PL7",
  },
  {
    id: 24,
    name: "Coco Market",
    address: "1064 Yonge Street	Toronto	ON	M4W 2L4",
    icon: "cart",
    lat: 43.67773574722174,
    lng: -79.3899116442534,
    link: "https://goo.gl/maps/42KHt4qDJ1mLcnqT7",
  },
  {
    id: 25,
    name: "Rachelle-Bery Health Store",
    address: "2346 Rue Beaubien E, Montréal, QC	H2G 1M9",
    icon: "cart",
    lat: 45.547650654693605,
    lng: -73.59271330186462,
    link: "https://goo.gl/maps/hXebbcdRnPhpfjnH6",
  },
  {
    id: 26,
    name: "Pleine Lune",
    address: "495 boul du Séminaire Nord, Saint-Jean-sur-Richelieu, QC	J3B 5L5",
    icon: "cart",
    lat: 45.315245372148816,
    lng: -73.26436841721643,
    link: "https://goo.gl/maps/ngnjbDwHU4VyXNc68",
  },
  {
    id: 27,
    name: "Apple Factory",
    address: "10024 Mississauga Road, Brampton, ON	L7A 0B8",
    icon: "cart",
    lat: 43.667227326231995,
    lng: -79.83074691726983,
    link: "https://goo.gl/maps/s5V8tUDeXGqXcZhu9",
  },
  {
    id: 28,
    name: "SmoothiesGo Marche Naturello Foods",
    address: "4969 Rue Levy, Saint-Laurent, QC H4R 2N9",
    icon: "cart",
    lat: 45.514863728188125,
    lng: -73.66454040186571,
    link: "https://g.page/SmoothiesGo?share",
  },
  {
    id: 29,
    name: "Luminate Co Wellness Market",
    address: "486 Starboard Dr., Bedford, NS B3M 0N6",
    icon: "cart",
    lat: 44.697235628406894,
    lng: -63.6828775962957,
    link: "https://g.page/Luminate_Co?share",
  },
  {
    id: 30,
    name: "Marche Nuvo",
    address: "1263 rue Jean-Talon Est, Montréal, ON	H2K 1W4",
    icon: "cart",
    lat: 45.544970598213126,
    lng: -73.61081768836021,
    link: "https://goo.gl/maps/yd4Y8Aq2uyAafTgY8",
  },
  {
    id: 31,
    name: "McEwan - Wellington",
    address: "66 Wellington Street West, Toronto, ON M5K 1E7",
    icon: "cart",
    lat: 43.64799699816403,
    lng: -79.38144798095163,
    link: "https://goo.gl/maps/kgAeiywHg5nBVtZp9",
  },
  {
    id: 32,
    name: "Vested Interest Trading",
    address: "1407 Main Street North, Callander, ON	P0H 1H0",
    icon: "cart",
    lat: 46.23449354400549,
    lng: -79.37184370282559,
    link: "https://g.page/vested-interest-trading-on?share",
  },
  {
    id: 33,
    name: "The Peanut Mill",
    address: "191 Welland Avenue, St Catherines, ON	L2R 2P2",
    icon: "cart",
    lat: 43.16662905917919,
    lng: -79.23954165961376,
    link: "https://goo.gl/maps/cQNQwCWq4o7S8pcY9",
  },
  {
    id: 34,
    name: "Nature's Best",
    address: "7 Glenwood Drive, Barrie, ON L4N 1R3",
    icon: "cart",
    lat: 43.166707311841854,
    lng: -79.23954702405986,
    link: "https://goo.gl/maps/Msa2EsTnMMAqYkCC9",
  },
  {
    id: 35,
    name: "Chez Clementine",
    address: "2339 rue Beabien Est, Montréal, QC	H2G 1N1",
    icon: "cart",
    lat: 45.547710918454165,
    lng: -73.59307267302859,
    link: "https://goo.gl/maps/igic1CfzMMXrtwTn8",
  },
  {
    id: 36,
    name: "IGA Extra - Montréal",
    address: "5600 boul Henri-Bourassa Ouest, Montréal, QC	H4R 0M6",
    icon: "cart",
    lat: 45.517566436781,
    lng: -73.71515691535758,
    link: "https://goo.gl/maps/7w6AzDXbBi2FEUXr9",
  },
  {
    id: 37,
    name: "Les 5 Saisons",
    address: "1180 avenue Bernard, Outremont, QC H2V 1V3",
    icon: "cart",
    lat: 45.52083402543055,
    lng: -73.60776448837353,
    link: "https://goo.gl/maps/dGwKiFRuDN2DUkSf9",
  },
  {
    id: 38,
    name: "Nature's Emporium - Burlington",
    address: "2180 Itabashi Way, Burlington, ON	L7M 5A5",
    icon: "cart",
    lat: 43.38748326485954,
    lng: -79.79428017072362,
    link: "https://goo.gl/maps/2xUrTCUryyfeCbT3A",
  },
  {
    id: 39,
    name: "Ambrosia - Vaughan",
    address: "57 Northview Boulevard, Vaughan, ON	L4L 8X9",
    icon: "cart",
    lat: 43.791312533048114,
    lng: -79.54355241356157,
    link: "https://goo.gl/maps/Rmtk2LtygVMj6nGw9",
  },
  {
    id: 40,
    name: "Fromagerie Atwater",
    address: "134 ave Atwater, Montréal, QC H4C 2G3",
    icon: "cart",
    lat: 45.47986357708775,
    lng: -73.576538717211,
    link: "https://goo.gl/maps/YjtwdCcqgFvfERMCA",
  },
  {
    id: 41,
    name: "Metro - Westmount",
    address: "4840 rue Sherbrooke Ouest, Westmount, QC	H3Z 1G8",
    icon: "cart",
    lat: 45.47926908345226,
    lng: -73.60276736139109,
    link: "https://goo.gl/maps/ekzf8MQ2f6VSGNcYA",
  },
  {
    id: 42,
    name: "Foodland - Pringle Creek Market",
    address: "728 Anderson Street, Whitby, ON	L1N 3V6",
    icon: "cart",
    lat: 43.89414805956755,
    lng: -78.92784387308252,
    link: "https://goo.gl/maps/8YehdR42buD1uBVPA",
  },
  {
    id: 43,
    name: "Amici Food & Beverage Company",
    address: "863 Barrydown Road, Sudbury, ON P3A 3T9",
    icon: "cart",
    lat: 46.516519354316955,
    lng: -80.94480484416032,
    link: "https://goo.gl/maps/WaL3AjVDvYmSbonr8",
  },
  {
    id: 44,
    name: "The Gentle Rain Natural Food",
    address: "30 Rebecca Street, Stratford, ON	N5A 3P1",
    icon: "cart",
    lat: 43.36942896238786,
    lng: -80.97863513077134,
    link: "https://goo.gl/maps/QxB9idk4nB8namgt9",
  },
  {
    id: 45,
    name: "Ambrosia - Thornhill",
    address: "55 Doncaster Avenue, Thornhill, ON	L3T 1L7",
    icon: "cart",
    lat: 43.803591218124296,
    lng: -79.4165309730854,
    link: "https://goo.gl/maps/EJYKJLmd8MPGVt4GA",
  },
  {
    id: 46,
    name: "Nature's Emporium - Newmarket",
    address: "16655 Yonge Street, Newmarket, ON	L3X 1V6",
    icon: "cart",
    lat: 44.03554387107046,
    lng: -79.4722362712258,
    link: "https://goo.gl/maps/1iGbpDaHxNQwrRwU8",
  },
  {
    id: 47,
    name: "Metro - Marcanio",
    address: "1550 rue Bélanger, Montréal, QC	H2G 1A8",
    icon: "cart",
    lat: 45.5456258225324,
    lng: -73.60405915953677,
    link: "https://goo.gl/maps/AaS1mu6Cm1x9LWEQ6",
  },
  {
    id: 48,
    name: "Metro - Glebe",
    address: "754 Bank Street, Ottawa, ON	K1S 3V6",
    icon: "cart",
    lat: 45.40352210117587,
    lng: -75.68893393070545,
    link: "https://goo.gl/maps/NGw6D2cMhaqtv6MA6",
  },
  {
    id: 49,
    name: "Metro - Marche Bélair",
    address: "180 rue Beaudry Nord, Joliette, QC J6E 6A6",
    icon: "cart",
    lat: 46.02106493066009,
    lng: -73.45092331534097,
    link: "https://goo.gl/maps/nyCLieAEcctnYXDY6",
  },
  {
    id: 50,
    name: "Nature's Emporium - Maple",
    address: "2535 Major Mackenzie Drive, Maple, ON	L6A 1C6",
    icon: "cart",
    lat: 43.852734736385266,
    lng: -79.52107560191983,
    link: "https://goo.gl/maps/ez1hyD6jbPWHayaX6",
  },
  {
    id: 51,
    name: "Marché Tradition - Yvanhoe Gosselin et Fils Ltée",
    address: "17 rue Principale, Frelighsburg, QC	J0J 1C0",
    icon: "cart",
    lat: 45.05473825488752,
    lng: -72.83759564420883,
    link: "https://goo.gl/maps/rN5j4pN8DWTpRpqR9",
  },
  {
    id: 52,
    name: "The Natural Food Pantry - Billings Bridge",
    address: "2277 Riverside Drive, Ottawa, ON	K1H 7X6",
    icon: "cart",
    lat: 45.38580138835037,
    lng: -75.67748843070603,
    link: "https://g.page/Natural-Food-Pantry---Billings?share",
  },
  {
    id: 53,
    name: "Rachelle-Bery Health Store",
    address: "4201 rue St-Denis, Montreal, QC	H2J 2K9",
    icon: "cart",
    lat: 45.52236255408125,
    lng: -73.57829281535737,
    link: "https://goo.gl/maps/GL8W8NqjtLhLFpt5A",
  },
  {
    id: 54,
    name: "Nature's Emporium - Woodbridge",
    address: "8401 Weston Road, Woodbridge, ON L4L 1A6",
    icon: "cart",
    lat: 43.80703919045334,
    lng: -79.55073898657724,
    link: "https://goo.gl/maps/c7uPpnjALnRA3qzS6",
  },
  {
    id: 55,
    name: "Ambrosia - Avenue Road",
    address: "1725 Avenue Road, Toronto, ON M5M 3Y5",
    icon: "cart",
    lat: 43.728858742209646,
    lng: -79.41779201726794,
    link: "https://goo.gl/maps/Kq4YNPB3WCAX3YLp6",
  },
  {
    id: 56,
    name: "National Food Shop",
    address: "4903 rue Sherbrooke Ouest, Westmount, QC H3Z 1H2",
    icon: "cart",
    lat: 45.47823652760616,
    lng: -73.60467244234275,
    link: "https://goo.gl/maps/suSbMY7a4xUMykLX6",
  },
  {
    id: 57,
    name: "Marche Toit Rouge",
    address: "160 boul Brien, Repentigny, QC	J6A 6V1",
    icon: "cart",
    lat: 45.74643064025129,
    lng: -73.46133780000594,
    link: "https://goo.gl/maps/k1sP9VYhreRbxZ9PA",
  },
  {
    id: 58,
    name: "Alim Poivre et Sel",
    address: "3245 rue Masson, Montréal, QC	H1Y 1Y4",
    icon: "cart",
    lat: 45.550937233993395,
    lng: -73.57369238837254,
    link: "https://g.page/AlimentationPoivreetSel?share",
  },
  {
    id: 59,
    name: "IGA Extra - Carignan",
    address: "2369 chemin de Chambly, Carignan, QC J3L 4N3",
    icon: "cart",
    lat: 45.458546539690104,
    lng: -73.31208110001539,
    link: "https://goo.gl/maps/SyMcnqtWMZHUv89C6",
  },
  {
    id: 60,
    name: "IGA - Després Ltée",
    address: "44 Principale Nord Street, Sutton, QC	J0E 2K0",
    icon: "cart",
    lat: 45.11038803071273,
    lng: -72.61583563071507,
    link: "https://goo.gl/maps/qT4z3LtiS6WXr4s6A",
  },
  {
    id: 61,
    name: "A Healthier You",
    address: "1237 Garrison Road, Fort Erie, ON L2A 1P2",
    icon: "cart",
    lat: 42.90464088295997,
    lng: -78.96110025776994,
    link: "https://goo.gl/maps/yGfXK5UKt8qKpPeG7",
  },
  {
    id: 62,
    name: "D & A Fine Meats Market",
    address: "1885 Paris Street, Sudbury, ON P3E 3C5",
    icon: "cart",
    lat: 46.454257657166494,
    lng: -81.0027263595065,
    link: "https://goo.gl/maps/g4SDDhpTFeuigQoZ9",
  },
  {
    id: 63,
    name: "The Cheese Gallery",
    address: "11 Bruce Street South, Thornbury, ON N0G 2P0",
    icon: "cart",
    lat: 44.561798506398794,
    lng: -80.453041201897,
    link: "https://goo.gl/maps/L7x7f1mcsYuJQ8Ta9",
  },
  {
    id: 64,
    name: "Surf and Turf Blue Mountains",
    address: "209574 Highway 26, Blue Mountains, ON	L9Y 0T5",
    icon: "cart",
    lat: 44.5287190909358,
    lng: -80.33175358655396,
    link: "https://goo.gl/maps/KRDTyCBRhGjoEVGC6",
  },
  {
    id: 65,
    name: "Kent Street Market",
    address: "134 Kent Street, Charlottetown, PE C1A 8R8",
    icon: "cart",
    lat: 46.235848068643435,
    lng: -63.12809185951381,
    link: "https://goo.gl/maps/PthjWESEs6mmsnmx7",
  },
  {
    id: 66,
    name: "Eagle Lake Country Market",
    address: "2622 Eagle Lake Road, Eagle Lake, ON K0M 1M0",
    icon: "cart",
    lat: 45.129253773139276,
    lng: -78.5099275595504,
    link: "https://goo.gl/maps/pMC5xzxASov9wTuY6",
  },
  {
    id: 67,
    name: "Rooted Cafe & Market",
    address: "338 Park Street, Victoria Harbour, ON L0K 2A0",
    icon: "cart",
    lat: 44.75154604148982,
    lng: -79.76537305771065,
    link: "https://g.page/rootedmidland19?share",
  },
  {
    id: 68,
    name: "Valmont Ontario",
    address: "3930 rue Ontario, Montréal, QC H1W 1S9",
    icon: "cart",
    lat: 45.549082000465305,
    lng: -73.54186358652049,
    link: "https://goo.gl/maps/i9icgjFcPemKjWj48",
  },
  {
    id: 69,
    name: "Simcoe Harvest",
    address: "31 King Street, Barrie, ON L4N 6B5",
    icon: "cart",
    lat: 44.328609873998964,
    lng: -79.70075533074052,
    link: "https://goo.gl/maps/E2qKn7oxWF7WyDVT8",
  },
  {
    id: 70,
    name: "Les 5 Saisons",
    address: "1280, Avenue Greene, Westmount, ON H3Z 1C2",
    icon: "cart",
    lat: 45.48678983058775,
    lng: -73.58922624419469,
    link: "https://g.page/5SaisonsGreene?share",
  },
  {
    id: 71,
    name: "Noor Food Market",
    address: "332 Richmond Road, Ottawa, ON K1Z 5C7",
    icon: "cart",
    lat: 45.392335299660054,
    lng: -75.75335688652565,
    link: "https://g.page/noorfoodmarket?share",
  },
  {
    id: 72,
    name: "The Tulip",
    address: "340 Westmoreland Avenue North, Toronto, ON M6H 3A7",
    icon: "cart",
    lat: 43.6707855678926,
    lng: -79.43451458658159,
    link: "https://goo.gl/maps/yoHus2bHVQ2Mhhj59",
  },
  {
    id: 73,
    name: "La Belle Fromage",
    address: "519 Notre Dame Street, Belle River, ON N0R 1A0",
    icon: "cart",
    lat: 42.29348849311393,
    lng: -82.70727195964126,
    link: "https://g.page/labellefromage?share",
  },
  {
    id: 74,
    name: "Yi Fang Bubble Tea Shop - Toronto",
    address: "672 Yonge St, Toronto, ON	M4Y 2A6",
    icon: "tea",
    lat: 43.667481921125834,
    lng: -79.38576100869956,
    link: "https://g.page/yifangdowntown?share",
  },
  {
    id: 75,
    name: "Yi Fang Bubble Tea Shop - Scarborough",
    address: "4002 Sheppard Ave E #107, Scarborough, ON	M1S 4R5",
    icon: "tea",
    lat: 43.783910522628176,
    lng: -79.28729105774198,
    link: "https://goo.gl/maps/H1MbrEuQMB4vVbeBA",
  },
  {
    id: 76,
    name: "Yi Fang Bubble Tea Shop - Downtown Toronto",
    address: "315 Spadina Ave, Toronto, ON M5T 2E9",
    icon: "tea",
    lat: 43.65435316684976,
    lng: -79.39817151541813,
    link: "https://g.page/yifangchinatownto?share",
  },
  {
    id: 77,
    name: "Yi Fang Bubble Tea Shop - North York",
    address: "5425 Yonge St, North York, ON M2N 5R6",
    icon: "tea",
    lat: 43.77655954643818,
    lng: -79.41440367308631,
    link: "https://g.page/yifangnorthyork?share",
  },
  {
    id: 78,
    name: "Yi Fang Bubble Tea Shop - Scarborough",
    address: "633 Silver Star Blvd #115, Scarborough, ON M1V 5N1",
    icon: "tea",
    lat: 43.82226069835956,
    lng: -79.29847930006873,
    link: "https://goo.gl/maps/mWyiJHRDKQAffSWM6",
  },
  {
    id: 79,
    name: "Yi Fang Bubble Tea Shop - Markham",
    address: "3235 Hwy 7 unit27, Markham, ON L3R 3P9",
    icon: "tea",
    lat: 43.84906104730156,
    lng: -79.34779802890385,
    link: "https://goo.gl/maps/tHWShU6QxrX3t3zX7",
  },
  {
    id: 80,
    name: "Yi Fang Bubble Tea Shop - Scarborough",
    address: "1571 Sandhurst Cir #115B, Scarborough, ON M1V 1V2",
    icon: "tea",
    lat: 43.80909917105478,
    lng: -79.26912148657718,
    link: "https://goo.gl/maps/KPLNrS7F7Lskn5eS8",
  },
  {
    id: 81,
    name: "Yi Fang Bubble Tea Shop - Waterloo",
    address: "50 Westmount Rd N Unit D3, Waterloo, ON N2L 6N9",
    icon: "tea",
    lat: 43.46169953804738,
    lng: -80.5377439865883,
    link: "https://g.page/yifangwaterloo?share",
  },
  {
    id: 82,
    name: "Yi Fang Bubble Tea Shop - Hamilton",
    address: "100 Main St E Unit 102, Hamilton, ON L8N 3W7",
    icon: "tea",
    lat: 43.25442881542703,
    lng: -79.86551701543088,
    link: "https://goo.gl/maps/Qb8vaqFj8fgQo9wq8",
  },
  {
    id: 83,
    name: "Dark Horse Espresso Bar - Canary",
    address: "416 Front Street East, Toronto, ON M5A 0H8",
    icon: "tea",
    lat: 43.65334233303455,
    lng: -79.35714745959828,
    link: "https://goo.gl/maps/2cvUaXvVXohf8t459",
  },
  {
    id: 84,
    name: "Les Entetees, Cafe Sante",
    address: "345 boul. De l’ange gardien, L'Assomption, QC J5W 1S2",
    icon: "tea",
    lat: 45.82781479114408,
    lng: -73.42467111654416,
    link: "https://goo.gl/maps/bg6BWp2TvmEwkCgX6",
  },
  {
    id: 85,
    name: "Bar'kada",
    address: "745 Queen St W, Toronto, ON M6J 1G1",
    icon: "tea",
    lat: 43.647156595729705,
    lng: -79.40719918884038,
    link: "https://g.page/barkadatoronto?share",
  },
  {
    id: 86,
    name: "Highland Farms - Vaughan",
    address: "9940 Dufferin Street, Vaughan, ON L6A 4K5",
    icon: "cart",
    lat: 43.873910915296754,
    lng: -79.48563311479225,
    link: "https://goo.gl/maps/4tPJReE9HNbnfrB2A",
  },
  {
    id: 87,
    name: "Highland Farms - Scarborough",
    address: "850 Ellesmere Road, Scarborough, ON M1P 2W5",
    icon: "cart",
    lat: 43.77830322358511,
    lng: -79.28375934417167,
    link: "https://goo.gl/maps/gXtVKYpZugYsfWF18",
  },
  {
    id: 88,
    name: "Highland Farms - Mississauga",
    address: "50 Matheson Blvd. East, Mississauga, ON L4Z 1N5",
    icon: "cart",
    lat: 43.63190360379177,
    lng: -79.67229697423774,
    link: "https://goo.gl/maps/aGzeF6wq7PSMUY1d7",
  },
  {
    id: 89,
    name: "Organice Garage - Liberty Village",
    address: "42 Hanna Ave, Toronto, ON M6K 1X1",
    icon: "cart",
    lat: 43.6394910688268854,
    lng: -79.41996778454188,
    link: "https://goo.gl/maps/9yZ8Yav36CBoy3TBA",
  },
  {
    id: 90,
    name: "Loblaws",
    address: "585 Queen Street West, Toronto, ON M5V 2B7",
    icon: "cart",
    lat: 43.647857826153086,
    lng: -79.40163000176123,
    link: "https://goo.gl/maps/DfTEXCeynFDPnxDVA",
  },
  {
    id: 91,
    name: "Loblaws",
    address: "1792 Liverpool Road, Pickering, ON L1V 1V9",
    icon: "cart",
    lat: 43.832535465050825,
    lng: -79.09140691710134,
    link: "https://goo.gl/maps/uXjPvpuiYJc3ZAcL7",
  },
  {
    id: 92,
    name: "Loblaws",
    address: "5970 Mclaughlin Road, Mississauga, ON L5R 3X9",
    icon: "cart",
    lat: 43.61496609859229,
    lng: -79.68839717107069,
    link: "https://goo.gl/maps/7aHMAV9MCymN1Ht59",
  },
  {
    id: 93,
    name: "Loblaws",
    address: "17 Leslie Street, Toronto, ON M4M 3H9",
    icon: "cart",
    lat: 43.66119641968414,
    lng: -79.32838525943374,
    link: "https://goo.gl/maps/KJ9ra2PxzRPNz7uUA",
  },
  {
    id: 94,
    name: "Loblaws",
    address: "472 Bayfield Street, Barrie, ON L4M 5A2",
    icon: "cart",
    lat: 44.41159525000662,
    lng: -79.70959773115487,
    link: "https://goo.gl/maps/DxR72xfLRdwH3g2J7",
  },
  {
    id: 95,
    name: "Loblaws",
    address: "60 Carlton Street Toronto ON M5B 1J2",
    icon: "cart",
    lat: 43.66212673222769,
    lng: -79.38017073118284,
    link: "https://goo.gl/maps/dBDbwXiyECKyvZJV6",
  },
  {
    id: 96,
    name: "Loblaws",
    address: "5095 Yonge Street, Toronto, ON M2N 6Z4",
    icon: "cart",
    lat: 43.768838194499814,
    lng: -79.41244432507854,
    link: "https://goo.gl/maps/fqAsvKTmvXzHQxwJA",
  },
  {
    id: 97,
    name: "Loblaws",
    address: "5010 Glen Erin Drive, Mississauga ON L5M 6J3",
    icon: "cart",
    lat: 43.55489246450881,
    lng: -79.71504491769326,
    link: "https://goo.gl/maps/GvBrHNKJdDfrSSEf7",
  },
  {
    id: 98,
    name: "Loblaws",
    address: "100 McArthur Road Ottawa, ON K1L 6P9",
    icon: "cart",
    lat: 45.42952168373555,
    lng: -75.66486000228078,
    link: "https://goo.gl/maps/QcV3GLZfoEccWYVn6",
  },
  {
    id: 99,
    name: "Loblaws",
    address: "3040 Wonderland Road South, London, ON N6L 1A6",
    icon: "cart",
    lat: 42.93815219564882,
    lng: -81.27842108703224,
    link: "https://goo.gl/maps/xTPvDG9j5M7y9iwN8",
  },
  {
    id: 100,
    name: "Loblaws",
    address: "2877 Bayview Avenue, Toronto, ON M2K 2S3",
    icon: "cart",
    lat: 43.79385484604793,
    lng: -79.39076837535531,
    link: "https://goo.gl/maps/cy6YqzntctXHGh3X9",
  },
  {
    id: 101,
    name: "Loblaws",
    address: "101 Eglinton Ave East, Toronto, ON M4P 1H4",
    icon: "cart",
    lat: 43.70744562590441,
    lng: -79.39469027351026,
    link: "https://goo.gl/maps/jWFUSebZhLFvNppW7",
  },
  {
    id: 102,
    name: "Loblaws",
    address: "50 Musgrave Street,Toronto, ON M4E 3W2",
    icon: "cart",
    lat: 43.688127207199784,
    lng: -79.287123273511,
    link: "https://goo.gl/maps/1HV2uYzihTHo9iCM9",
  },
  {
    id: 103,
    name: "Loblaws",
    address: "400 Kent Street West, Lindsay, ON K9V 6K2",
    icon: "cart",
    lat: 44.349526011687004,
    lng: -78.76360576407272,
    link: "https://goo.gl/maps/XHDU7rABRXXfucVc8",
  },
  {
    id: 104,
    name: "Loblaws",
    address: "2065A Robertson Road, Napean, ON K2H 5H9",
    icon: "cart",
    lat: 45.32472682233065,
    lng: -75.83245227344928,
    link: "https://goo.gl/maps/s92DU74mkbPrfwpK9",
  },
  {
    id: 105,
    name: "Maxi",
    address: "180 Boul. Barrette Chicoutimi, QC G7H 7W8",
    icon: "cart",
    lat: 48.39827981762192,
    lng: -71.08085173099964,
    link: "https://goo.gl/maps/D2yaJGV49hPnarrP8",
  },
  {
    id: 106,
    name: "Loblaws",
    address: "1740 Richmond Street North, London, ON N5X 2S7",
    icon: "cart",
    lat: 43.028427053023954,
    lng: -81.28217608702893,
    link: "https://goo.gl/maps/ChXZKHTsCkNRWFpN7",
  },
  {
    id: 107,
    name: "Loblaws",
    address: "301 High Tec Road, Richmond Hill, ON L4B 4R2",
    icon: "cart",
    lat: 43.844144641360884,
    lng: -79.41145918884708,
    link: "https://goo.gl/maps/ug6hNP7Ghr37KZsF6",
  },
  {
    id: 108,
    name: "Loblaws",
    address: "650 Dupont Street, Toronto, ON M6G 4B1",
    icon: "cart",
    lat: 43.67184322511077,
    lng: -79.42141764652438,
    link: "https://goo.gl/maps/F4Gb8Uuacdi7aPWD7",
  },
  {
    id: 109,
    name: "Loblaws",
    address: "200 Bullock Drive, Markham, ON L3P 1W2",
    icon: "cart",
    lat: 43.87445067099763,
    lng: -79.28435233838644,
    link: "https://goo.gl/maps/ov5mug3mBArNaq7J9",
  },
  {
    id: 110,
    name: "Loblaws",
    address: "3201 Greenbank Road, Ottawa, ON K2J 4H9",
    icon: "cart",
    lat: 45.26816640307394,
    lng: -75.74471300228691,
    link: "https://goo.gl/maps/hKfAHF77iNcMm2oZ7",
  },
  {
    id: 111,
    name: "Loblaws",
    address: "1100 Princess Street, Kingston, ON K7L 5G8",
    icon: "cart",
    lat: 44.24299807136645,
    lng: -76.51852354465478,
    link: "https://goo.gl/maps/HxzDxGKq2c7ZxoHA6",
  },
  {
    id: 112,
    name: "Loblaws",
    address: "1980 Baseline Road, Ottawa, ON K2C 0C6",
    icon: "cart",
    lat: 45.352756246214604,
    lng: -75.7604071715999,
    link: "https://goo.gl/maps/VjNhumCGsaeFdnH76",
  },
  {
    id: 113,
    name: "Loblaws",
    address: "1980 Ogilvie Road, Ottawa, ON K1J 9L3",
    icon: "cart",
    lat: 45.43275831808609,
    lng: -75.61035645995157,
    link: "https://goo.gl/maps/NtnNqfniVMLbiAN28",
  },
  {
    id: 114,
    name: "Loblaws",
    address: "2375 Highway #2, Bowmanville, ON L1C 5A3",
    icon: "cart",
    lat: 43.90981434215979,
    lng: -78.7084560592719,
    link: "https://goo.gl/maps/uv5dJhEfwYzHptjq6",
  },
  {
    id: 115,
    name: "Loblaws",
    address: "380 The East Mall, Etobicoke, ON M9B 6L5",
    icon: "cart",
    lat: 43.64404207522069,
    lng: -79.56014518885449,
    link: "https://goo.gl/maps/U7G2SLpnMRQSyDwn9",
  },
  {
    id: 116,
    name: "Loblaws",
    address: "10 Lower Jarvis Street, Toronto, ON M5E 1Z2",
    icon: "cart",
    lat: 43.645652124620185,
    lng: -79.36978900234807,
    link: "https://goo.gl/maps/YxZX7huD5DXKcPLF6",
  },
  {
    id: 117,
    name: "Loblaws",
    address: "1460 Merivale Road, Ottawa, ON K2E 5P2",
    icon: "cart",
    lat: 45.362140052762925,
    lng: -75.73534017344785,
    link: "https://goo.gl/maps/nf4AyxLwPKvwKUVF8",
  },
  {
    id: 118,
    name: "Loblaws",
    address: "1048 Midland Avenue, Kingston, ON K7P 2X9",
    icon: "cart",
    lat: 44.26092548930507,
    lng: -76.57406737533785,
    link: "https://goo.gl/maps/hQMmy8Feze8SNZKt7",
  },
  {
    id: 119,
    name: "Loblaws",
    address: "250 Lakeshore Road West, Mississauga, ON L5H 1G6",
    icon: "cart",
    lat: 43.54599916822773,
    lng: -79.5922778330355,
    link: "https://goo.gl/maps/PkyqFi2m6czWcRRx6",
  },
  {
    id: 120,
    name: "Loblaws",
    address: "11 Redway Road, Toronto, ON M4H 1P6",
    icon: "cart",
    lat: 43.699247034079754,
    lng: -79.35980908700411,
    link: "https://goo.gl/maps/AteVJidvs1fVvZ358",
  },
  {
    id: 121,
    name: "Loblaws",
    address: "64 Isabella Street, Ottawa, ON K1S 1V4",
    icon: "cart",
    lat: 45.41089421150648,
    lng: -75.68633627344597,
    link: "https://goo.gl/maps/n5nXJeT8EWeSBvVW6",
  },
  {
    id: 122,
    name: "Loblaws",
    address: "3671 Dundas Street West, Etobicoke, ON M6S 2T3",
    icon: "cart",
    lat: 43.66578379249725,
    lng: -79.49588354467633,
    link: "https://goo.gl/maps/5mYdLWYbABH9yz9k6",
  },
  {
    id: 123,
    name: "Loblaws",
    address: "200 Earl Grey Drive Ottawa  ON  K2T 1B6",
    icon: "cart",
    lat: 45.309834427809086,
    lng: -75.91437590043704,
    link: "https://goo.gl/maps/Rhqv3N8UWbVpTgEA6",
  },
  {
    id: 124,
    name: "Loblaws",
    address: "12 Hurontario Street, Collingwood, ON L9Y 2L6",
    icon: "cart",
    lat: 44.502662627331055,
    lng: -80.21732354464504,
    link: "https://goo.gl/maps/AmmuSRfP54upYShY7",
  },
  {
    id: 125,
    name: "Loblaws",
    address: "2085 Carling Avenue, Ottawa, ON K2A 1H2",
    icon: "cart",
    lat: 45.37249640318925,
    lng: -75.76756127344743,
    link: "https://goo.gl/maps/XkVJm9JyWSf1ZqRD8",
  },
  {
    id: 126,
    name: "Loblaws",
    address: "12 St. Clair Avenue East, Toronto, ON M4T 1L7",
    icon: "cart",
    lat: 43.68858483414678,
    lng: -79.39404922323321,
    link: "https://goo.gl/maps/EV7QqJbMAVGGscBcA",
  },
  {
    id: 127,
    name: "Loblaws",
    address: "2280 Dundas Street West, Toronto, ON M6R 1X3",
    icon: "cart",
    lat: 43.655351613062344,
    lng: -79.44933827351224,
    link: "https://goo.gl/maps/xrZdN5DwRpxdJNxe6",
  },
  {
    id: 128,
    name: "Loblaws",
    address: "301 Moore Avenue  Toronto ON  M4G 1E1",
    icon: "cart",
    lat: 43.69664366223987,
    lng: -79.37074918885251,
    link: "https://goo.gl/maps/z3ScyEN8gwpNxdDAA",
  },
  {
    id: 129,
    name: "Loblaws",
    address: "363 Rideau Street Ottawa  ON  K1N 5Y6",
    icon: "cart",
    lat: 45.42997874089611,
    lng: -75.68393110412899,
    link: "https://goo.gl/maps/wmfxZkdiZ2B51ia58",
  },
  {
    id: 130,
    name: "Loblaws",
    address: "270 The Kingsway  Etobicoke ON  M9A 3T7",
    icon: "cart",
    lat: 43.66191501746407,
    lng: -79.51883808700552,
    link: "https://goo.gl/maps/ATs92o4fhYHMmQcc7",
  },
  {
    id: 131,
    name: "Loblaws",
    address: "720 Broadview Avenue  Toronto ON  M4K 2P1",
    icon: "cart",
    lat: 43.67569917409994,
    lng: -79.35821781584052,
    link: "https://goo.gl/maps/5WKiMUsLzgQo9yCn6",
  },
  {
    id: 132,
    name: "Loblaws",
    address: "2210C Bank Street, Ottawa, ON K1V 1J5",
    icon: "cart",
    lat: 45.35794845165188,
    lng: -75.65671281577703,
    link: "https://goo.gl/maps/SpeLQdD3v5apyqr4A",
  },
  {
    id: 133,
    name: "Loblaws",
    address: "3501 Yonge Street, North York, ON M4N 2N5",
    icon: "cart",
    lat: 43.73545153166521,
    lng: -79.40445262933187,
    link: "https://goo.gl/maps/33WPauagYou3L8peA",
  },
  {
    id: 134,
    name: "Loblaws",
    address: "1910 St Laurent Blvd, Ottawa, ON K1G 1A4",
    icon: "cart",
    lat: 45.40068130926431,
    lng: -75.62380945995278,
    link: "https://goo.gl/maps/Rc6KQwBEgjZH2KCr9",
  },
  {
    id: 135,
    name: "Loblaws",
    address: "10909 Yonge Street, Richmond Hill, ON L4C 3E3",
    icon: "cart",
    lat: 43.89489239423454,
    lng: -79.43992321768066,
    link: "https://goo.gl/maps/991yu15qVTznZSAb9",
  },
  {
    id: 136,
    name: "Loblaws",
    address: "396 St. Clair Avenue West Toronto ON  M5P 3N3",
    icon: "cart",
    lat: 43.684335393120726,
    lng: -79.41547427351112,
    link: "https://goo.gl/maps/o9C9bSC59v7LNjCD8",
  },
  {
    id: 137,
    name: "Loblaws",
    address: "500 Lakeshore Blvd. West, Toronto, ON M5V 1A5",
    icon: "cart",
    lat: 43.63707539200344,
    lng: -79.39923181584192,
    link: "https://goo.gl/maps/b2y37rJCt1dJD8Dj9",
  },
  {
    id: 138,
    name: "Dominion",
    address: "260 Blackmarsh Road, St John's, NL A1E 1T2",
    icon: "cart",
    lat: 47.54617257573162,
    lng: -52.744057815128244,
    link: "https://goo.gl/maps/JAJxywqa5ewCzjir9",
  },
  {
    id: 139,
    name: "Dominion",
    address: "20 Lake Avenue, St John's, NL A1A 1H4",
    icon: "cart",
    lat: 47.57637697195809,
    lng: -52.702187815127225,
    link: "https://goo.gl/maps/HNu38LuSyLeWTjSq5",
  },
  {
    id: 140,
    name: "Dominion",
    address: "17 Cromer Avenue, Grand Falls, NL A2A 1X3",
    icon: "cart",
    lat: 48.94670847964927,
    lng: -55.6529448150819,
    link: "https://goo.gl/maps/jtct1BUVq5oxCP5D7",
  },
  {
    id: 141,
    name: "Dominion",
    address: "100 Laurell Road, Gander, NL A1V 2V5",
    icon: "cart",
    lat: 48.949731988155456,
    lng: -54.60009461187652,
    link: "https://goo.gl/maps/hoQf2iQhwBPGyCNL8",
  },
  {
    id: 142,
    name: "Dominion",
    address: "5 Murphy Square, Corner Brook, NL A2H 1R4",
    icon: "cart",
    lat: 48.95895622693579,
    lng: -57.92263427460938,
    link: "https://goo.gl/maps/rPNE45eusCATYtKu7",
  },
  {
    id: 143,
    name: "Dominion",
    address: "62 Prince Rupert Street, Stephenville, NL A2N 3W7",
    icon: "cart",
    lat: 48.54621103252709,
    lng: -58.58251691509521,
    link: "https://goo.gl/maps/eiWJxKdDGf9xpfKf9",
  },
  {
    id: 144,
    name: "Dominion",
    address: "55 Stavanger Drive, St John's, NL A1A 5E8",
    icon: "cart",
    lat: 47.61619032088083,
    lng: -52.718124815125954,
    link: "https://goo.gl/maps/dYc4AzrpRFrEHpAv9",
  },
  {
    id: 145,
    name: "Dominion",
    address: "166 Conception Bay Hwy, Conception Bay South, NL A1W 1M9",
    icon: "cart",
    lat: 47.516544637617876,
    lng: -52.96268728814779,
    link: "https://goo.gl/maps/2e9XAD8WDCN3nt629",
  },
  {
    id: 146,
    name: "Dominion",
    address: "Pearlgate Plaza 150 Oldplacent,  Mount Pearl, NL A1N 4P5",
    icon: "cart",
    lat: 47.50400112857043,
    lng: -52.81334588258313,
    link: "https://goo.gl/maps/YAocYTK3CsCzXh5AA",
  },
  {
    id: 147,
    name: "Dominion",
    address: "Trinity Conc Square London Road, Carbonear, NL A1Y 1B3",
    icon: "cart",
    lat: 47.72826901333442,
    lng: -53.24486679977653,
    link: "https://goo.gl/maps/5YY8RMfZk2zZWix36",
  },
  {
    id: 148,
    name: "Dominion",
    address: "Main Highway General Delvy, Bay Roberts, NL A0A 1G0",
    icon: "cart",
    lat: 47.578946796195396,
    lng: -53.282397591956304,
    link: "https://goo.gl/maps/RbBCjsC72yNzbWdU8",
  },
  {
    id: 149,
    name: "Your Independent Grocer",
    address: "47 Main Street, Hantsport, NS B0P 1P0",
    icon: "cart",
    lat: 45.06824187861231,
    lng: -64.17992627288069,
    link: "https://goo.gl/maps/mEqx9D7Zpmrsfft38",
  },
  {
    id: 150,
    name: "Your Independent Grocer",
    address: "229 Boul J D Gauthier Unit 2, Shippagan, NB E8S 1N2",
    icon: "cart",
    lat: 47.7432208430025,
    lng: -64.70970091697681,
    link: "https://goo.gl/maps/wCiz1gompVofH3W39",
  },
  {
    id: 151,
    name: "Your Independent Grocer",
    address: "451 Main Street Kentville NS  B4N 3X9",
    icon: "cart",
    lat: 45.078263076536736,
    lng: -64.49793100171674,
    link: "https://goo.gl/maps/VFvQ936rBHGcnwGD7",
  },
  {
    id: 152,
    name: "Your Independent Grocer",
    address: "9996 Highway 16, P.O. Box 39, Guysborough, NS B0H 1N0",
    icon: "cart",
    lat: 45.384191970373784,
    lng: -61.50484935752509,
    link: "https://goo.gl/maps/6FnWsyyR4amd2u8Y7",
  },
  {
    id: 153,
    name: "Your Independent Grocer",
    address: "129 Brunswick Street, Unit #2, ST. GEORGE, NB E5C 1A8",
    icon: "cart",
    lat: 45.129427919115486,
    lng: -66.83224745938806,
    link: "https://goo.gl/maps/WwxPwzFcZcebHwc67",
  },
  {
    id: 154,
    name: "Your Independent Grocer",
    address: "75 Main Street, Sackville, NB E4L 4A9",
    icon: "cart",
    lat: 45.89694931567414,
    lng: -64.36892403609274,
    link: "https://goo.gl/maps/Fzw3orCQfme2iLUGA",
  },
  {
    id: 155,
    name: "Your Independent Grocer",
    address: "232 Water Street, St. Andrews, NB E5B 1B4",
    icon: "cart",
    lat: 45.07361493607995,
    lng: -67.05180827288055,
    link: "https://goo.gl/maps/Aea34UpJ7mxo7zsd9",
  },
  {
    id: 156,
    name: "Your Independent Grocer",
    address: "Route # 31, HUBBARDS, NS B0J 1T0",
    icon: "cart",
    lat: 44.63816762879953,
    lng: -64.07012749987543,
    link: "https://goo.gl/maps/dexnsWLiZbB76N8M7",
  },
  {
    id: 157,
    name: "Your Independent Grocer",
    address: "211 Duke Street Chester NS  B0J 1J0",
    icon: "cart",
    lat: 44.54596555176438,
    lng: -64.23818017104202,
    link: "https://goo.gl/maps/a3CsVKRUuvbnBfvF8",
  },
  {
    id: 158,
    name: "Your Independent Grocer",
    address: "143 Victoria Road Lunenburg NS  B0J 2C0",
    icon: "cart",
    lat: 44.37569008116899,
    lng: -64.3245922212998,
    link: "https://goo.gl/maps/yXZ2HV192UtY7yNy5",
  },
  {
    id: 159,
    name: "Your Independent Grocer",
    address: "543 Main Street, Mahone Bay, NS B0J 2E0",
    icon: "cart",
    lat: 44.44894450103332,
    lng: -64.38058962871783,
    link: "https://goo.gl/maps/ohqxeXLctTkQkstu5",
  },
  {
    id: 160,
    name: "Your Independent Grocer",
    address: "169 Main Street SOURIS  PE  C0A 2B0",
    icon: "cart",
    lat: 46.3541035356246,
    lng: -62.24470300167625,
    link: "https://goo.gl/maps/YJ7Bm2qRngk9mcsA7",
  },
  {
    id: 161,
    name: "Your Independent Grocer",
    address: "21 St Anthony Street  Annapolis Royal NS  B0S 1A0",
    icon: "cart",
    lat: 44.7486021885234,
    lng: -65.51403435754493,
    link: "https://goo.gl/maps/RGTtjrZxmh5vzugs9",
  },
  {
    id: 162,
    name: "Your Independent Grocer",
    address: "18 Main Street P.O. Box 98, Twillingate, NL A0G 4M0",
    icon: "cart",
    lat: 49.65775959540802,
    lng: -54.77441905738505,
    link: "https://goo.gl/maps/nDJG8h6ykKoxzjth9",
  },
  {
    id: 163,
    name: "Your Independent Grocer",
    address: "306-316 Main Street Middleton NS  B0S 1P0",
    icon: "cart",
    lat: 44.94221107850853,
    lng: -65.06791530543117,
    link: "https://goo.gl/maps/Ty4rb9D9KP46f7Ys8",
  },
  {
    id: 164,
    name: "Your Independent Grocer",
    address: "197 Commercial Street Berwick NS  B0P 1E0",
    icon: "cart",
    lat: 45.04369859208301,
    lng: -64.73634964590009,
    link: "https://goo.gl/maps/GgAFNLvvEtR3237FA",
  },
  {
    id: 165,
    name: "Your Independent Grocer",
    address: "170 Renfrew Street P.O. Box 2380, Dalhousie, NB E8C 2K2",
    icon: "cart",
    lat: 48.06016105037733,
    lng: -66.37010335743844,
    link: "https://goo.gl/maps/Ps6zbiHJaYYe1xHa8",
  },
  {
    id: 166,
    name: "Your Independent Grocer",
    address: "17-25 Meadowbank Rd Box 760, Cornwall, PE C0A 1H0",
    icon: "cart",
    lat: 46.22695955187068,
    lng: -63.21925140960585,
    link: "https://goo.gl/maps/u6M7REFsi8N2ifKU8",
  },
  {
    id: 167,
    name: "Your Independent Grocer",
    address: "1792 A 9th Avenue, Fernie, BC V0B 1M0",
    icon: "cart",
    lat: 49.51640328916598,
    lng: -115.05798845924485,
    link: "https://goo.gl/maps/tbRsVWzVh3DwDior7",
  },
  {
    id: 168,
    name: "Your Independent Grocer",
    address: "2110 Ryley Ave, Vanderhoof, BC V0J 3A0",
    icon: "cart",
    lat: 54.013031587253906,
    lng: -124.00683364559751,
    link: "https://goo.gl/maps/tBaGkDaeNj6HNKbs6",
  },
  {
    id: 169,
    name: "Your Independent Grocer",
    address: "16 Superior Street, Devon, AB T9G 1Z1",
    icon: "cart",
    lat: 53.36512923737517,
    lng: -113.7334800014384,
    link: "https://goo.gl/maps/NAJTCoYYBAMuCoSE8",
  },
  {
    id: 170,
    name: "Your Independent Grocer",
    address: "P.O. Box 1120 Main Street, Lewisporte, NL A0G 3A0",
    icon: "cart",
    lat: 49.25749402059183,
    lng: -55.168704001580686,
    link: "https://goo.gl/maps/53py12WQejBfsQ1a6",
  },
  {
    id: 171,
    name: "Atlantic Superstore",
    address: "26 Market Street, Antigonish, NS B2G 2K8",
    icon: "cart",
    lat: 45.60965927629617,
    lng: -62.015532323116126,
    link: "https://goo.gl/maps/r7ug7tuukoPKEbLa6",
  },
  {
    id: 172,
    name: "Atlantic Superstore",
    address: "680 Somerset Street, Saint John, NB E2K 2V7",
    icon: "cart",
    lat: 45.28941067970086,
    lng: -66.08238573054655,
    link: "https://goo.gl/maps/S9xGxpJo5oUHvQqF9",
  },
  {
    id: 173,
    name: "Atlantic Superstore",
    address: "126 South Albion Street, Amherst, NS B4H 2Z3",
    icon: "cart",
    lat: 45.81591170271825,
    lng: -64.20256395304352,
    link: "https://goo.gl/maps/xTcuEyhRbHUbUddJ7",
  },
  {
    id: 174,
    name: "Atlantic Superstore",
    address: "5178 St. Margaret's Bay Rd, Upper Tantallon, NS B3Z 4G3",
    icon: "cart",
    lat: 44.68696004572331,
    lng: -63.87938808638334,
    link: "https://goo.gl/maps/528Dy2DoDkFZegZz5",
  },
  {
    id: 175,
    name: "Atlantic Superstore",
    address: "535 Granville Street, Summerside, PE C1N 3C4",
    icon: "cart",
    lat: 46.41969092129857,
    lng: -63.77778991651482,
    link: "https://goo.gl/maps/CqTyRbEQCd1VJTNs8",
  },
  {
    id: 176,
    name: "Atlantic Superstore",
    address: "1150 Onondago Street, Oromocto, NB E2V 1B8",
    icon: "cart",
    lat: 45.85260106969913,
    lng: -66.47327062867363,
    link: "https://goo.gl/maps/RtARPJu1fs8PkpyXA",
  },
  {
    id: 177,
    name: "Atlantic Superstore",
    address: "3455 Rue Principale, TRACADIE, NB E1X 1C8",
    icon: "cart",
    lat: 47.51945462837506,
    lng: -64.91134935745619,
    link: "https://goo.gl/maps/bxV5jQNYka7ngnwd6",
  },
  {
    id: 178,
    name: "Atlantic Superstore",
    address: "50 Paint Street, Port Hawkesbury, NS B9A 3K1",
    icon: "cart",
    lat: 45.61917001109672,
    lng: -61.33404856122774,
    link: "https://goo.gl/maps/xTJ6zCTszRd3ZFgs8",
  },
  {
    id: 179,
    name: "Atlantic Superstore",
    address: "470 Main Street, Kingston, NS B0P 1R0",
    icon: "cart",
    lat: 44.98470558013962,
    lng: -64.95233601892058,
    link: "https://goo.gl/maps/kYxY5xpGEgMkNSmW7",
  },
  {
    id: 180,
    name: "Atlantic Superstore",
    address: "125 King St., North Sydney, NS B2A 3S1",
    icon: "cart",
    lat: 46.214850000317995,
    lng: -60.263190730517266,
    link: "https://goo.gl/maps/muQpSyKSSr98bBMy8",
  },
  {
    id: 181,
    name: "Atlantic Superstore",
    address: "577 Victoria Street, Edmundston, NB E3V 3L1",
    icon: "cart",
    lat: 47.38309873879898,
    lng: -68.33490627280649,
    link: "https://goo.gl/maps/GaeR8oewcZfLoyPo9",
  },
  {
    id: 182,
    name: "Atlantic Superstore",
    address: "89 Trinity Drive  Moncton NB  E1G 2J7",
    icon: "cart",
    lat: 46.11616084531384,
    lng: -64.83050726989862,
    link: "https://goo.gl/maps/k25WqMZc51AjfuoB6",
  },
  {
    id: 183,
    name: "Atlantic Superstore",
    address: "116 Main Street, Fredericton, NB E3A 1R7",
    icon: "cart",
    lat: 45.97871684214651,
    lng: -66.65801900168834,
    link: "https://goo.gl/maps/D247YQxzT6CYLxpX7",
  },
  {
    id: 184,
    name: "Atlantic Superstore",
    address: "50 Milton Road, Liverpool, NS B0T 1K0",
    icon: "cart",
    lat: 44.04635740230147,
    lng: -64.7246689342956,
    link: "https://goo.gl/maps/qzjUFAURWweiKxUm8",
  },
  {
    id: 185,
    name: "Atlantic Superstore",
    address: "408 King George Highway, Miramichi, NB E1V 2T3",
    icon: "cart",
    lat: 46.99217234165423,
    lng: -65.57150108630996,
    link: "https://goo.gl/maps/eTucKvxD5VBUJbax7",
  },
  {
    id: 186,
    name: "Atlantic Superstore",
    address: "46 Elm Street, Truro, NS B2N 3H6",
    icon: "cart",
    lat: 45.36725082840513,
    lng: -63.287104398502635,
    link: "https://goo.gl/maps/6K3s8FdJQHJk7V3D8",
  },
  {
    id: 187,
    name: "Atlantic Superstore",
    address: "21 Davidson Drive, Bridgewater, NS B4V 2P9",
    icon: "cart",
    lat: 44.381466344424666,
    lng: -64.51887642635982,
    link: "https://goo.gl/maps/aavwkC689Qeqa8yw7",
  },
  {
    id: 188,
    name: "Atlantic Superstore",
    address: "490 Highway #303, Conway, NS B0V 1A0",
    icon: "cart",
    lat: 44.59937876504354,
    lng: -65.77053708638599,
    link: "https://goo.gl/maps/8Lf69FgRGyn88u6S9",
  },
  {
    id: 189,
    name: "Atlantic Superstore",
    address: "471 Smythe Street, Fredericton, NB E3B 3E3",
    icon: "cart",
    lat: 45.958799815253926,
    lng: -66.65681864587107,
    link: "https://goo.gl/maps/FjFiYPuUSBbEyzrQA",
  },
  {
    id: 190,
    name: "Atlantic Superstore",
    address: "9064 Commercial Street, New Minas, NS B4N 3E4",
    icon: "cart",
    lat: 45.0676866834692,
    lng: -64.45580408637134,
    link: "https://goo.gl/maps/Kujtxyi8AxHJeBzF7",
  },
  {
    id: 191,
    name: "Atlantic Superstore",
    address: "165 Main St., Moncton, NB E1C 1B3",
    icon: "cart",
    lat: 46.09417978280486,
    lng: -64.76865827284813,
    link: "https://goo.gl/maps/iGYDjcR7nbV2Gpfu6",
  },
  {
    id: 192,
    name: "Atlantic Superstore",
    address: "745 Sackville, Lower Sackville, NS B4E 2R2",
    icon: "cart",
    lat: 44.7733805395872,
    lng: -63.692202103581444,
    link: "https://goo.gl/maps/DW1fFmfNRtS4k1wW7",
  },
  {
    id: 193,
    name: "Atlantic Superstore",
    address: "650 Portland St., Dartmouth, NS B2W 2M7",
    icon: "cart",
    lat: 44.67024746854158,
    lng: -63.52238108638377,
    link: "https://goo.gl/maps/TdrEbtszovg7JGtNA",
  },
  {
    id: 194,
    name: "Atlantic Superstore",
    address: "168 Rothesay Ave  Saint John  NB  E2J 2B5",
    icon: "cart",
    lat: 45.28808829839888,
    lng: -66.04505183054663,
    link: "https://goo.gl/maps/7HsnyyPuesU389YXA",
  },
  {
    id: 195,
    name: "Atlantic Superstore",
    address: "6141 Young St., HaliFax, NS B3K 2A5",
    icon: "cart",
    lat: 44.65850013564533,
    lng: -63.60424856125786,
    link: "https://goo.gl/maps/xJawpLVJj1NnGxXq9",
  },
  {
    id: 196,
    name: "Atlantic Superstore",
    address: "700 St Peter Ave., Bathurst, NB E2A 2Y7",
    icon: "cart",
    lat: 47.63181181097691,
    lng: -65.66845627279827,
    link: "https://goo.gl/maps/HW1L8hgufvctXiaWA",
  },
  {
    id: 197,
    name: "Atlantic Superstore",
    address: "210 Chain Lake Dr Bayers Lake, Halifax, NS B3S 1A2",
    icon: "cart",
    lat: 44.65348604808459,
    lng: -63.676702359402974,
    link: "https://goo.gl/maps/TU62yRZirqH35gWn8",
  },
  {
    id: 198,
    name: "Atlantic Superstore",
    address: "2 Johnson Street, Chatham, NB E1N 3B7",
    icon: "cart",
    lat: 47.02841489094279,
    lng: -65.47227820401456,
    link: "https://goo.gl/maps/sNhdR1VGfTps3GWB9",
  },
  {
    id: 199,
    name: "Atlantic Superstore",
    address: "429 Coverdale Road, Riverview, NB E1B 3K3",
    icon: "cart",
    lat: 46.06516608164907,
    lng: -64.80071481517618,
    link: "https://goo.gl/maps/fUQvov9oTE48qKr27",
  },
  {
    id: 200,
    name: "Atlantic Superstore",
    address: "25 Savoie Ave., ATHOLVILLE, NB E3N 4A8",
    icon: "cart",
    lat: 47.98973903126725,
    lng: -66.70007028813232,
    link: "https://goo.gl/maps/J9aCU9wMMWY6ByUB6",
  },
  {
    id: 201,
    name: "Atlantic Superstore",
    address: "115 Campbell Road, Unit B, Rothesay, NB E2E 6E4",
    icon: "cart",
    lat: 45.39315566314536,
    lng: -65.96842210356199,
    link: "https://goo.gl/maps/qUsCAfWsMjfo3fvF6",
  },
  {
    id: 202,
    name: "Atlantic Superstore",
    address: "195 King Street St., Stephen, NB E3L 2E4",
    icon: "cart",
    lat: 45.20215341013291,
    lng: -67.27317527287653,
    link: "https://goo.gl/maps/4aGfTt5pYDCgF8a96",
  },
  {
    id: 203,
    name: "Atlantic Superstore",
    address: "5240 Highway #7, Porters Lake, NS B3E 1J7",
    icon: "cart",
    lat: 44.743428339358736,
    lng: -63.281392442199376,
    link: "https://goo.gl/maps/fQ2Ygkri89V2sbSH7",
  },
  {
    id: 204,
    name: "Atlantic Superstore",
    address: "394 Westville Rd, New Glasgow, NS B2H 2Y7",
    icon: "cart",
    lat: 45.581568669032535,
    lng: -62.658576986355236,
    link: "https://goo.gl/maps/5yXZjCbHnFM9Ak4q7",
  },
  {
    id: 205,
    name: "Atlantic Superstore",
    address: "1075 Barrington Street, HaliFax, NS B3H 2P8",
    icon: "cart",
    lat: 44.63838919916062,
    lng: -63.56956454405769,
    link: "https://goo.gl/maps/Hb2UQrSDmZbZ3JCn7",
  },
  {
    id: 206,
    name: "Atlantic Superstore",
    address: "104 Starrs Road Yarmouth  NS  B5A 2T5",
    icon: "cart",
    lat: 43.84447612452137,
    lng: -66.10033703480693,
    link: "https://goo.gl/maps/QFS3pPjW4ttpXDEa8",
  },
  {
    id: 207,
    name: "Atlantic Superstore",
    address: "1225 Kings Road Sydney River  NS  B1S 1E1",
    icon: "cart",
    lat: 46.10870831730276,
    lng: -60.22282303608587,
    link: "https://goo.gl/maps/TzhFLXzyJDWcbxf28",
  },
  {
    id: 208,
    name: "Atlantic Superstore",
    address: "350 Connell Street, Woodstock, NB E7M 5G8",
    icon: "cart",
    lat: 46.16748072429404,
    lng: -67.59720973051861,
    link: "https://goo.gl/maps/bWWVWRGmZtCibFxa6",
  },
  {
    id: 209,
    name: "Atlantic Superstore",
    address: "295 Highway # 214, Elmsdale, NS B2S 2L1",
    icon: "cart",
    lat: 44.98144781243834,
    lng: -63.509508274738565,
    link: "https://goo.gl/maps/vNCXudrb6ZHeqkpf9",
  },
  {
    id: 210,
    name: "Atlantic Superstore",
    address: "465 University Avenue, Charlottetown, PE C1A 4N9",
    icon: "cart",
    lat: 46.25107119829457,
    lng: -63.13971962666969,
    link: "https://goo.gl/maps/m3urdJU8R7ASaXxy6",
  },
  {
    id: 211,
    name: "Atlantic Superstore",
    address: "11 Cole Drive, Windsor, NS B0N 2T0",
    icon: "cart",
    lat: 44.98892773716643,
    lng: -64.1129506459019,
    link: "https://goo.gl/maps/Hjy1eMngcymrFpsN7",
  },
  {
    id: 212,
    name: "Atlantic Superstore",
    address: "1650 BedFord Highway, BedFord, NS B4A 4J7",
    icon: "cart",
    lat: 44.73439278804884,
    lng: -63.65765745940042,
    link: "https://goo.gl/maps/bjoJnw4eyTc8vBPp7",
  },
  {
    id: 213,
    name: "Atlantic Superstore",
    address: "3601 Joseph Howe Drive, HaliFax, NS B3L 4H8",
    icon: "cart",
    lat: 44.65774876965711,
    lng: -63.62732408638424,
    link: "https://goo.gl/maps/CmrSmT9WY766NjH49",
  },
  {
    id: 214,
    name: "Atlantic Superstore",
    address: "9 Braemar Drive, Dartmouth, NS B2Y 3H6",
    icon: "cart",
    lat: 44.6814748060308,
    lng: -63.54776873056567,
    link: "https://goo.gl/maps/KotuheZTeiAKXbb99",
  },
  {
    id: 215,
    name: "Atlantic Superstore",
    address: "6139 Quinpool Road, HaliFax, NS B3L 4P6",
    icon: "cart",
    lat: 44.64643527437578,
    lng: -63.594317544057446,
    link: "https://goo.gl/maps/n4qa1Q9XMs91uTan9",
  },
  {
    id: 216,
    name: "Atlantic Superstore",
    address: "920 Cole Harbour Road Box 440, Dartmouth, NS B2V 2J5",
    icon: "cart",
    lat: 44.6700186193565,
    lng: -63.4990625440567,
    link: "https://goo.gl/maps/k2BKzdeHW9maoFRo6",
  },
  {
    id: 217,
    name: "Atlantic Superstore",
    address: "155 Reserve Street Box 41, Glace Bay, NS B1A 5V1",
    icon: "cart",
    lat: 46.19091666730102,
    lng: -59.97759108633575,
    link: "https://goo.gl/maps/bE2x7HRp8Ph1ucUV7",
  },
  {
    id: 218,
    name: "Atlantic Superstore",
    address: "680 University Ave, Charlottetown, PE C1E 1E3",
    icon: "cart",
    lat: 46.26569757042997,
    lng: -63.147618917024815,
    link: "https://goo.gl/maps/c8mFxmbucLMdbyVM8",
  },
  {
    id: 219,
    name: "Atlantic Superstore",
    address: "509 Main Street, Montague, PE C0A 1R0",
    icon: "cart",
    lat: 46.16457959273819,
    lng: -62.64856864586446,
    link: "https://goo.gl/maps/CUEDxweQgdrWWqLGA",
  },
  {
    id: 220,
    name: "Atlantic Superstore",
    address: "44 Lower Cove Road, SUSSEX, NB E4E 1T6",
    icon: "cart",
    lat: 45.7212717210239,
    lng: -65.52862598449566,
    link: "https://goo.gl/maps/BdGwAUyPZKVytozf8",
  },
  {
    id: 221,
    name: "Atlantic Superstore",
    address: "240 Madawaska Road, Grand Falls, NB E3Y 1A5",
    icon: "cart",
    lat: 47.05622078489496,
    lng: -67.74547774769076,
    link: "https://goo.gl/maps/UzFqSAjjz8cxiUiq8",
  },
  {
    id: 222,
    name: "Atlantic Superstore",
    address: "40 Hogan Court, Bedford, NS B4B 2G2",
    icon: "cart",
    lat: 44.697044508000594,
    lng: -63.69069591521943,
    link: "https://goo.gl/maps/cP3b982qTGtGVUg76",
  },
   {
    id: 223,
    name: "Zehrs",
    address: "124 Clair Rd. E., Guelph, ON N1L OG6",
    icon: "cart",
    lat: 43.501159012381514,
    lng: -80.18873173118892,
    link: "https://goo.gl/maps/28uQCLdwgfirz8nx9",
  },
  {
    id: 224,
    name: "Zehrs",
    address: "500 Holland St. West, Bradford, ON L3Z 0A2",
    icon: "cart",
    lat: 44.105837945525735,
    lng: -79.59172218883727,
    link: "https://goo.gl/maps/NCrXdndQiyWSrgTX6",
  },
  {
    id: 225,
    name: "Zehrs",
    address: "11 Bryne Drive, Barrie, ON L4N 8V8",
    icon: "cart",
    lat: 44.35772475433431,
    lng: -79.6962829004731,
    link: "https://goo.gl/maps/QMz1gvHWeGBoutti6",
  },
  {
    id: 226,
    name: "Zehrs",
    address: "30 King Street South, Alliston, ON L9R 1H6",
    icon: "cart",
    lat: 44.150829732174365,
    lng: -79.8787473581518,
    link: "https://goo.gl/maps/uXZPBWH5wWNESekc9",
  },
  {
    id: 227,
    name: "Zehrs",
    address: "607 Cundles Rd East, Barrie, ON L4M 0J7",
    icon: "cart",
    lat: 44.41615657289289,
    lng: -79.67380835814181,
    link: "https://goo.gl/maps/TZLCg8zpLGdpgKTF6",
  },
  {
    id: 228,
    name: "Zehrs",
    address: "1375 Weber St. East, Kitchener, ON N2A 2Y7",
    icon: "cart",
    lat: 43.43238625171219,
    lng: -80.44105890050764,
    link: "https://goo.gl/maps/aHiDqrqGoMK9aCwj6",
  },
  {
    id: 229,
    name: "Zehrs",
    address: "322 Argyle Street South, Caledonia, ON N3W 1K8",
    icon: "cart",
    lat: 43.06154850845409,
    lng: -79.96107231771143,
    link: "https://goo.gl/maps/88SgHXnnSuWx8V2k6",
  },
  {
    id: 230,
    name: "Zehrs",
    address: "24018 Woodbine Ave Rr # 2 Keswick ON  L4P 3E9",
    icon: "cart",
    lat: 44.22674718251049,
    lng: -79.44832464650369,
    link: "https://goo.gl/maps/WGQCowZRjLZg131i8",
  },
  {
    id: 231,
    name: "Zehrs",
    address: "5890 Malden Road, Windsor, ON N9H 1S4",
    icon: "cart",
    lat: 42.24735876165273,
    lng: -83.05847383308308,
    link: "https://goo.gl/maps/4DDf4xfSvidKzdqL8",
  },
  {
    id: 232,
    name: "Zehrs",
    address: "221 Glendale Ave (At Hwy 406), St. Catharines, ON  L2T 2K9",
    icon: "cart",
    lat: 43.1340796780398,
    lng: -79.22525371659768,
    link: "https://goo.gl/maps/zr2cPPsCmo1yB1mF6",
  },
  {
    id: 233,
    name: "Zehrs",
    address: "1005 Ottawa Street N, Kitchener, ON N2A 1H1",
    icon: "cart",
    lat: 43.449525201632405,
    lng: -80.44478328387235,
    link: "https://goo.gl/maps/8xVYBtkqHXu95ubx8",
  },
  {
    id: 234,
    name: "Zehrs",
    address: "450 Erb St. West, Waterloo, ON N2T 1H4",
    icon: "cart",
    lat: 43.453262113613654,
    lng: -80.55584727351967,
    link: "https://goo.gl/maps/vu3v4xSeuXM6LMZw5",
  },
  {
    id: 235,
    name: "Zehrs",
    address: "315 Lincoln Road, Waterloo, ON N2J 4H7",
    icon: "cart",
    lat: 43.4833453012474,
    lng: -80.50623590050577,
    link: "https://goo.gl/maps/4LRXZMoJuR4ACS1W6",
  },
  {
    id: 236,
    name: "Zehrs",
    address: "555 Davenport Road, Waterloo, ON N2L 6L2",
    icon: "cart",
    lat: 43.497056332317406,
    lng: -80.52508146002445,
    link: "https://goo.gl/maps/9K1YU2t91BCH6vn88",
  },
  {
    id: 237,
    name: "Zehrs",
    address: "7201 Tecumseh Rd East, Windsor, ON N8T 3K4",
    icon: "cart",
    lat: 42.31190839259088,
    lng: -82.93858291773878,
    link: "https://goo.gl/maps/LzWLoJeX1V8i7hXG7",
  },
  {
    id: 238,
    name: "Zehrs",
    address: "123 Pioneer Park, Kitchener, ON N2P 1K8",
    icon: "cart",
    lat: 43.394076696663184,
    lng: -80.4320575446864,
    link: "https://goo.gl/maps/W6EXDRrHhKPs1vjN8",
  },
  {
    id: 239,
    name: "Zehrs",
    address: "160 Kortright Road West, Guelph, ON N1G 4W2",
    icon: "cart",
    lat: 43.51384539870879,
    lng: -80.21830991769484,
    link: "https://goo.gl/maps/qeNyuyE3T8cWdePE6",
  },
  {
    id: 240,
    name: "Zehrs",
    address: "35400D Huron Road, Goderich, ON N7A 4C6",
    icon: "cart",
    lat: 43.731223897329336,
    lng: -81.68796560290038,
    link: "https://goo.gl/maps/CkfNLg4zERktRw5t8",
  },
  {
    id: 241,
    name: "Zehrs",
    address: "400 Conestoga Blvd., Cambridge, ON N1R 7L7",
    icon: "cart",
    lat: 43.39189925898097,
    lng: -80.31601210420574,
    link: "https://goo.gl/maps/P9X2SdFWx32QV49k9",
  },
  {
    id: 242,
    name: "Zehrs",
    address: "600 Mitchell Rd Hwy 23 South, Listowel, ON N4W 3T1",
    icon: "cart",
    lat: 43.73044852708225,
    lng: -80.9687161716611,
    link: "https://goo.gl/maps/XYVsbVxuZGyiJ5Fi8",
  },
  {
    id: 243,
    name: "Zehrs",
    address: "Fairview Mall 285 Geneva St., St Catharines, ON L2N 2G1",
    icon: "cart",
    lat: 43.177993165254236,
    lng: -79.24315218887176,
    link: "https://goo.gl/maps/bss1vfwzDHVRqRKt8",
  },
  {
    id: 244,
    name: "Zehrs",
    address: "6940 Morrison Street  Niagara Falls ON  L2E 7K5",
    icon: "cart",
    lat: 43.10315331490663,
    lng: -79.11509891770999,
    link: "https://goo.gl/maps/scSNVh2vou3Aue1j6",
  },
  {
    id: 245,
    name: "Zehrs",
    address: "297 Eramosa Road, Guelph, ON N1E 2M7",
    icon: "cart",
    lat: 43.55908389916306,
    lng: -80.24799017166745,
    link: "https://goo.gl/maps/vjwirwp6tFMT64g67",
  },
  {
    id: 246,
    name: "Zehrs",
    address: "800 Tower Street South, Fergus, ON N1M 2R3",
    icon: "cart",
    lat: 43.6982806293645,
    lng: -80.36874981583965,
    link: "https://goo.gl/maps/spTBtsFw59h3Q9Ug8",
  },
  {
    id: 247,
    name: "Zehrs",
    address: "410 Fairview Drive, Brantford, ON N3R 7V7",
    icon: "cart",
    lat: 43.169960300881606,
    lng: -80.24879454469462,
    link: "https://goo.gl/maps/vTbrbRaSJRf45Vzh7",
  },
  {
    id: 248,
    name: "Zehrs",
    address: "290 King George Rd Nth/Hiway24, Brantford, ON N3R 5L8",
    icon: "cart",
    lat: 43.18174585311559,
    lng: -80.27919517297417,
    link: "https://goo.gl/maps/f1S5NRriGWQvqTmF7",
  },
  {
    id: 249,
    name: "Zehrs",
    address: "180 Holiday Inn Drive, Cambridge, ON N3C 3Z4",
    icon: "cart",
    lat: 43.41600739699107,
    lng: -80.3236285446856,
    link: "https://goo.gl/maps/yt6jsjXP7ZdUS9Yi9",
  },
  {
    id: 250,
    name: "Zehrs",
    address: "821 Niagara St. North, Welland, ON L3C 1M4",
    icon: "cart",
    lat: 43.01744615433909,
    lng: -79.25185518887766,
    link: "https://goo.gl/maps/WEVm62MoMh1b5SX8A",
  },
  {
    id: 251,
    name: "Zehrs",
    address: "323 Toronto St.S  Uxbridge  ON  L9P 1N2",
    icon: "cart",
    lat: 44.089897922809165,
    lng: -79.1307634600024,
    link: "https://goo.gl/maps/ju9DDBZkCkdC19Q28",
  },
  {
    id: 252,
    name: "Zehrs",
    address: "Heritage Mall 50 - 4th Ave, Orangeville, ON L9W 1L0",
    icon: "cart",
    lat: 43.92593052545244,
    lng: -80.09088391767945,
    link: "https://goo.gl/maps/8uQdtjPWRTo4EFBLA",
  },
  {
    id: 253,
    name: "Zehrs",
    address: "865 Ontario Street, Stratford, ON N5A 7Y2",
    icon: "cart",
    lat: 43.36958253588952,
    lng: -80.95190366372576,
    link: "https://goo.gl/maps/H6W9DoZZcvb5U5kW7",
  },
  {
    id: 254,
    name: "Zehrs",
    address: "487 Queen St. S, Bolton, ON L7E 2B4",
    icon: "cart",
    lat: 43.871542385344306,
    lng: -79.72467485021359,
    link: "https://goo.gl/maps/xEMfik4RnRqGxCbT8",
  },
  {
    id: 255,
    name: "Zehrs",
    address: "1045 Paisley Road Guelph  ON  N1K 1X6",
    icon: "cart",
    lat: 43.5227587895868,
    lng: -80.2893539005043,
    link: "https://goo.gl/maps/EN9GuPUJz812PY3bA",
  },
  {
    id: 256,
    name: "Zehrs",
    address: "750 Ottawa St South, Kitchener, ON N2E 1B6",
    icon: "cart",
    lat: 43.42396457709589,
    lng: -80.48756091769813,
    link: "https://goo.gl/maps/9CCNe1nQy7y7P46m8",
  },
  {
    id: 257,
    name: "Zehrs",
    address: "1150 16th Street East Owen Sound  ON  N4K 1Z3",
    icon: "cart",
    lat: 44.57558382481005,
    lng: -80.92387248512301,
    link: "https://goo.gl/maps/ox6QY1iCEdZS9ffe9",
  },
  {
    id: 258,
    name: "Zehrs",
    address: "620 Yonge Street, Barrie, ON L4N 4E6",
    icon: "cart",
    lat: 44.35545476176371,
    lng: -79.64861481581508,
    link: "https://goo.gl/maps/BJFCDwNk5Z9gpHu99",
  },
  {
    id: 259,
    name: "Zehrs",
    address: "400 Simcoe Street, Tillsonburg, ON N4G 4X1",
    icon: "cart",
    lat: 42.85509018307228,
    lng: -80.69104408333867,
    link: "https://goo.gl/maps/27Bsmby4ynhUR5Q26",
  },
  {
    id: 260,
    name: "Zehrs",
    address: "300 Main St. E, Kingsville, ON N9Y 3S9",
    icon: "cart",
    lat: 42.03879396264123,
    lng: -82.72631473124231,
    link: "https://goo.gl/maps/yVLLQS1K2szjviEv7",
  },
  {
    id: 261,
    name: "Zehrs",
    address: "400 Manning Road, Tecumseh, ON N8N 4Z4",
    icon: "cart",
    lat: 42.31489805119258,
    lng: -82.86687691773867,
    link: "https://goo.gl/maps/gyzpEgGLsFPzTkQd7",
  },
  {
    id: 262,
    name: "Zehrs",
    address: "200 Franklin Blvd Cambridge ON  N1R 5S2",
    icon: "cart",
    lat: 43.357139003963944,
    lng: -80.28932618886516,
    link: "https://goo.gl/maps/8AhFro4S8oghKz7Z9",
  },
  {
    id: 263,
    name: "Zehrs",
    address: "969 Dundas Street Woodstock ON  N4S 1H2",
    icon: "cart",
    lat: 43.135736856113134,
    lng: -80.73123062935406,
    link: "https://goo.gl/maps/FX3qeFNeGsukHYGx6",
  },
  {
    id: 264,
    name: "Zehrs",
    address: "289 Coldwater Road, Orillia, ON L3V 6J3",
    icon: "cart",
    lat: 44.60844429379482,
    lng: -79.4377533753248,
    link: "https://goo.gl/maps/GxdkWNwK2DfvxwmKA",
  },
  {
    id: 265,
    name: "Provigo",
    address: "282, rue Ouellette, Marieville, QC J3M 1A5",
    icon: "cart",
    lat: 45.435387250728624,
    lng: -73.1667350022805,
    link: "https://goo.gl/maps/2wXzpzgNyvQariBd9",
  },
  {
    id: 266,
    name: "Provigo",
    address: "1001, boul. De Montarville, Boucherville, QC J4B 6P5",
    icon: "cart",
    lat: 45.590975673926,
    lng: -73.43342227343906,
    link: "https://goo.gl/maps/rNYBWWKHebsdDVtZ8",
  },
  {
    id: 267,
    name: "Provigo",
    address: "3725, boul. des Forges, Trois-Rivières, QC G8Y 4P2",
    icon: "cart",
    lat: 46.35389151114993,
    lng: -72.58397918875146,
    link: "https://goo.gl/maps/yE3jcEwcvUphoZrR6",
  },
  {
    id: 268,
    name: "Provigo",
    address: "2386, chemin Lucerne, Ville Mont-Royal, QC H3R 2J8",
    icon: "cart",
    lat: 45.50687395497319,
    lng: -73.66202954460687,
    link: "https://goo.gl/maps/hxEBmjgzCsJxvyhY8",
  },
  {
    id: 269,
    name: "Provigo",
    address: "2925, Rachel Est, Montréal, QC H1W 3Z8",
    icon: "cart",
    lat: 45.54459837098238,
    lng: -73.5623933752891,
    link: "https://goo.gl/maps/ghaR2Cx8yezqRKYH9",
  },
  {
    id: 270,
    name: "Provigo",
    address: "1402, rue Roberval, St-Bruno, QC J3V 5J2",
    icon: "cart",
    lat: 45.528052046470144,
    lng: -73.34473245994792,
    link: "https://goo.gl/maps/SeDgB6bgb3Pya9T3A",
  },
  {
    id: 271,
    name: "Provigo",
    address: "100, rue des Oblats nord, Ville-Marie, QC J9V 1J7",
    icon: "cart",
    lat: 47.340123467625666,
    lng: -79.43699054453542,
    link: "https://goo.gl/maps/gNaHYYoBZXeqeuRWA",
  },
  {
    id: 272,
    name: "Provigo",
    address: "480, boul Ste-Anne, Sainte-Anne-Des-Plaines, QC J5N 3R2",
    icon: "cart",
    lat: 45.76737666227098,
    lng: -73.81504191760965,
    link: "https://goo.gl/maps/9Zex1bUdUaSojSUx6",
  },
  {
    id: 273,
    name: "Provigo",
    address: "167, De La Reine  Pl. Jacques-Cartier, Gaspé, QC G4X 2W6",
    icon: "cart",
    lat: 48.828997061831295,
    lng: -64.48497049419807,
    link: "https://goo.gl/maps/FKTkG76oS55QGtDPA",
  },
  {
    id: 274,
    name: "Provigo",
    address: "9550, de L'Ormière, Québec, QC, G2B 3Z6",
    icon: "cart",
    lat: 46.837324783238884,
    lng: -71.35608127339069,
    link: "https://goo.gl/maps/4bz9Rbpd73yK941X8",
  },
  {
    id: 275,
    name: "Provigo",
    address: "1095, St-Isidore, Ville des Laurentides, QC J5M 2V5",
    icon: "cart",
    lat: 45.86675110367768,
    lng: -73.75608864644126,
    link: "https://goo.gl/maps/uEMKjURo5kBDtDsFA",
  },
  {
    id: 276,
    name: "Provigo",
    address: "8, boul. des Iles, Port-Cartier, QC G5B 2J4",
    icon: "cart",
    lat: 50.02068110146312,
    lng: -66.88259327326264,
    link: "https://goo.gl/maps/TPHXUVQttkksdmnq9",
  },
  {
    id: 277,
    name: "Provigo",
    address: "2900, Chemin St-Louis, Ste-Foy, QC G1W 4R7",
    icon: "cart",
    lat: 46.76229236836502,
    lng: -71.28319237339369,
    link: "https://goo.gl/maps/Y3TaLD95XCaB4vmE7",
  },
  {
    id: 278,
    name: "Provigo",
    address: "3440, Ch. Quatre Bourgeois, Ste-Foy, QC G1W 4T3",
    icon: "cart",
    lat: 46.76319981149425,
    lng: -71.31795227339357,
    link: "https://goo.gl/maps/cdG5hJYZ74ZeoSL3A",
  },
  {
    id: 279,
    name: "Provigo",
    address: "8570, boul. St-Laurent, Montréal, QC H2P 2M8",
    icon: "cart",
    lat: 45.54120584029331,
    lng: -73.63917491761835,
    link: "https://goo.gl/maps/sxcKUXcZGicWP1wi9",
  },
  {
    id: 280,
    name: "Provigo",
    address: "1115, boul. Décarie, Ville St-Laurent, QC H4L 3M8",
    icon: "cart",
    lat: 45.51570319328826,
    lng: -73.68338612131592,
    link: "https://goo.gl/maps/4NE5KZkhXgEBRCSJA",
  },
  {
    id: 281,
    name: "Provigo",
    address: "2323, Henri-Bourassa est, Montréal, QC H2B 1T4",
    icon: "cart",
    lat: 45.576153213700856,
    lng: -73.65538635809772,
    link: "https://goo.gl/maps/ychxMcaFM6EBWypz8",
  },
  {
    id: 282,
    name: "Provigo",
    address: "3185, rue Beaubien Est, Montréal, QC H1Y 1H5",
    icon: "cart",
    lat: 45.55436682171752,
    lng: -73.5874867311114,
    link: "https://goo.gl/maps/yC5CVKSzm19HMFcc6",
  },
  {
    id: 283,
    name: "Provigo",
    address: "169, rue Queen, Lennoxville, QC J1M 1K1",
    icon: "cart",
    lat: 45.36960309964958,
    lng: -71.85728173111845,
    link: "https://goo.gl/maps/vEDENnLdWHNUfGCS8",
  },
  {
    id: 284,
    name: "Provigo",
    address: "10455, Boul St-Laurent, Montréal, QC H3L 2P1",
    icon: "cart",
    lat: 45.549648272091666,
    lng: -73.66699110412448,
    link: "https://goo.gl/maps/DsPwkq3P4ztDQSuo9",
  },
  {
    id: 285,
    name: "Provigo",
    address: "90, Chemin Morgan Baie D`Urfé QC  H9X 3A8",
    icon: "cart",
    lat: 45.41786821598881,
    lng: -73.9176105618004,
    link: "https://goo.gl/maps/8sJsC1RzJ6iQJDNF8",
  },
  {
    id: 286,
    name: "Provigo",
    address: "1155, boul. Talbot  Chicoutimi  QC  G7H 4B5",
    icon: "cart",
    lat: 48.408967392825275,
    lng: -71.05342981565725,
    link: "https://goo.gl/maps/eAc24fL5yaBfCdAUA",
  },
  {
    id: 287,
    name: "Provigo",
    address: "16900, aut. Transcanada, Kirkland, QC H9H 4M7",
    icon: "cart",
    lat: 45.45371965853755,
    lng: -73.85101800227987,
    link: "https://goo.gl/maps/cyvNfdHac2SAirnR7",
  },
  {
    id: 288,
    name: "Provigo",
    address: "501, Boul. St-Charles, Vaudreuil, QC J7V 8V9",
    icon: "cart",
    lat: 45.40507685973097,
    lng: -74.03106137529451,
    link: "https://goo.gl/maps/nGU2mtxvnsTphXJ86",
  },
  {
    id: 289,
    name: "Provigo",
    address: "4849, Boul. St-Jean, Pierrefonds, QC H9H 2A9",
    icon: "cart",
    lat: 45.46650018751036,
    lng: -73.82800381577289,
    link: "https://goo.gl/maps/gUvjBfRZqJfVq2Vs7",
  },
  {
    id: 290,
    name: "Provigo",
    address: "50, Rue St-Denis, St-Sauveur, QC J0R 1R4",
    icon: "cart",
    lat: 45.89061041034584,
    lng: -74.15528564644038,
    link: "https://goo.gl/maps/HYBskLdMyZPS47LW9",
  },
  {
    id: 291,
    name: "Provigo",
    address: "375, Rue Jean-Talon Ouest, Montréal, QC H3N 2Y8",
    icon: "cart",
    lat: 45.53100284273632,
    lng: -73.6228376464542,
    link: "https://goo.gl/maps/vwXEnfTjA8xF9k7W9",
  },
  {
    id: 292,
    name: "Provigo",
    address: "815, Avenue Myrand, Ste-Foy, QC G1V 2V7",
    icon: "cart",
    lat: 46.78911793461697,
    lng: -71.26937747524094,
    link: "https://goo.gl/maps/NJWxZRAN4jbC2jTu5",
  },
  {
    id: 293,
    name: "Provigo",
    address: "6767, Boul. Newman, Lasalle, QC H8N 3E4",
    icon: "cart",
    lat: 45.45145877217816,
    lng: -73.60757700227988,
    link: "https://goo.gl/maps/eaWwbJa6LK468SM9A",
  },
  {
    id: 294,
    name: "Provigo",
    address: "320, boul. Leclerc, Granby, QC J2G 1V3",
    icon: "cart",
    lat: 45.40872753964436,
    lng: -72.7275402747388,
    link: "https://goo.gl/maps/QxzeFkoV7d8Ai6rM6",
  },
  {
    id: 295,
    name: "Provigo",
    address: "1275, avenue des Canadiens-de-Montréal, Suite 200 Montréal  Qc  H3B 0G4",
    icon: "cart",
    lat: 45.496626989188314,
    lng: -73.57068355994913,
    link: "https://goo.gl/maps/ymbz5wE9sFgdq5t96",
  },
  {
    id: 296,
    name: "Provigo",
    address: "1111, boulevard Jutras Est, Victoriaville, QC G6S 1C1",
    icon: "cart",
    lat: 46.05980187563974,
    lng: -71.9267894599274,
    link: "https://goo.gl/maps/M1HN5Qq9yfZB7BzB7",
  },
  {
    id: 297,
    name: "Provigo",
    address: "25, Route 138, C.P. 100, Forestville, QC G0T 1E0",
    icon: "cart",
    lat: 48.7439077764594,
    lng: -69.08678527331476,
    link: "https://goo.gl/maps/QmAQFMZfpsqDP2mq7",
  },
  {
    id: 298,
    name: "Provigo",
    address: "295, rue St-Georges, Windsor, QC J1S 1K3",
    icon: "cart",
    lat: 45.576780269925024,
    lng: -71.99484481576872,
    link: "https://goo.gl/maps/68fxyTtF25zYADq47",
  },
  {
    id: 299,
    name: "Provigo",
    address: "6485, rue Sherbrooke Ouest, Montréal, QC H4B 1N3",
    icon: "cart",
    lat: 45.46458659021265,
    lng: -73.62912356179868,
    link: "https://goo.gl/maps/K9YVoamqMaueRVDJ6",
  },
  {
    id: 300,
    name: "Provigo",
    address: "6000, boul. Laurendeau, Montréal, QC H4E 3X4",
    icon: "cart",
    lat: 45.45869959029842,
    lng: -73.58824091762149,
    link: "https://goo.gl/maps/wcXEBwNXL7eJGjCh7",
  },
  {
    id: 301,
    name: "Provigo",
    address: "300, boul. Lasalle, Baie Comeau, QC G4Z 2K2",
    icon: "cart",
    lat: 49.213265185095025,
    lng: -68.18820690213117,
    link: "https://goo.gl/maps/dXF6oQRTSYKPqqvL7",
  },
  {
    id: 302,
    name: "Provigo",
    address: "25, boul. Kane, La Malbaie, QC G5A 1J2",
    icon: "cart",
    lat: 47.646717732349416,
    lng: -70.15240654452326,
    link: "https://goo.gl/maps/5y5uGgeCou4NCeQeA",
  },
  {
    id: 303,
    name: "Provigo",
    address: "515, Boul. Lacombe, Le Gardeur, QC J5Z 1P5",
    icon: "cart",
    lat: 45.75586752177165,
    lng: -73.465804475281,
    link: "https://goo.gl/maps/u9e8CMfvFjwpwyFHA",
  },
  {
    id: 304,
    name: "Provigo",
    address: "2185, rue Galt Ouest, Sherbrooke, QC J1K 1K3",
    icon: "cart",
    lat: 45.38757142289384,
    lng: -71.92654783111779,
    link: "https://goo.gl/maps/nTS58xML1znoqa6A7",
  },
  {
    id: 305,
    name: "Provigo",
    address: "6700, rue St-Georges, Lévis, QC G6V 4H3",
    icon: "cart",
    lat: 46.8158115419312,
    lng: -71.17193822182101,
    link: "https://goo.gl/maps/eVSvMunHuGuhkEyw8",
  },
  {
    id: 306,
    name: "Provigo",
    address: "50, rue Victoria, Sorel, QC J3P 1Y6",
    icon: "cart",
    lat: 46.03878764745114,
    lng: -73.11222427342183,
    link: "https://goo.gl/maps/mDJa5yh2rDsK4AkP8",
  },
  {
    id: 307,
    name: "Provigo",
    address: "50, Avenue Mont-Royal Ouest Montréal  QC  H2T 2S3",
    icon: "cart",
    lat: 45.519332052464186,
    lng: -73.58670535809998,
    link: "https://goo.gl/maps/ghf7LTeLfF9GWieJ7",
  },
  {
    id: 308,
    name: "Provigo",
    address: "53, Place Quévillon C.P.489, Lebel-sur-Quévillon, QC J0Y 1X0",
    icon: "cart",
    lat: 49.052771043705654,
    lng: -76.97833391747959,
    link: "https://goo.gl/maps/3eEDNVE45Wd7LZwG9",
  },
  {
    id: 309,
    name: "Provigo",
    address: "375, Chemin Aylmer, Aylmer, QC J9H 1A5",
    icon: "cart",
    lat: 45.39658725538252,
    lng: -75.82299973111749,
    link: "https://goo.gl/maps/LRHbHpwZLS39DeiE7",
  },
  {
    id: 310,
    name: "Provigo",
    address: "3421, Avenue du Parc, Montréal, QC H2X 2H6",
    icon: "cart",
    lat: 45.50914357862828,
    lng: -73.5717416311131,
    link: "https://goo.gl/maps/Ko8upXTUkfy9ET4t5",
  },
  {
    id: 311,
    name: "Provigo",
    address: "95, Boul De Gaulle, Lorraine, QC J6Z 3R8",
    icon: "cart",
    lat: 45.66666941133309,
    lng: -73.78181100227162,
    link: "https://goo.gl/maps/VaLw95WcJNAzvGem8",
  },
  {
    id: 312,
    name: "Provigo",
    address: "101, boul. des Haut-Bois, Ste-Julie, QC J3E 3J8",
    icon: "cart",
    lat: 45.57200294442443,
    lng: -73.32609754460437,
    link: "https://goo.gl/maps/oDTtZAms9DxAMLgVA",
  },
  {
    id: 313,
    name: "Provigo",
    address: "1350, rue Sherbrooke, Magog, QC J1X 2T3",
    icon: "cart",
    lat: 45.29575958348301,
    lng: -72.1044463581085,
    link: "https://goo.gl/maps/7Ty76nC3tQjDPGXb7",
  },
  {
    id: 314,
    name: "Provigo",
    address: "1, Boul. Du Plateau Hull, QC J9A 3G1",
    icon: "cart",
    lat: 45.43540336503948,
    lng: -75.76614727344497,
    link: "https://goo.gl/maps/SydcsRyiB7avAGEK8",
  },
  {
    id: 315,
    name: "Provigo",
    address: "80, rue St-Judes Nord, Granby, QC J2J 2T7",
    icon: "cart",
    lat: 45.41851324981669,
    lng: -72.7537582734457,
    link: "https://goo.gl/maps/LwbPcWbjjritfatp9",
  },
  {
    id: 316,
    name: "Provigo",
    address: "1150, rue King-George, Longueuil, QC J4J 2W1",
    icon: "cart",
    lat: 45.53950336066995,
    lng: -73.4673545446056,
    link: "https://goo.gl/maps/ZWx89GrF5ngVduYr8",
  },
  {
    id: 317,
    name: "Provigo",
    address: "2695, Boul. Rideau, Rouyn-Noranda, QC J0Z 1Y0",
    icon: "cart",
    lat: 48.23988763726908,
    lng: -79.12734218867689,
    link: "https://goo.gl/maps/ypBEZstKbZ6xL2fy7",
  },
  {
    id: 318,
    name: "Provigo",
    address: "2120, Roussel Chicoutimi-Nord QC  G7G 1W3",
    icon: "cart",
    lat: 48.43900403851745,
    lng: -71.0616194101112,
    link: "https://goo.gl/maps/m6vRb4ciHpBR5ye48",
  },
  {
    id: 319,
    name: "Provigo",
    address: "224, boul. St-Michel, Mistassini, QC G8L 4P5",
    icon: "cart",
    lat: 48.89168765513181,
    lng: -72.20168115611855,
    link: "https://goo.gl/maps/tWkkGgqVfbS6vuN9A",
  },
  {
    id: 320,
    name: "Provigo",
    address: "2000, rue Casavant Ouest, St-Hyacinthe, QC J2S 7K2",
    icon: "cart",
    lat: 45.6346010378325,
    lng: -72.96851545994384,
    link: "https://goo.gl/maps/xNwspC5MYhyCjGqi7",
  },
  {
    id: 321,
    name: "Provigo",
    address: "501, rue Patry, St-Pascal, QC G0L 3Y0",
    icon: "cart",
    lat: 47.530237570808644,
    lng: -69.81055973103433,
    link: "https://goo.gl/maps/6n59wjgUNbq9tNgXA",
  },
  {
    id: 322,
    name: "Provigo",
    address: "42, Place Du Commerce, Verdun, QC H3E 1J5",
    icon: "cart",
    lat: 45.46809508442619,
    lng: -73.54442545995028,
    link: "https://goo.gl/maps/A6wWSEbxb7GPs8547",
  },
  {
    id: 323,
    name: "Provigo",
    address: "6600, St-Jacques Ouest, Montréal, QC H4B 1V8",
    icon: "cart",
    lat: 45.461208582261825,
    lng: -73.62503583111496,
    link: "https://goo.gl/maps/iewEw3SYi8rrXr1D8",
  },
  {
    id: 324,
    name: "Provigo",
    address: "502, rue Giguère, Val D'Or, QC J9P 7G6",
    icon: "cart",
    lat: 48.09026480589517,
    lng: -77.82459518868289,
    link: "https://goo.gl/maps/wWM6zXbT1sxaGMqo9",
  },
  {
    id: 325,
    name: "Provigo",
    address: "110, Rue Brassard, St-Michel des Saints, QC J0K 3B0",
    icon: "cart",
    lat: 46.66955912056446,
    lng: -73.91422600223271,
    link: "https://goo.gl/maps/NTr2LhNBLkTDs8uZ6",
  },
  {
    id: 326,
    name: "Provigo",
    address: "1100, 13ième Avenue Nord, Sherbrooke, QC J1E 3P1",
    icon: "cart",
    lat: 45.42383411155652,
    lng: -71.87253391577457,
    link: "https://goo.gl/maps/JaWZxJmGyaRSoLHu8",
  },
  {
    id: 327,
    name: "Provigo",
    address: "7000, route 125, Cherstey, QC J0K 3K0",
    icon: "cart",
    lat: 46.066683091337715,
    lng: -73.87995991759809,
    link: "https://goo.gl/maps/9hq7aQTQcaYvgNLF6",
  },
  {
    id: 328,
    name: "Provigo",
    address: "649, boul. Laure, suite 100, Sept-Iles, QC G4R 1X8",
    icon: "cart",
    lat: 50.21605434349736,
    lng: -66.38097735791267,
    link: "https://goo.gl/maps/ZpgqDP84zHETFgAs7",
  },
  {
    id: 329,
    name: "Provigo",
    address: "7777, boul. Guillaume-Couture, Lévis, QC G6V 6Z1",
    icon: "cart",
    lat: 46.81668461165477,
    lng: -71.14653100222695,
    link: "https://goo.gl/maps/cWtGRG2SrRdy8eVt9",
  },
  {
    id: 330,
    name: "Provigo",
    address: "775, boul St-Joseph, Gatineau, QC J8Y 4C1",
    icon: "cart",
    lat: 45.46078373484343,
    lng: -75.74155991762146,
    link: "https://goo.gl/maps/S32mijwKacp3ZF1E7",
  },
  {
    id: 331,
    name: "Provigo",
    address: "1461, avenue Victoria, St-Lambert, QC J4R 1R5",
    icon: "cart",
    lat: 45.489660491100516,
    lng: -73.49263754460749,
    link: "https://goo.gl/maps/Ye9rM2wVcx7gM91q7",
  },
  {
    id: 332,
    name: "Provigo",
    address: "2020, boul. René Gaultier, local, Varennes, QC J3X 1N9",
    icon: "cart",
    lat: 45.678013367266196,
    lng: -73.43564264644854,
    link: "https://goo.gl/maps/YLSNrV2yTWhWdX7u6",
  },
  {
    id: 333,
    name: "Provigo",
    address: "7600, Rue Sherbrooke Est, Montréal, QC H1N 3W1",
    icon: "cart",
    lat: 45.591686856267195,
    lng: -73.53600976549038,
    link: "https://goo.gl/maps/14gNet9n2kqxaCzYA",
  },
  {
    id: 334,
    name: "Provigo",
    address: "5595, avenue Monkland, Montréal, QC H4A 1E1",
    icon: "cart",
    lat: 45.47627271909633,
    lng: -73.62283973111445,
    link: "https://goo.gl/maps/hwFSAfT1weNC3Nbn9",
  },
  {
    id: 335,
    name: "Real Canadian Superstore",
    address: "11541 Kingsway Avenue, Edmonton, AB T5G 3E8",
    icon: "cart",
    lat: 53.56496067570813,
    lng: -113.52077525419378,
    link: "https://goo.gl/maps/qk3965suueq9gbo58",
  },
  {
    id: 336,
    name: "Real Canadian Superstore",
    address: "130 PTH 12 North, Steinbach, MB R5G 1T4",
    icon: "cart",
    lat: 49.54399539568784,
    lng: -96.69089219733912,
    link: "https://goo.gl/maps/wypRFHg64ypMQnYy5",
  },
  {
    id: 337,
    name: "Real Canadian Superstore",
    address: "971 Carrick Street, Thunder Bay, ON P7B 6L9",
    icon: "cart",
    lat: 48.40983641500295,
    lng: -89.2500419175056,
    link: "https://goo.gl/maps/1iPca5bdtCukUeQa6",
  },
  {
    id: 338,
    name: "Real Canadian Superstore",
    address: "2132 Mcphillips St., Winnipeg, MB R2V 3C8",
    icon: "cart",
    lat: 49.94811776712026,
    lng: -97.15255218860752,
    link: "https://goo.gl/maps/fKZ2J6Bvhrm6d9P8A",
  },
  {
    id: 339,
    name: "Real Canadian Superstore",
    address: "1578 Regent Ave West, Winnipeg, MB R2C 3B4",
    icon: "cart",
    lat: 49.89769076719778,
    lng: -97.06526951800063,
    link: "https://goo.gl/maps/3n72UW6S7oaiMHoy6",
  },
  {
    id: 340,
    name: "Real Canadian Superstore",
    address: "3193 Portage Avenue, Winnipeg, MB R3K 0W4",
    icon: "cart",
    lat: 49.88103281503679,
    lng: -97.28683654443293,
    link: "https://goo.gl/maps/1WzDU1s8MDUpNoGF7",
  },
  {
    id: 341,
    name: "Real Canadian Superstore",
    address: "80 Bison Drive, Winnipeg, MB, R3T 4Z7",
    icon: "cart",
    lat: 49.80057432803421,
    lng: -97.1636005444362,
    link: "https://goo.gl/maps/QG5pkZN8ehncQUnu7",
  },
  {
    id: 342,
    name: "Real Canadian Superstore",
    address: "PO Box 870 175 Cargill Road, Winkler, MB R6W 4A9",
    icon: "cart",
    lat: 49.18647923907442,
    lng: -97.93209262911945,
    link: "https://goo.gl/maps/ntBYa1zCRAtzyocy9",
  },
  {
    id: 343,
    name: "Real Canadian Superstore",
    address: "1385 Sargent Avenue, Winnipeg, MB R3E 3P8",
    icon: "cart",
    lat: 49.898100308585676,
    lng: -97.1986390001201,
    link: "https://goo.gl/maps/2brc5iCZYAfAcqTT9",
  },
  {
    id: 344,
    name: "Real Canadian Superstore",
    address: "1035 Gateway Rd Winnipeg, MB R2K 4C1",
    icon: "cart",
    lat: 49.92926710967949,
    lng: -97.07076308675994,
    link: "https://goo.gl/maps/DGqGHihxnHKENssB6",
  },
  {
    id: 345,
    name: "Real Canadian Superstore",
    address: "215 St Anne's Rd., Winnipeg, MB R2M 2Z9",
    icon: "cart",
    lat: 49.8556085949529,
    lng: -97.10387727128463,
    link: "https://goo.gl/maps/6DNNufahF87Knkqy7",
  },
  {
    id: 346,
    name: "Real Canadian Superstore",
    address: "920 Victoria Avenue, Brandon, MB R7A 1A7",
    icon: "cart",
    lat: 49.842346467357444,
    lng: -99.95145418861179,
    link: "https://goo.gl/maps/Gd9FLvEVq1CKcvbX9",
  },
  {
    id: 347,
    name: "Real Canadian Superstore",
    address: "550 Kenaston Blvd, Winnipeg, MB R3N 2A1",
    icon: "cart",
    lat: 49.856722735883324,
    lng: -97.20424259600443,
    link: "https://goo.gl/maps/dzr9FeLGkKSK5ReJA",
  },
  {
    id: 348,
    name: "Real Canadian Superstore",
    address: "350 S.E. Marine Dr, Vancouver, BC V5X 2S5",
    icon: "cart",
    lat: 49.20869620987463,
    lng: -123.0994465444604,
    link: "https://goo.gl/maps/JWJgg2PFnSHAA8wM9",
  },
  {
    id: 349,
    name: "Real Canadian Superstore",
    address: "#1105 Eaton Ctr. 4700 Kingsway, Burnaby, BC V5H 4M1",
    icon: "cart",
    lat: 49.22646211333692,
    lng: -123.00277045980152,
    link: "https://goo.gl/maps/s4xVf63gNDUCtyeV7",
  },
  {
    id: 350,
    name: "Real Canadian Superstore",
    address: "1301 Lougheed Highway, Coquitlam, BC V3K 6P9",
    icon: "cart",
    lat: 49.23523579627512,
    lng: -122.85590800213029,
    link: "https://goo.gl/maps/DvGLSdESWKKJVeRw5",
  },
  {
    id: 351,
    name: "Real Canadian Superstore",
    address: "3185 Grandview Highway, Vancouver, BC V5M 2E9",
    icon: "cart",
    lat: 49.259127033157384,
    lng: -123.03661781562286,
    link: "https://goo.gl/maps/mxmaFzFnF2XQiM8E7",
  },
  {
    id: 352,
    name: "Real Canadian Superstore",
    address: "7550 King George Boulevard, Surrey, BC V3W 2T2",
    icon: "cart",
    lat: 49.14000632044503,
    lng: -122.8428435444632,
    link: "https://goo.gl/maps/Uo5RsBLujfpKmQob8",
  },
  {
    id: 353,
    name: "Real Canadian Superstore",
    address: "910 Columbia Street West, Kamloops, BC V2C 1L2",
    icon: "cart",
    lat: 50.668663992081555,
    lng: -120.35593393589595,
    link: "https://goo.gl/maps/kewDruoUP2V8QDna6",
  },
  {
    id: 354,
    name: "Real Canadian Superstore",
    address: "45779 Luckakuck Way, Chilliwack, BC V2R 4E8",
    icon: "cart",
    lat: 49.14328638607556,
    lng: -121.95978218864046,
    link: "https://goo.gl/maps/Ywrp1N7AnsTjBu15A",
  },
  {
    id: 355,
    name: "Real Canadian Superstore",
    address: "1424 Island Highway, Campbell River, BC V9W 2E3",
    icon: "cart",
    lat: 50.036339931968854,
    lng: -125.2480898174393,
    link: "https://goo.gl/maps/gbcvo2un5qBND2jo9",
  },
  {
    id: 356,
    name: "Real Canadian Superstore",
    address: "6435 Metral Dr., Nanaimo, BC V9T 2L9",
    icon: "cart",
    lat: 49.23070912573289,
    lng: -124.04610437514324,
    link: "https://goo.gl/maps/4ebXcYyqzxM6RhJE9",
  },
  {
    id: 357,
    name: "Real Canadian Superstore",
    address: "3000 Lougheed Hwy., Coquitlam, BC V3B 1C5",
    icon: "cart",
    lat: 49.27377197916952,
    lng: -122.79331163096411,
    link: "https://goo.gl/maps/K7VhW41h3o9KGT736",
  },
  {
    id: 358,
    name: "Real Canadian Superstore",
    address: "835 Langford Parkway, Victoria, BC V9B 4V5",
    icon: "cart",
    lat: 48.44158103019131,
    lng: -123.50438291750424,
    link: "https://goo.gl/maps/d63oE5ThGAdnVZcz8",
  },
  {
    id: 359,
    name: "Real Canadian Superstore",
    address: "757 Ryan Road, Courtenay, BC V9N 3R6",
    icon: "cart",
    lat: 49.69750917974181,
    lng: -124.9865096806691,
    link: "https://goo.gl/maps/heznpLERue65SAby8",
  },
  {
    id: 360,
    name: "Real Canadian Superstore",
    address: "2270-2nd Avenue, Whitehorse, YT Y1A 1C8",
    icon: "cart",
    lat: 60.72945537048571,
    lng: -135.06229291696678,
    link: "https://goo.gl/maps/7ESrNUrS5tWu8c4w5",
  },
  {
    id: 361,
    name: "Real Canadian Superstore",
    address: "5001 Anderson Way, Vernon BC V1T 9V1",
    icon: "cart",
    lat: 50.28403523479376,
    lng: -119.27171364626463,
    link: "https://goo.gl/maps/GEYgDAmjjeC55Ned7",
  },
  {
    id: 362,
    name: "Real Canadian Superstore",
    address: "#100 - 2210 Main Street, Penticton, BC V2A 5H8",
    icon: "cart",
    lat: 49.47375032545894,
    lng: -119.58074308677861,
    link: "https://goo.gl/maps/MrdgNAcg7FjDrPge6",
  },
  {
    id: 363,
    name: "Real Canadian Superstore",
    address: "Unit 1200 - 3806 Albert Street, Regina, SK S4S 3R2",
    icon: "cart",
    lat: 50.41757105051963,
    lng: -104.61961454441081,
    link: "https://goo.gl/maps/MnPWdGHsQ2KAXkrj9",
  },
  {
    id: 364,
    name: "Real Canadian Superstore",
    address: "2901-8th Street East, Saskatoon, SK S7H 0V4",
    icon: "cart",
    lat: 52.11599892039374,
    lng: -106.6138085443395,
    link: "https://goo.gl/maps/7BJuqCfMsXnbsifd7",
  },
  {
    id: 365,
    name: "Real Canadian Superstore",
    address: "411 Confederation Dr, Saskatoon, SK S7L 5C3",
    icon: "cart",
    lat: 52.133160621651975,
    lng: -106.72440562899688,
    link: "https://goo.gl/maps/JX8W2AMP2fGmSJFo7",
  },
  {
    id: 366,
    name: "Real Canadian Superstore",
    address: "11839 26 Ave SW, Edmonton, AB T6W 3R3",
    icon: "cart",
    lat: 53.408293264669474,
    lng: -113.53677710380346,
    link: "https://goo.gl/maps/gzVQMp6dAX6XzR2B7",
  },
  {
    id: 367,
    name: "Real Canadian Superstore",
    address: "1155 Windermere Way SW, Edmonton, AB T6W 2B6",
    icon: "cart",
    lat: 53.437630347213045,
    lng: -113.61729827053281,
    link: "https://goo.gl/maps/X9VHhXULG9Qwq6UM6",
  },
  {
    id: 368,
    name: "Real Canadian Superstore",
    address: "#100, 20 Heritage Meadows Way SE, Calgary, AB T2H 3C1",
    icon: "cart",
    lat: 50.98604331965054,
    lng: -114.04394218856443,
    link: "https://goo.gl/maps/g2sfcjuSuskXacjZ9",
  },
  {
    id: 369,
    name: "Real Canadian Superstore",
    address: "300 Veterans Blvd NE, Airdrie, AB T4B 3P2",
    icon: "cart",
    lat: 51.301034051308484,
    lng: -114.01060000204488,
    link: "https://goo.gl/maps/VpWyzNAR67WZA3wk7",
  },
  {
    id: 370,
    name: "Real Canadian Superstore",
    address: "3515 Mayor MaGrath Drive S., Lethbridge, AB T1K 8A8",
    icon: "cart",
    lat: 49.65953379878514,
    lng: -112.79274658049971,
    link: "https://goo.gl/maps/KwGxGApVgqjkN8AE7",
  },
  {
    id: 371,
    name: "Real Canadian Superstore",
    address: "Unit 100 3633 Westwinds Drive N.E., Calgary, AB T3J 5K3",
    icon: "cart",
    lat: 51.10922753104266,
    lng: -113.9709636462302,
    link: "https://goo.gl/maps/SAZyEhwAwgC7uL6P6",
  },
  {
    id: 372,
    name: "Real Canadian Superstore",
    address: "100 Country Village Road NE, Calgary, AB T3K 5Z2",
    icon: "cart",
    lat: 51.162268914001224,
    lng: -114.06946625787332,
    link: "https://goo.gl/maps/3cGg5F3ByYh3Vgqc9",
  },
  {
    id: 373,
    name: "Real Canadian Superstore",
    address: "12225-99th Street, Grande Prairie, AB T8V 6X9",
    icon: "cart",
    lat: 55.18977997260293,
    lng: -118.79062854420641,
    link: "https://goo.gl/maps/GvfieeWSCSEZUjSF7",
  },
  {
    id: 374,
    name: "Real Canadian Superstore",
    address: "7020 4th Street NW, Calgary, AB, T2K 1C4",
    icon: "cart",
    lat: 51.11604861922089,
    lng: -114.07126271369793,
    link: "https://goo.gl/maps/MuDBj4xTYHb3aNPu8",
  },
  {
    id: 375,
    name: "Real Canadian Superstore",
    address: "#100 4700 130th Avenue SE, Calgary, AB T2Z 4E7",
    icon: "cart",
    lat: 50.933694017845035,
    lng: -113.96677390021189,
    link: "https://goo.gl/maps/L8Hi1YyAe2whCVsU6",
  },
  {
    id: 376,
    name: "Real Canadian Superstore",
    address: "7005-48th Avenue, Camrose, AB T4V 4W1",
    icon: "cart",
    lat: 53.01800496994206,
    lng: -112.86069073080739,
    link: "https://goo.gl/maps/aiCAeUqfhs8ULnWWA",
  },
  {
    id: 377,
    name: "Real Canadian Superstore",
    address: "9 Haineault Street, Ft Mcmurray, AB T9H 1R8",
    icon: "cart",
    lat: 56.7235390155789,
    lng: -111.37650464598634,
    link: "https://goo.gl/maps/LcEhiowMdGXxFkUM8",
  },
  {
    id: 378,
    name: "Real Canadian Superstore",
    address: "9711-23rd Ave. N.W., Edmonton, AB T6N 1K7",
    icon: "cart",
    lat: 53.45336553325283,
    lng: -113.4811407307887,
    link: "https://goo.gl/maps/D5DgxFkk92Axeewn7",
  },
  {
    id: 379,
    name: "Real Canadian Superstore",
    address: "1792 Trans Canada Way S.E., Medicine Hat, AB T1B 4C6",
    icon: "cart",
    lat: 50.00302954558432,
    lng: -110.65204135976983,
    link: "https://goo.gl/maps/ZVFbeQjrXhAWoFFz8",
  },
  {
    id: 380,
    name: "Real Canadian Superstore",
    address: "2332 160th St., Surrey, BC V3S 9N6",
    icon: "cart",
    lat: 49.044095857596076,
    lng: -122.77874010213804,
    link: "https://goo.gl/maps/pawrgQ1CAm3YtuSH8",
  },
  {
    id: 381,
    name: "Real Canadian Superstore",
    address: "3020 Louie Drive  Westbank  BC  V4T 3E1",
    icon: "cart",
    lat: 49.83607673553839,
    lng: -119.62130073094119,
    link: "https://goo.gl/maps/EvPiCeSUf6ZXQcW69",
  },
  {
    id: 382,
    name: "Real Canadian Superstore",
    address: "2100 - 17th Street N. Cranbrook BC  V1C 7J1",
    icon: "cart",
    lat: 49.52937899792886,
    lng: -115.75223378280194,
    link: "https://goo.gl/maps/8j37srcAW492c3wQA",
  },
  {
    id: 383,
    name: "Real Canadian Superstore",
    address: "8195 - 120th Street DELTA BC  V4C 6P7",
    icon: "cart",
    lat: 49.15183927299491,
    lng: -122.89179073096918,
    link: "https://goo.gl/maps/dinzmMJfvF7Le7UK8",
  },
  {
    id: 384,
    name: "Real Canadian Superstore",
    address: "19800 Lougheed Hwy Suite 201  Pitt Meadows  BC  V3Y 2W1",
    icon: "cart",
    lat: 49.21984417495673,
    lng: -122.67185341590226,
    link: "https://goo.gl/maps/BwLRsmg8i59bhS3y9",
  },
  {
    id: 385,
    name: "Real Canadian Superstore",
    address: "14650 - 104th Avenue  Surrey  BC  V3R 1M3",
    icon: "cart",
    lat: 49.19096728444751,
    lng: -122.81584073096757,
    link: "https://goo.gl/maps/F2UEmmmB776VRLMg6",
  },
  {
    id: 385,
    name: "Real Canadian Superstore",
    address: "4651 No.3 Road, Richmond, BC V6X 2C4",
    icon: "cart",
    lat: 49.179564366560626,
    lng: -123.13835090213259,
    link: "https://goo.gl/maps/BmusdsyuCmrS97B58",
  },
  {
    id: 386,
    name: "Real Canadian Superstore",
    address: "2855 Gladwin Road, Abbotsford, BC V2T 6Y4",
    icon: "cart",
    lat: 49.05416160135121,
    lng: -122.31756127330219,
    link: "https://goo.gl/maps/BRqPUtgKv29eka44A",
  },
  {
    id: 387,
    name: "Real Canadian Superstore",
    address: "32136 Lougheed Highway, Mission, BC V2V 1A4",
    icon: "cart",
    lat: 49.13124848419771,
    lng: -122.3336415598054,
    link: "https://goo.gl/maps/6RdGB6GeDkvvjrLv8",
  },
  {
    id: 388,
    name: "Real Canadian Superstore",
    address: "333 Seymour Blvd., North Vancouver, BC V7J 2J4",
    icon: "cart",
    lat: 49.31167287690356,
    lng: -123.02438303521474,
    link: "https://goo.gl/maps/jLYRF27xJm43FNE78",
  },
  {
    id: 389,
    name: "Real Canadian Superstore",
    address: "19851 Willowbrook Drive, Langley, BC V2Y 1A7",
    icon: "cart",
    lat: 49.11860852893865,
    lng: -122.67338073097052,
    link: "https://goo.gl/maps/EwmxtEe2oJPAWGDx7",
  },
  {
    id: 390,
    name: "Real Canadian Superstore",
    address: "2155 Ferry Avenue, Prince George, BC V2N 5E8",
    icon: "cart",
    lat: 53.887370769641215,
    lng: -122.76919781542794,
    link: "https://goo.gl/maps/eaWpF21Ub2LYyAJAA",
  },
  {
    id: 391,
    name: "Real Canadian Superstore",
    address: "291 Cowichan Way P.R. #6, Duncan, BC V9L 4T8",
    icon: "cart",
    lat: 48.7746005315982,
    lng: -123.7041893733136,
    link: "https://goo.gl/maps/fq57zm3TnD8pjYe5A",
  },
  {
    id: 398,
    name: "Real Canadian Superstore",
    address: "2280 Baron Road, Kelowna, BC V1X 7W3",
    icon: "cart",
    lat: 49.88115172803247,
    lng: -119.4317700021039,
    link: "https://goo.gl/maps/s39sABaE62YisgB28",
  },
  {
    id: 399,
    name: "Real Canadian Superstore",
    address: "#10 - 110 Jennifer Heil Way, Spruce Grove, AB T7X 3Z3",
    icon: "cart",
    lat: 53.54435110748167,
    lng: -113.93316408660732,
    link: "https://goo.gl/maps/ryFoiv84JytDxHnS8",
  },
  {
    id: 400,
    name: "Real Canadian Superstore",
    address: "4950 - 137 Avenue NW, Edmonton, AB T5Y 2V4",
    icon: "cart",
    lat: 53.59730289209093,
    lng: -113.41228000194698,
    link: "https://goo.gl/maps/VtT3pqURXBmB5T7L7",
  },
  {
    id: 401,
    name: "Real Canadian Superstore",
    address: "#100-410 Baseline Road, Sherwood Park, AB T8H 2A7",
    icon: "cart",
    lat: 53.54242774308257,
    lng: -113.2931841884557,
    link: "https://goo.gl/maps/7bF9VRcLoTAiiADF9",
  },
  {
    id: 402,
    name: "Real Canadian Superstore",
    address: "101 St.Albert Trail, St. Albert, AB T8N 6L5",
    icon: "cart",
    lat: 53.620706708779096,
    lng: -113.60442191673232,
    link: "https://goo.gl/maps/ZnzBfWcegyJJpm7T6",
  },
  {
    id: 403,
    name: "Real Canadian Superstore",
    address: "4410-17 Street NW, Edmonton, AB T6T 0C1",
    icon: "cart",
    lat: 53.48058092080156,
    lng: -113.37519371544555,
    link: "https://goo.gl/maps/TKoNgiSEtBpcLqDV6",
  },
  {
    id: 404,
    name: "Real Canadian Superstore",
    address: "4821 Calgary Trail N.W., Edmonton, AB T6H 5W8",
    icon: "cart",
    lat: 53.48638211077765,
    lng: -113.4945684442808,
    link: "https://goo.gl/maps/Q6S3YH6sgK4dTrXX8",
  },
  {
    id: 405,
    name: "Real Canadian Superstore",
    address: "5031 - 44th Street, Lloydminster, AB T9V 0A6",
    icon: "cart",
    lat: 53.27808990167559,
    lng: -110.00949067256981,
    link: "https://goo.gl/maps/4M1GUSUjkvsDNepa7",
  },
  {
    id: 406,
    name: "Real Canadian Superstore",
    address: "12350 - 137 Ave. NW, Edmonton, AB T5L 4X6",
    icon: "cart",
    lat: 53.59984410219412,
    lng: -113.53612583080387,
    link: "https://goo.gl/maps/KyVy8Bqotg7Nw8em7",
  },
  {
    id: 407,
    name: "Real Canadian Superstore",
    address: "17303 Stony Plain Road NW, Edmonton, AB T5S 1B5",
    icon: "cart",
    lat: 53.5415801229514,
    lng: -113.62006000194938,
    link: "https://goo.gl/maps/r4WChEY1Mua2nAy69",
  },
  {
    id: 408,
    name: "Real Canadian Superstore",
    address: "10505 Southport Road SW, Calgary, AB T2W 3N2",
    icon: "cart",
    lat: 50.96097170658527,
    lng: -114.07284562904624,
    link: "https://goo.gl/maps/H3tWksSKg5tG83848",
  },
  {
    id: 409,
    name: "Real Canadian Superstore",
    address: "5251 Country Hills Bv. N.W., Calgary, AB T3A 5H8",
    icon: "cart",
    lat: 51.1366201552773,
    lng: -114.16121554438078,
    link: "https://goo.gl/maps/J8JsaKpbjVNj9HuR7",
  },
  {
    id: 410,
    name: "Real Canadian Superstore",
    address: "3575-20th Avenue N.E., Calgary, AB T1Y 6R3",
    icon: "cart",
    lat: 51.071584774712264,
    lng: -113.98250927321901,
    link: "https://goo.gl/maps/y37wzoCBHhLzfGLC9",
  },
  {
    id: 411,
    name: "Real Canadian Superstore",
    address: "5858 Signal Hill Cntr S.W., Calgary, AB T3H 3P8",
    icon: "cart",
    lat: 51.01968715607683,
    lng: -114.17425398671475,
    link: "https://goo.gl/maps/6XzbFQUPsxE4EHXv5",
  },
  {
    id: 412,
    name: "Real Canadian Superstore",
    address: "100-15915 Macleod Tr. S.E., Calgary, AB T2Y 3R9",
    icon: "cart",
    lat: 50.90983404450735,
    lng: -114.06763291740312,
    link: "https://goo.gl/maps/rqv2MSC1JxVSQ13F8",
  },
  {
    id: 413,
    name: "Real Canadian Superstore",
    address: "A-5016-51 Avenue, Red Deer, AB T4N 4H5",
    icon: "cart",
    lat: 52.2702831566784,
    lng: -113.81722781549745,
    link: "https://goo.gl/maps/TsvDt86Dj6J4jyob7",
  },
  {
    id: 414,
    name: "Real Canadian Superstore",
    address: "591-15th Street East,  Prince Albert, SK S6V 1G3",
    icon: "cart",
    lat: 53.19952365268173,
    lng: -105.73800000196417,
    link: "https://goo.gl/maps/1xmvACCqZRhSLuKj8",
  },
  {
    id: 415,
    name: "Real Canadian Superstore",
    address: "206 Broadway St. East, Yorkton, SK S3N 3K4",
    icon: "cart",
    lat: 51.21022684031313,
    lng: -102.45120433273249,
    link: "https://goo.gl/maps/UUQVArynf7cKfG9c8",
  },
  {
    id: 416,
    name: "Real Canadian Superstore",
    address: "30 Thatcher Drive East, Moose Jaw, SK S6J 1L7",
    icon: "cart",
    lat: 50.41291857756806,
    lng: -105.53211291742373,
    link: "https://goo.gl/maps/mqNEbikCFFmQoyjH9",
  },
  {
    id: 417,
    name: "Real Canadian Superstore",
    address: "2055 Prince Of Wales Dr., Regina, SK S4V 3A3",
    icon: "cart",
    lat: 50.4313064348725,
    lng: -104.53749874650603,
    link: "https://goo.gl/maps/bbjjqNpFstuNudpE6",
  },
  {
    id: 418,
    name: "Real Canadian Superstore",
    address: "4450 Rochdale Blvd., Regina, SK S4X 4N9",
    icon: "cart",
    lat: 50.49693654942023,
    lng: -104.64412917324296,
    link: "https://goo.gl/maps/z5zejihoqprPkDzK7",
  },
  {
    id: 419,
    name: "Real Canadian Superstore",
    address: "19655 Seton Way SE, Calgary, AB T3M 2N9",
    icon: "cart",
    lat: 50.87479968037137,
    lng: -113.96094852849436,
    link: "https://goo.gl/maps/DJatwut4GHr1k5hf6",
  },
  {
    id: 420,
    name: "Real Canadian Superstore",
    address: "#240, 540 3rd street SE, Calgary, AB T2G 0G7",
    icon: "cart",
    lat: 51.046870625873694,
    lng: -114.05512327321999,
    link: "https://goo.gl/maps/XyKDHjxrWAeftsmB7",
  },
  {
    id: 421,
    name: "Real Canadian Superstore",
    address: "2549 Weston Road, Toronto, ON M9N 2A7",
    icon: "cart",
    lat: 43.70863815493636,
    lng: -79.53387554467479,
    link: "https://goo.gl/maps/7TjrzUFGNRb6PvLVA",
  },
  {
    id: 422,
    name: "Real Canadian Superstore",
    address: "85 Steeles Ave West, Brampton ON L6Y 0B5",
    icon: "cart",
    lat: 43.664443486855035,
    lng: -79.7336536293345,
    link: "https://goo.gl/maps/ikUoHesmABybySgD8",
  },
  {
    id: 423,
    name: "Real Canadian Superstore",
    address: "1485 Lasalle Blvd, Sudbury, ON P3A 5H7",
    icon: "cart",
    lat: 46.52189338928751,
    lng: -80.94353581573212,
    link: "https://goo.gl/maps/zR3oHkHwRcvskBaU9",
  },
  {
    id: 424,
    name: "Real Canadian Superstore",
    address: "361 South Service Road, Grimsby, ON L3M 4E8",
    icon: "cart",
    lat: 43.20524430760247,
    lng: -79.59024764654171,
    link: "-79.59024764654171",
  },
  {
    id: 425,
    name: "Real Canadian Superstore",
    address: "1755 Brimley Road, Scarborough, ON M1P OA3",
    icon: "cart",
    lat: 43.77592716305638,
    lng: -79.26321372895556,
    link: "https://goo.gl/maps/y2ZypaSpLNH6DdQY6",
  },
  {
    id: 426,
    name: "Real Canadian Superstore",
    address: "820 Main Street East, Milton, ON L9T 0J4",
    icon: "cart",
    lat: 43.52575586486371,
    lng: -79.86684376556829,
    link: "https://goo.gl/maps/cArFY3GmfqJxScUH9",
  },
  {
    id: 427,
    name: "Real Canadian Superstore",
    address: "171 Guelph Street, Georgetown, ON L7G 4A1",
    icon: "cart",
    lat: 43.65072989458056,
    lng: -79.9081122668565,
    link: "https://goo.gl/maps/HUZUUSov7NiF2mPH8",
  },
  {
    id: 428,
    name: "Real Canadian Superstore",
    address: "1205 Oxford Street, London, ON N6H 1V9",
    icon: "cart",
    lat: 42.97673231551446,
    lng: -81.32286854470179,
    link: "https://goo.gl/maps/q9mcxRKLB9X73sBx6",
  },
  {
    id: 429,
    name: "Real Canadian Superstore",
    address: "760 Eagleson Road, Ottawa, ON K2M 0A7",
    icon: "cart",
    lat: 45.28296925013054,
    lng: -75.86654154461542,
    link: "https://goo.gl/maps/aXonF7YNmsgBdHbGA",
  },
  {
    id: 430,
    name: "Real Canadian Superstore",
    address: "125 Queensway East, Simcoe, ON N3Y 4M5",
    icon: "cart",
    lat: 42.85128194579677,
    lng: -80.29541600237735,
    link: "https://goo.gl/maps/58NyiRLS1rc9Bgzp6",
  },
  {
    id: 431,
    name: "Real Canadian Superstore",
    address: "875 Highland Road West, Kitchener, ON N2N 2Y2",
    icon: "cart",
    lat: 43.42952097544696,
    lng: -80.52626091769788,
    link: "https://goo.gl/maps/Q7bznQCteZgfbz4D6",
  },
  {
    id: 432,
    name: "Real Canadian Superstore",
    address: "626 Victoria Street, Strathroy, ON N7G 3C1",
    icon: "cart",
    lat: 42.96739609863262,
    lng: -81.63086000237305,
    link: "https://goo.gl/maps/yT4dDAivaVYG5igQ9",
  },
  {
    id: 433,
    name: "Real Canadian Superstore",
    address: "600 Murphy Road, Sarnia, ON N7S 2X1",
    icon: "cart",
    lat: 42.97761581743953,
    lng: -82.36541435819531,
    link: "https://goo.gl/maps/9Z7maRAXPvY3MDuT9",
  },
  {
    id: 434,
    name: "Real Canadian Superstore",
    address: "2430 Dougall Ave., Windsor, ON N8X 1T2",
    icon: "cart",
    lat: 42.28868446283536,
    lng: -83.0235902735623,
    link: "https://goo.gl/maps/yCGAaSJQjyBdbHv76",
  },
  {
    id: 435,
    name: "Real Canadian Superstore",
    address: "769 Borden Avenue, Peterborough, ON K9J 0B6",
    icon: "cart",
    lat: 44.282422681499234,
    lng: -78.33373118883065,
    link: "https://goo.gl/maps/LfYjwH8aV2HF1cN77",
  },
  {
    id: 436,
    name: "Real Canadian Superstore",
    address: "3045 Mavis Road, Mississauga, ON L5C 1T7",
    icon: "cart",
    lat: 43.567804868776385,
    lng: -79.63363254468003,
    link: "https://goo.gl/maps/d5iTA8uRMr2Kr2fH9",
  },
  {
    id: 437,
    name: "Real Canadian Superstore",
    address: "481 Gibb Street, Oshawa, ON L1J 1Z4",
    icon: "cart",
    lat: 43.885573618891506,
    lng: -78.87870791897377,
    link: "https://goo.gl/maps/LD6K5y4nRreFwbiV6",
  },
  {
    id: 438,
    name: "Real Canadian Superstore",
    address: "190 Richmond Road, Ottawa, ON K1Z 6W6",
    icon: "cart",
    lat: 45.39467460147704,
    lng: -75.74753591762399,
    link: "https://goo.gl/maps/8g9JBT2sTYCaGgQu6",
  },
  {
    id: 439,
    name: "Real Canadian Superstore",
    address: "30 Kingston Road West Ajax  ON  L1T 4K8",
    icon: "cart",
    lat: 43.86074254421016,
    lng: -79.0306992470729,
    link: "https://goo.gl/maps/G2oKM7TXkyScuqnQ7",
  },
  {
    id: 440,
    name: "Real Canadian Superstore",
    address: "1972 Parkedale Avenue Brockville  ON  K6V 5T2",
    icon: "cart",
    lat: 44.61370391808475,
    lng: -75.68828286608313,
    link: "https://goo.gl/maps/hngH7Bo55S653fFD7",
  },
  {
    id: 441,
    name: "Real Canadian Superstore",
    address: "18120 Yonge Street, Newmarket, ON L3Y 4V8",
    icon: "cart",
    lat: 44.07002356776918,
    lng: -79.48622435815486,
    link: "https://goo.gl/maps/58P3FUejfv9bEYtH6",
  },
  {
    id: 442,
    name: "Real Canadian Superstore",
    address: "201 Oak Park Drive, Oakville, ON L6H 6M3",
    icon: "cart",
    lat: 43.48407559131244,
    lng: -79.7222089005057,
    link: "https://goo.gl/maps/u8ju8oQavE3UrTyL8",
  },
  {
    id: 443,
    name: "Real Canadian Superstore",
    address: "15900 Bayview Avenue, Aurora, ON L4G 7Y3",
    icon: "cart",
    lat: 44.02246141877145,
    lng: -79.44947035815662,
    link: "https://goo.gl/maps/sff5CX9eMXSGC6st7",
  },
  {
    id: 444,
    name: "Real Canadian Superstore",
    address: "51 Gerry Fitzgerald Dr, Toronto, ON M3J 3N4",
    icon: "cart",
    lat: 43.78490890137715,
    lng: -79.47169226833623,
    link: "https://goo.gl/maps/2gnbwEF2dH48vA1C9",
  },
  {
    id: 445,
    name: "Real Canadian Superstore",
    address: "1385 Harmony Rd, North Oshawa, ON L1H 7K5",
    icon: "cart",
    lat: 43.941885385018956,
    lng: -78.84392390048868,
    link: "https://goo.gl/maps/xo2PN9YsawtroFRV9",
  },
  {
    id: 446,
    name: "Real Canadian Superstore",
    address: "200 Taunton Road West, Whitby, ON L1R 3H8",
    icon: "cart",
    lat: 43.917945765390336,
    lng: -78.94769452002888,
    link: "https://goo.gl/maps/1hyi7kCLa7H4teh29",
  },
  {
    id: 447,
    name: "Real Canadian Superstore",
    address: "4270 Innes Road, Ottawa ON K4A 5E6",
    icon: "cart",
    lat: 45.45767243991247,
    lng: -75.49647981577326,
    link: "https://goo.gl/maps/iDbPg9CAwGvNPcjD8",
  },
  {
    id: 448,
    name: "Real Canadian Superstore",
    address: "825 Don Mills Road, Toronto, ON M3C 1V4",
    icon: "cart",
    lat: 43.72291332751934,
    lng: -79.33756164652253,
    link: "https://goo.gl/maps/fTK9EQL6ywnaWaRs5",
  },
  {
    id: 449,
    name: "Real Canadian Superstore",
    address: "3050 Argentia Road, Mississauga, ON L5N 8E1",
    icon: "cart",
    lat: 43.59586585130629,
    lng: -79.78406918885626,
    link: "https://goo.gl/maps/tG6btQdfr48Y7bsh6",
  },
  {
    id: 450,
    name: "Real Canadian Superstore",
    address: "825 Oxford Street East, London, ON N5Y 3J8",
    icon: "cart",
    lat: 43.0030519802728,
    lng: -81.22720481586529,
    link: "https://goo.gl/maps/3fTCcTKhD7UWVQk3A",
  },
  {
    id: 451,
    name: "Real Canadian Superstore",
    address: "25-45th Street South, Wasaga Beach, ON L9Z 1A7",
    icon: "cart",
    lat: 44.473384557601335,
    lng: -80.07316472985976,
    link: "https://goo.gl/maps/5XP1nXxCgpZS14Mw9",
  },
  {
    id: 452,
    name: "Real Canadian Superstore",
    address: "1063 Talbot Street Unit 50, St. Thomas, ON N5P 1G4",
    icon: "cart",
    lat: 42.77911034859615,
    lng: -81.17423373121544,
    link: "https://goo.gl/maps/RuGRgtiqTw3N1bv18",
  },
  {
    id: 453,
    name: "Real Canadian Superstore",
    address: "791 St Clair Street North, Chatham, ON N7M 5J7",
    icon: "cart",
    lat: 42.43239635286872,
    lng: -82.21767881588615,
    link: "https://goo.gl/maps/YqbhM2yYfkxUNQh9A",
  },
  {
    id: 454,
    name: "Real Canadian Superstore",
    address: "201 Talbot Street East, Leamington, ON N8H 3X5",
    icon: "cart",
    lat: 42.05674008143468,
    lng: -82.58577464429746,
    link: "https://goo.gl/maps/SHGJ7SV2w4YzhMyw8",
  },
  {
    id: 455,
    name: "Real Canadian Superstore",
    address: "4371 Walker Road, Windsor, ON N8W 3T6",
    icon: "cart",
    lat: 42.255803326132515,
    lng: -82.96477691774083,
    link: "https://goo.gl/maps/ovM9WDrvfaKy3bFx7",
  },
  {
    id: 456,
    name: "Real Canadian Superstore",
    address: "9292 County Road, Midland, ON L4R 4K4",
    icon: "cart",
    lat: 44.73910822742073,
    lng: -79.9142388158006,
    link: "https://goo.gl/maps/U9HWyg2eAbAWepkr7",
  },
  {
    id: 457,
    name: "Real Canadian Superstore",
    address: "411 Louth Street, St. Catharines, ON L2S 4A2",
    icon: "cart",
    lat: 43.153786808240106,
    lng: -79.2661301716824,
    link: "https://goo.gl/maps/eVbxt9DSRW3DrVK66",
  },
  {
    id: 458,
    name: "Valu-Mart",
    address: "710 Kemsley Drive, Sarnia, ON N7V 2M2",
    icon: "cart",
    lat: 43.004369821939044,
    lng: -82.38572956189091,
    link: "https://goo.gl/maps/kEMG3r1K4iYjo3PL7",
  },
  {
    id: 459,
    name: "Valu-Mart",
    address: "83 Underhill Drive, Don Mills, ON M3A 2P5",
    icon: "cart",
    lat: 43.746305741996636,
    lng: -79.32488749439558,
    link: "https://goo.gl/maps/cU9DkZqTPLqM9hqJ9",
  },
  {
    id: 460,
    name: "Valu-Mart",
    address: "42 Russell St. W, Lindsay, ON K9V 2W9",
    icon: "cart",
    lat: 44.35392381048908,
    lng: -78.73674745999249,
    link: "https://goo.gl/maps/cU9DkZqTPLqM9hqJ9",
  },
  {
    id: 461,
    name: "Valu-Mart",
    address: "55 Josephine St., Wingham, ON N0G 2W0",
    icon: "cart",
    lat: 43.88178023769322,
    lng: -81.3157804600102,
    link: "https://goo.gl/maps/Si7mWTQd5si2yxLv9",
  },
  {
    id: 462,
    name: "Valu-Mart",
    address: "345 Notre Dame St., Belle River, ON N0R 1A0",
    icon: "cart",
    lat: 42.29356893781629,
    lng: -82.69973664657495,
    link: "https://goo.gl/maps/FjyDRvbGFQwZ1dMf7",
  },
  {
    id: 463,
    name: "Your Independent Grocer",
    address: "277 King Street, Midland, ON L4R 3M4",
    icon: "cart",
    lat: 44.7501917042654,
    lng: -79.88490073114214,
    link: "https://goo.gl/maps/tFwaC32m9xBGbZzh7",
  },
  {
    id: 464,
    name: "Bloor Street Market",
    address: "55 Bloor St. West, Toronto, ON M4W 1A5",
    icon: "cart",
    lat: 43.6732919396991,
    lng: -79.38859300192154,
    link: "https://goo.gl/maps/pgzSqsSJAYC8Ci259",
  },
  {
    id: 465,
    name: "Valu-Mart",
    address: "1551 Hwy 55, Virgil, ON L0S 1T0",
    icon: "cart",
    lat: 43.22382124567213,
    lng: -79.12006818887004,
    link: "https://goo.gl/maps/F38Ufb5KfoC7b7ZK6",
  },
  {
    id: 466,
    name: "Valu-Mart",
    address: "24 Commercial Drive, Burk's Falls, ON P0A 1C0",
    icon: "cart",
    lat: 45.60976562166473,
    lng: -79.40314654460286,
    link: "https://goo.gl/maps/XyPLWxHcWS22atQb7",
  },
  {
    id: 467,
    name: "Valu-Mart",
    address: "120 Ontario Road, Mitchell, ON N0K 1N0",
    icon: "cart",
    lat: 43.466720433949895,
    lng: -81.19511027351922,
    link: "https://goo.gl/maps/Vm4U8DUCaD41xRjA8",
  },
  {
    id: 468,
    name: "Valu-Mart",
    address: "75 King Street, Waterloo, ON N2J 1P2",
    icon: "cart",
    lat: 43.46370523117435,
    lng: -80.52355846002571,
    link: "https://goo.gl/maps/UD7C8LtWDBrgD2bFA",
  },
  {
    id: 469,
    name: "Valu-Mart",
    address: "South Water St. & Hwy #35, PO Box 460, Minden, ON K0M 2K0",
    icon: "cart",
    lat: 44.931877415848795,
    lng: -78.71384920599638,
    link: "https://goo.gl/maps/UFF6nKJXro28eUJA6",
  },
  {
    id: 470,
    name: "Valu-Mart",
    address: "101 East Street South, Bobcaygeon, ON K0M 1A0",
    icon: "cart",
    lat: 44.53884010242622,
    lng: -78.5393057311501,
    link: "https://goo.gl/maps/m7cxP3UmJScDmMdP7",
  },
  {
    id: 471,
    name: "Valu-Mart",
    address: "Highway #60, Barry's Bay, ON K0J 1B0",
    icon: "cart",
    lat: 45.489220620952835,
    lng: -77.68281591762035,
    link: "https://goo.gl/maps/CagcUvVohXE6tTXj6",
  },
  {
    id: 472,
    name: "Valu-Mart",
    address: "1022 King Street West, Toronto, ON M6K 0C1",
    icon: "cart",
    lat: 43.63931944177104,
    lng: -79.42753081584182,
    link: "https://goo.gl/maps/urtAji8FP68dkfX88",
  },
  {
    id: 473,
    name: "Valu-Mart",
    address: "2399 Lakeshore Blvd., Etobicoke, ON M8V 1C5",
    icon: "cart",
    lat: 43.61484463427599,
    lng: -79.48841800234918,
    link: "https://goo.gl/maps/cUV6kJFBtqVjezcTA",
  },
  {
    id: 474,
    name: "Independent City Market",
    address: "111 Peter Street, Toronto, ON M5V 2H1",
    icon: "cart",
    lat: 43.65456914927891,
    lng: -79.39187203563792,
    link: "https://goo.gl/maps/4nVuYDGbcEz73Mrr9",
  },
  {
    id: 475,
    name: "Valu-Mart",
    address: "234 Oxford Street, London, ON N6A 1T7",
    icon: "cart",
    lat: 42.9960620616276,
    lng: -81.25276454470107,
    link: "https://goo.gl/maps/pXQ6v8BVboMRcSBf8",
  },
  {
    id: 476,
    name: "Valu-Mart",
    address: "125 John Street, North Aylmer, ON N5H 2A7",
    icon: "cart",
    lat: 42.775081361451775,
    lng: -80.98293218888651,
    link: "https://goo.gl/maps/FM8bcPeJJpVFRmTC9",
  },
  {
    id: 477,
    name: "Independent City Market",
    address: "3080 Yonge St., Toronto, ON M4N 3N1",
    icon: "cart",
    lat: 43.739313579912604,
    lng: -79.4059815849333,
    link: "https://goo.gl/maps/66UqkFXNHjzoC8RS8",
  },
  {
    id: 478,
    name: "Valu-Mart",
    address: "42 Matthew Street P.O. Box 460, Marmora, ON K0K 2M0",
    icon: "cart",
    lat: 44.48399312127505,
    lng: -77.678797646494,
    link: "https://goo.gl/maps/sajWiEQyKKoqQ82G9",
  },
  {
    id: 479,
    name: "Valu-Mart",
    address: "985 Woodbine Ave, Toronto, ON M4C 4B8",
    icon: "cart",
    lat: 43.686467454836624,
    lng: -79.31251081584007,
    link: "https://goo.gl/maps/6XaGfHtQ2vtmUyrr8",
  },
  {
    id: 480,
    name: "Valu-Mart",
    address: "70 Hope Street West, Tavistock, ON N0B 2R0",
    icon: "cart",
    lat: 43.3207507277052,
    lng: -80.83864946003101,
    link: "https://goo.gl/maps/fZjDo68SnHFWhLaw9",
  },
  {
    id: 481,
    name: "Valu-Mart",
    address: "30 Main Street, Dowling, ON P0M 1R0",
    icon: "cart",
    lat: 46.58658706328835,
    lng: -81.32875854456503,
    link: "https://goo.gl/maps/wC4PjaxjJCqW4kadA",
  },
  {
    id: 482,
    name: "Valu-Mart",
    address: "123 Guildwood Parkway, Scarborough, ON M1E 4V2",
    icon: "cart",
    lat: 43.74687324600687,
    lng: -79.2010310023443,
    link: "https://goo.gl/maps/qqqY9jZ5KQScYFW5A",
  },
  {
    id: 483,
    name: "Valu-Mart",
    address: "512 St. Phillippe Street, Alfred, ON K0B 1A0",
    icon: "cart",
    lat: 45.5571439791022,
    lng: -74.88666169857927,
    link: "https://goo.gl/maps/PWweiS32YgekhRVi6",
  },
  {
    id: 484,
    name: "Valu-Mart",
    address: "3259 Bayview Avenue, Toronto, ON M2K 1G4",
    icon: "cart",
    lat: 43.791005620403624,
    lng: -79.39228103672295,
    link: "https://goo.gl/maps/FGsC2LvfxRNHYzSTA",
  },
  {
    id: 485,
    name: "Valu-Mart",
    address: "1500 Bayview Avenue, Toronto, ON M4G 3B4",
    icon: "cart",
    lat: 43.703630808843705,
    lng: -79.37455881583944,
    link: "https://goo.gl/maps/ffxWJqqGjxo1CreYA",
  },
  {
    id: 486,
    name: "Valu-Mart",
    address: "235 Gore Road, Kingston, ON K7L 2L6",
    icon: "cart",
    lat: 44.25901402532568,
    lng: -76.45563308698327,
    link: "https://goo.gl/maps/vwVfcFMWvynnxKMf7",
  },
  {
    id: 487,
    name: "Valu-Mart",
    address: "Morrisburg Shopping Plaza, 5 Main Str. Box 737, Morrisburg, ON K0C 1X0",
    icon: "cart",
    lat: 44.90055116272212,
    lng: -75.18413078270694,
    link: "https://goo.gl/maps/cApmsLw58fhrquwU7",
  },
  {
    id: 488,
    name: "Valu-Mart",
    address: "2266 Queen St. East, Toronto, ON M4E 1G4",
    icon: "cart",
    lat: 43.673395139839066,
    lng: -79.28593983303074,
    link: "https://g.page/dyson-s-valu-mart?share",
  },
  {
    id: 489,
    name: "Your Independent Grocer",
    address: "1251 Main Street, Stittsville, ON K2S 2E5",
    icon: "cart",
    lat: 45.26812124193488,
    lng: -75.93008727345149,
    link: "https://goo.gl/maps/9EJ6wjCbDFXSP2Uz6",
  },
  {
    id: 490,
    name: "Your Independent Grocer",
    address: "31 Ninth Street East, Cornwall, ON K6H6R3",
    icon: "cart",
    lat: 45.03067137388212,
    lng: -74.734405544625,
    link: "https://goo.gl/maps/11edyWhsMoPnzoPe7",
  },
  {
    id: 491,
    name: "Your Independent Grocer",
    address: "80 Dufferin Street, Perth, ON K7H 3A7",
    icon: "cart",
    lat: 44.90811474691533,
    lng: -76.26523845997158,
    link: "https://goo.gl/maps/ouxJqxVecCsG96MF8",
  },
  {
    id: 492,
    name: "Your Independent Grocer",
    address: "1900 Dixie Road, Pickering, ON L1V 1V4",
    icon: "cart",
    lat: 43.84240721862848,
    lng: -79.10518427350526,
    link: "https://goo.gl/maps/WZxKCY6wfezfud4C9",
  },
  {
    id: 493,
    name: "Your Independent Grocer",
    address: "2681 Alta Vista Drive, Ottawa, ON K1V 7T5",
    icon: "cart",
    lat: 45.3733419372362,
    lng: -75.66340418878933,
    link: "https://goo.gl/maps/37K99ihbAM7YPZ5R9",
  },
  {
    id: 494,
    name: "Your Independent Grocer",
    address: "65 Regional Road #24, Lively, ON P3Y 1C3",
    icon: "cart",
    lat: 46.421375481391436,
    lng: -81.14177000224238,
    link: "https://goo.gl/maps/EYWkvDLaWcFFsWWPA",
  },
  {
    id: 495,
    name: "Your Independent Grocer",
    address: "1619 Orleans Avenue, Gloucester, ON K1C 7E2",
    icon: "cart",
    lat: 45.459861425219735,
    lng: -75.52416025625392,
    link: "https://goo.gl/maps/a1TtZpgX6pyEVE2E8",
  },
  {
    id: 496,
    name: "Your Independent Grocer",
    address: "832 Tenth Street, Hanover, ON N4N 1S3",
    icon: "cart",
    lat: 44.15578890112553,
    lng: -81.00714491767091,
    link: "https://goo.gl/maps/vifNZeEtKxg8Sb2s6",
  },
  {
    id: 497,
    name: "Your Independent Grocer",
    address: "15 McChesney Avenue, Kirkland Lake, ON P2N 3R9",
    icon: "cart",
    lat: 48.146157421265386,
    lng: -80.04234091751613,
    link: "https://goo.gl/maps/rKGaVydMuzJT3otDA",
  },
  {
    id: 498,
    name: "Your Independent Grocer",
    address: "Kemptville Mall Hwy. 43 W., Kemptville, ON K0G 1J0",
    icon: "cart",
    lat: 45.01905727020429,
    lng: -75.65639682928365,
    link: "https://goo.gl/maps/Xg7et3Bafm79v7qE9",
  },
  {
    id: 499,
    name: "Your Independent Grocer",
    address: "20 Jocelyn Road, Port Hope, ON L1A 3V5",
    icon: "cart",
    lat: 43.96092905868065,
    lng: -78.3201870354239,
    link: "https://goo.gl/maps/FGsqxe2sLY56pZTM9",
  },
  {
    id: 500,
    name: "Your Independent Grocer",
    address: "55 Brunetville Road, Kapuskasing, ON P5N 2E8",
    icon: "cart",
    lat: 49.42033245861975,
    lng: -82.41556027328726,
    link: "https://goo.gl/maps/WxGejMR1kAc41tqs9",
  },
  {
    id: 501,
    name: "Your Independent Grocer",
    address: "5121 Country Road #21, Haliburton, ON K0M 1S0",
    icon: "cart",
    lat: 45.04618954966839,
    lng: -78.53462710414365,
    link: "https://goo.gl/maps/aiK7yA92h5VXt3ow7",
  },
  {
    id: 502,
    name: "Your Independent Grocer",
    address: "270 Wellington Street, Bracebridge, ON P1L 1B9",
    icon: "cart",
    lat: 45.04231172783396,
    lng: -79.32429156181477,
    link: "https://goo.gl/maps/SGsQeky2mZmWEe288",
  },
  {
    id: 503,
    name: "Your Independent Grocer",
    address: "25 Ferrara Drive, Smiths Falls, ON K7A5K6",
    icon: "cart",
    lat: 44.8926785958049,
    lng: -76.0268759176431,
    link: "https://goo.gl/maps/Q85PyXAaGvfKvPUw6",
  },
  {
    id: 504,
    name: "Your Independent Grocer",
    address: "753 Notre Dame Street, Embrun, ON K0A 1W0",
    icon: "cart",
    lat: 45.2677670573362,
    lng: -75.3038127311224,
    link: "https://goo.gl/maps/xat1cmTMVKGteYZM6",
  },
  {
    id: 505,
    name: "Your Independent Grocer",
    address: "150 Prescott Centre Drive, Prescott, ON K0E 1T0",
    icon: "cart",
    lat: 44.72494107513008,
    lng: -75.52045779861098,
    link: "https://goo.gl/maps/Y1nTsSET5d8pP7xv7",
  },
  {
    id: 506,
    name: "Your Independent Grocer",
    address: "1893 Scugog Street, Port Perry, ON, L9L 1H9",
    icon: "cart",
    lat: 44.103052994530024,
    lng: -78.93733937534381,
    link: "https://goo.gl/maps/DQ8M3fYmpvcNM6Tc7",
  },
  {
    id: 507,
    name: "Your Independent Grocer",
    address: "B30 Beaver Avenue, Beaverton, ON L0K 1A0",
    icon: "cart",
    lat: 44.42831719959955,
    lng: -79.12511073115425,
    link: "https://goo.gl/maps/CxZeVoni4HFpVnJv8",
  },
  {
    id: 508,
    name: "Your Independent Grocer",
    address: "685 River Rd, Gloucester, ON K1V 2G2",
    icon: "cart",
    lat: 45.270390029646,
    lng: -75.69752605256457,
    link: "https://goo.gl/maps/9hpWRVjqGM9TkNXK8",
  },
  {
    id: 509,
    name: "Your Independent Grocer",
    address: "5911 Perth St., Richmond, ON K0A 2Z0",
    icon: "cart",
    lat: 45.20314253604005,
    lng: -75.8291246943407,
    link: "https://goo.gl/maps/CJv1Xsfk8rG8Ry1q9",
  },
  {
    id: 510,
    name: "Your Independent Grocer",
    address: "400 Lansdowne Street East, Peterborough, ON K9L 0B2",
    icon: "cart",
    lat: 44.293095668360536,
    lng: -78.29058478513366,
    link: "https://goo.gl/maps/NuMHF5FDk7aEhbP48",
  },
  {
    id: 511,
    name: "Your Independent Grocer",
    address: "290 First Street North, Gravenhurst, ON P1P 1H3",
    icon: "cart",
    lat: 44.921876092248496,
    lng: -79.37237481579368,
    link: "https://goo.gl/maps/SjLbdhyqEKALCrTd6",
  },
  {
    id: 512,
    name: "Your Independent Grocer",
    address: "401 Ottawa Street, Almonte, ON K0A 1A0",
    icon: "cart",
    lat: 45.234109085291124,
    lng: -76.18216427345276,
    link: "https://goo.gl/maps/wZRjCXt7AfiX1DKD7",
  },
  {
    id: 513,
    name: "Your Independent Grocer",
    address: "200 Grant Carman, Nepean, ON K2E 7Z8",
    icon: "cart",
    lat: 45.35060516400826,
    lng: -75.72937758564908,
    link: "https://goo.gl/maps/KXNAbhyR5PH3wDMD7",
  },
  {
    id: 514,
    name: "Your Independent Grocer",
    address: "62 Thames Road East, Exeter, ON N0M 1S3",
    icon: "cart",
    lat: 43.36358657901716,
    lng: -81.48122627352298,
    link: "https://goo.gl/maps/2n11CUQ4hbbL2XkC6",
  },
  {
    id: 515,
    name: "Your Independent Grocer",
    address: "1160 Beaverwood Road P.O. Box 517, Manotick, ON K4M 1A5",
    icon: "cart",
    lat: 45.22316024121427,
    lng: -75.68358845995954,
    link: "https://goo.gl/maps/mZGqDisjGv6RFJtX8",
  },
  {
    id: 516,
    name: "Your Independent Grocer",
    address: "2241 Riverside Drive, Ottawa, ON K1H 7X5",
    icon: "cart",
    lat: 45.38634575165647,
    lng: -75.67618627344693,
    link: "https://goo.gl/maps/fwVaTHZFpRAeE4iX7",
  },
  {
    id: 517,
    name: "Your Independent Grocer",
    address: "2 Hemlo Dr., Marathon, ON P0T 2T0",
    icon: "cart",
    lat: 48.72096311997618,
    lng: -86.37394869660628,
    link: "https://goo.gl/maps/CQwSV85ZLYKruSSd6",
  },
  {
    id: 518,
    name: "Your Independent Grocer",
    address: "745 Centre Street, Espanola, ON P5E 1S8",
    icon: "cart",
    lat: 46.25076903603193,
    lng: -81.76354854457814,
    link: "https://goo.gl/maps/d9Ptv64UJgNMK4Vs9",
  },
  {
    id: 519,
    name: "Your Independent Grocer",
    address: "30 Kenderdine Rd., Saskatoon, SK S7N 4MB",
    icon: "cart",
    lat: 52.1447868267797,
    lng: -106.57528927317378,
    link: "https://goo.gl/maps/s1GhbCijVfk2WvTw7",
  },
  {
    id: 520,
    name: "Your Independent Grocer",
    address: "1560 Cameron Street, Hawkesbury, ON K6A 3S5",
    icon: "cart",
    lat: 45.593479665552096,
    lng: -74.60171473110991,
    link: "https://goo.gl/maps/gqBE7r2hE2CLSmqV7",
  },
  {
    id: 521,
    name: "Your Independent Grocer",
    address: "1836 Regent Street South, Sudbury, ON P3E 3Z8",
    icon: "cart",
    lat: 46.453649370662234,
    lng: -81.00374639244417,
    link: "https://g.page/Rehansyig?share",
  },
  {
    id: 522,
    name: "Your Independent Grocer",
    address: "10 Sage Hill Plaza NW, Calgary, AB T3R 0HO",
    icon: "cart",
    lat: 51.16975642845438,
    lng: -114.15165967321485,
    link: "https://goo.gl/maps/Ut8nif9YzSmC28VZ9",
  },
  {
    id: 523,
    name: "Your Independent Grocer",
    address: "251 Powder Drive, Fort McMurray, AB T9K 2W6",
    icon: "cart",
    lat: 56.756697900122724,
    lng: -111.46078917297203,
    link: "https://goo.gl/maps/kzVPBMSHi67ZaGWD8",
  },
  {
    id: 524,
    name: "Your Independent Grocer",
    address: "4764-17 Regional Road 15, Chelmsford, ON P0M 1L0",
    icon: "cart",
    lat: 46.56985537008786,
    lng: -81.18446723107209,
    link: "https://goo.gl/maps/ginZeZkkHUu58tns6",
  },
  {
    id: 525,
    name: "Your Independent Grocer",
    address: "100 Halifax Ave W, Melville, SK S0A 2P0",
    icon: "cart",
    lat: 50.93879795609304,
    lng: -102.80120671555362,
    link: "https://goo.gl/maps/Mipynqj9fZ2ZAH2d9",
  },
  {
    id: 526,
    name: "Your Independent Grocer",
    address: "7190 Kerr Street, Vancouver, BC V5S 4W2",
    icon: "cart",
    lat: 49.21899427452582,
    lng: -123.04027010397931,
    link: "https://goo.gl/maps/qewpzvPwyumfn4Fe8",
  },
  {
    id: 527,
    name: "Your Independent Grocer",
    address: "1501 Cook St., Creston, BC V0B 1G0",
    icon: "cart",
    lat: 49.09441576709763,
    lng: -116.5094676463134,
    link: "https://goo.gl/maps/R4YZcXMAvfnc9eb18",
  },
  {
    id: 528,
    name: "Your Independent Grocer",
    address: "1900 Garibaldi Way, Squamish, BC V0N 1T0",
    icon: "cart",
    lat: 49.74018671721216,
    lng: -123.13365424018659,
    link: "https://goo.gl/maps/g9ppL9gFnhvDJWdX8",
  },
  {
    id: 529,
    name: "Your Independent Grocer",
    address: "10852 - 100th Street, Westlock, AB T7P 2R6",
    icon: "cart",
    lat: 54.153152905304246,
    lng: -113.8358736461002,
    link: "https://goo.gl/maps/bSeFiTD7yAtFr1CW6",
  },
  {
    id: 530,
    name: "Your Independent Grocer",
    address: "1255 Davie St., Vancouver, BC V6E 1N4",
    icon: "cart",
    lat: 49.28264194014786,
    lng: -123.13400881562193,
    link: "https://goo.gl/maps/qsrprpjk5rYjM5pN9",
  },
  {
    id: 531,
    name: "Your Independent Grocer",
    address: "1521 Highway 11 West, Hearst, ON P0L 1N0",
    icon: "cart",
    lat: 49.691490135869024,
    lng: -83.68263691745351,
    link: "https://goo.gl/maps/hWKuQv3oxmkR5HNE6",
  },
  {
    id: 532,
    name: "Your Independent Grocer",
    address: "1906-8th Ave., Fort Mcleod, AB T0L 0Z0",
    icon: "cart",
    lat: 49.72224331894648,
    lng: -113.38980235793308,
    link: "https://goo.gl/maps/V4kdC8u7eUhDV1Du9",
  },
  {
    id: 533,
    name: "Your Independent Grocer",
    address: "985 St Albert Trail, St. Albert, AB T8N 4K6",
    icon: "cart",
    lat: 53.66970046012177,
    lng: -113.63772591728566,
    link: "https://goo.gl/maps/v7yCncfMb5RqzDNL6",
  },
  {
    id: 534,
    name: "Your Independent Grocer",
    address: "#5-303 Ogilvie St., Whitehorse, YT Y1A 2S3",
    icon: "cart",
    lat: 60.7252296348366,
    lng: -135.05974364580248,
    link: "https://goo.gl/maps/weKezwVRkhquicVy8",
  },
  {
    id: 535,
    name: "Your Independent Grocer",
    address: "5212 - 50th St., Drayton Valley, AB T7A 1S6",
    icon: "cart",
    lat: 53.22522009477272,
    lng: -114.97715967793519,
    link: "https://goo.gl/maps/11Dpv4te95Jq3pU76",
  },
  {
    id: 536,
    name: "Your Independent Grocer",
    address: "49-700 Tranquille Road, Kamloops, BC V2B 3H9",
    icon: "cart",
    lat: 50.6970486518624,
    lng: -120.35922029042479,
    link: "https://goo.gl/maps/Uz3uBVCKxdBDMRki7",
  },
  {
    id: 537,
    name: "Your Independent Grocer",
    address: "1835 Gordon Drive, Kelowna, BC V1Y 3H4",
    icon: "cart",
    lat: 49.88070062971181,
    lng: -119.47574958861031,
    link: "https://goo.gl/maps/ApCbyw7ZxsXyywwdA",
  },
  {
    id: 538,
    name: "Your Independent Grocer",
    address: "3185 Arbutus St., Vancouver, BC V6J 3Z3",
    icon: "cart",
    lat: 49.25762503410686,
    lng: -123.15340444261008,
    link: "https://goo.gl/maps/cF4Yp6HrkJpNDwzk8",
  },
  {
    id: 539,
    name: "Your Independent Grocer",
    address: "845 Park Royal North, West Vancouver, BC V7T-1H9",
    icon: "cart",
    lat: 49.327820975986526,
    lng: -123.1389384002782,
    link: "https://goo.gl/maps/T9vmBbAkVrvK9yBU6",
  },
  {
    id: 540,
    name: "Your Independent Grocer",
    address: "315 Herold Road, Saskatoon, SK S7V 1J7",
    icon: "cart",
    lat: 52.100350380731,
    lng: -106.56726291735302,
    link: "https://goo.gl/maps/AHyWiWU91LyxiLxv9",
  },
  {
    id: 541,
    name: "Your Independent Grocer",
    address: "5530 Sunshine Coast Highway, Sechelt, BC V0N 3A0",
    icon: "cart",
    lat: 49.47414135265368,
    lng: -123.75461964629795,
    link: "https://goo.gl/maps/BismmL5uthSUX4d36",
  },
  {
    id: 542,
    name: "Your Independent Grocer",
    address: "620A Saskachewan Ave East, Melfort, SK S0E 1A0",
    icon: "cart",
    lat: 52.85783183799608,
    lng: -104.59741091732069,
    link: "https://goo.gl/maps/BHEY1RUkiqqSWwzW6",
  },
  {
    id: 543,
    name: "Your Independent Grocer",
    address: "18765 Fraser Highway, Surrey, BC V3S 7Y3",
    icon: "cart",
    lat: 49.12553208428845,
    lng: -122.70274842671812,
    link: "https://goo.gl/maps/orz9txasibuuCb9z9",
  },
  {
    id: 544,
    name: "Your Independent Grocer",
    address: "2815 Wanuskewin Rd., Saskatoon, SK S7K 8E6",
    icon: "cart",
    lat: 52.17513788700174,
    lng: -106.64087218851435,
    link: "https://goo.gl/maps/YSEyfCH1sXqym1Gs8",
  },
  {
    id: 545,
    name: "Your Independent Grocer",
    address: "1341 Broadway Ave., Regina, SK S4P 1E5",
    icon: "cart",
    lat: 50.43717481883364,
    lng: -104.59987127324543,
    link: "https://goo.gl/maps/mUayomkQeTDy3wft9",
  },
  {
    id: 546,
    name: "Your Independent Grocer",
    address: "215 Port Augusta St., Comox, BC V9M 3M9",
    icon: "cart",
    lat: 49.67388684017269,
    lng: -124.92412291745414,
    link: "https://goo.gl/maps/LJp2WsdT7nRnRck39",
  },
  {
    id: 547,
    name: "Your Independent Grocer",
    address: "3 Clearview Market Way, Red Deer, AB T4P 0M9",
    icon: "cart",
    lat: 52.28536174812224,
    lng: -113.76895437501608,
    link: "https://goo.gl/maps/wh8rrarvnsj7BygJ8",
  },
  {
    id: 548,
    name: "Loblaws City Market",
    address: "1650 Lonsdale Avenue, North Vancouver, BC V7M 2J3",
    icon: "cart",
    lat: 49.33016445409962,
    lng: -123.07080909088468,
    link: "https://goo.gl/maps/WmZXZ4ZyoenGx61YA",
  },
  {
    id: 549,
    name: "Your Independent Grocer",
    address: "455 North Rd, Coquitlam, BC V3K 3V9",
    icon: "cart",
    lat: 49.24856666494716,
    lng: -122.8915702886361,
    link: "https://goo.gl/maps/L5SD3NxYRHipy9pH8",
  },
  {
    id: 550,
    name: "Your Independent Grocer",
    address: "14-4910 50th Avenue, Yellowknife, NWT X1A 3S5",
    icon: "cart",
    lat: 62.45490014662766,
    lng: -114.372171970882,
    link: "https://goo.gl/maps/J3f83psB2nxLRwkL9",
  },
  {
    id: 551,
    name: "Your Independent Grocer",
    address: "8200 Bear Paw Trail, Whistler, BC V0N 1B9",
    icon: "cart",
    lat: 50.15648920524488,
    lng: -122.95505837510524,
    link: "https://goo.gl/maps/pRfpXM8Bh9q9Y9KB7",
  },
  {
    id: 552,
    name: "Your Independent Grocer",
    address: "5007 - 52nd Street, Athabasca, AB T9S 1V2",
    icon: "cart",
    lat: 54.72216126405034,
    lng: -113.28994583258167,
    link: "https://goo.gl/maps/FwaW8RRa3ZJmPueCA",
  },
  {
    id: 553,
    name: "Your Independent Grocer",
    address: "10527-101 St., Avenue, Lac La Biche, AB T0A 2C0",
    icon: "cart",
    lat: 54.764395727957194,
    lng: -111.98935615531491,
    link: "https://goo.gl/maps/SiHZgFL9UKmwTCXw9",
  },
  {
    id: 554,
    name: "Your Independent Grocer",
    address: "336 McCarthy Blvd. N, Regina, SK S4R 7M2",
    icon: "cart",
    lat: 50.48210130788472,
    lng: -104.66642391557258,
    link: "https://goo.gl/maps/2XpBp37w6JkEZ9Uf6",
  },
  {
    id: 555,
    name: "Your Independent Grocer",
    address: "400 Dundas Street E, Belleville, ON K8N 1E8",
    icon: "cart",
    lat: 44.16709037720078,
    lng: -77.35804183301236,
    link: "https://goo.gl/maps/YmxNXdsh2p6tdsFK7",
  },
  {
    id: 556,
    name: "Your Independent Grocer",
    address: "1244 Goderich St., Port Elgin, ON N0H 2C0",
    icon: "cart",
    lat: 44.453644464772964,
    lng: -81.37599044279858,
    link: "https://goo.gl/maps/JQjh6RBG4rkLL4zK9",
  },
  {
    id: 557,
    name: "Your Independent Grocer",
    address: "227 Main Street, Delhi, ON N4B 2N4",
    icon: "cart",
    lat: 42.85343533634147,
    lng: -80.49862103685194,
    link: "https://goo.gl/maps/bRVrCu9nvKn2zQBF6",
  },
  {
    id: 558,
    name: "Your Independent Grocer",
    address: "293 Dundas St E, Trenton, ON K8V 1M1",
    icon: "cart",
    lat: 44.10425181889199,
    lng: -77.55424895205321,
    link: "https://goo.gl/maps/n4tvH61nxxH6SVnf7",
  },
  {
    id: 559,
    name: "Your Independent Grocer",
    address: "780 Queen Street East PO Box 220, St Mary's, ON N4X 1B1",
    icon: "cart",
    lat: 43.262259239327086,
    lng: -81.11812573119772,
    link: "https://goo.gl/maps/w1246z93ZNU9sZ6p7",
  },
  {
    id: 560,
    name: "Your Independent Grocer",
    address: "654 Algonquin Blvd East, Timmins, ON P4N 8R4",
    icon: "cart",
    lat: 48.47804756057391,
    lng: -81.3155723579836,
    link: "https://goo.gl/maps/r4123DbYcfqpPh1J9",
  },
  {
    id: 561,
    name: "Your Independent Grocer",
    address: "1 Laurentian Avenue, North Bay, ON P1B 9P2",
    icon: "cart",
    lat: 46.32055312294914,
    lng: -79.43987881573995,
    link: "https://goo.gl/maps/FXhpHtCyTxup2Vhr8",
  },
  {
    id: 562,
    name: "Your Independent Grocer",
    address: "420 Main Street South, Alexandria, ON K0C 1A0",
    icon: "cart",
    lat: 45.29956709932284,
    lng: -74.6296661887921,
    link: "https://goo.gl/maps/nS7QbCi5Qn943Lu58",
  },
  {
    id: 563,
    name: "Your Independent Grocer",
    address: "300 Eagleson Road, Kanata, ON K2M 1C9",
    icon: "cart",
    lat: 45.30229963691861,
    lng: -75.87790181577925,
    link: "https://goo.gl/maps/BTQ29CFBCzLno2uaA",
  },
  {
    id: 564,
    name: "Your Independent Grocer",
    address: "4136 Petrolia Street, Petrolia, ON N0N 1R0",
    icon: "cart",
    lat: 42.88223882748934,
    lng: -82.15045473121165,
    link: "https://goo.gl/maps/GZdFWZkdWmKxEtm49",
  },
  {
    id: 565,
    name: "Your Independent Grocer",
    address: "596 Montreal Road, Ottawa, ON K1K 0T9",
    icon: "cart",
    lat: 45.44139336855269,
    lng: -75.6438493752931,
    link: "https://goo.gl/maps/tjyo5PDeUVAuZGb27",
  },
  {
    id: 566,
    name: "Your Independent Grocer",
    address: "82 Lorne Street, Sudbury, ON P3C 4N8",
    icon: "cart",
    lat: 46.49065014226829,
    lng: -81.00129647155593,
    link: "https://goo.gl/maps/36WGBTTFRdd1U8QV7",
  },
  {
    id: 567,
    name: "Your Independent Grocer",
    address: "273 King Street West, Ingersoll, ON N5C 2K9",
    icon: "cart",
    lat: 43.032123214228086,
    lng: -80.89349895209297,
    link: "https://goo.gl/maps/DRYNBG1VjTjgRhZU7",
  },
  {
    id: 568,
    name: "Your Independent Grocer",
    address: "44 Great Northern Road, Sault Ste. Marie, ON P6B 4Y5",
    icon: "cart",
    lat: 46.524199382394066,
    lng: -84.31715081573199,
    link: "https://goo.gl/maps/y11xYQTX52n9HnMA9",
  },
  {
    id: 569,
    name: "Your Independent Grocer",
    address: "455 McNeely Avenue, Carleton Place, ON K7C 4S6",
    icon: "cart",
    lat: 45.134146508957016,
    lng: -76.1213848157856,
    link: "https://goo.gl/maps/2ZPq8ufqC2SeqBNn8",
  },  
  {
    id: 570,
    name: "Your Independent Grocer",
    address: "55 Scott Street, New Liskeard, ON P0J 1P0",
    icon: "cart",
    lat: 47.51022213926637,
    lng: -79.68409010404791,
    link: "https://goo.gl/maps/ivue9WrJWHCKggCz8",
  },
  {
    id: 571,
    name: "Your Independent Grocer",
    address: "296 Bank St., Ottawa, ON K2P 1X8",
    icon: "cart",
    lat: 45.41543047886479,
    lng: -75.69625308693941,
    link: "https://goo.gl/maps/1NJPbNqs7CF7bqjUA",
  },
  {
    id: 572,
    name: "Your Independent Grocer",
    address: "3025 Petawawa Blvd., Petawawa, ON K8H 1X9",
    icon: "cart",
    lat: 45.88934652793931,
    lng: -77.26002998507295,
    link: "https://goo.gl/maps/hdKCSTuDR3zviJAS8",
  },
  {
    id: 573,
    name: "Your Independent Grocer",
    address: "2737 Laurier Street, Rockland, ON K4K 1A3",
    icon: "cart",
    lat: 45.542451636117306,
    lng: -75.30020273111187,
    link: "https://goo.gl/maps/UUqQzndSK1KZwk689",
  },
  {
    id: 574,
    name: "Your Independent Grocer",
    address: "131 Howland Drive, Huntsville, ON P1H 2P7",
    icon: "cart",
    lat: 45.345021657258684,
    lng: -79.22710018879036,
    link: "https://goo.gl/maps/qWrKgsCtnQsDaLhaA",
  },
  {
    id: 575,
    name: "Your Independent Grocer",
    address: "4431 4th Avenue, Edson, AB T7E 1Z1",
    icon: "cart",
    lat: 53.5868914146984,
    lng: -116.41903701913758,
    link: "https://goo.gl/maps/8o2BSdZpcHFVxP2HA",
  },
  {
    id: 576,
    name: "Your Independent Grocer",
    address: "Hwy #26 East, Meaford, ON N4L 1W7",
    icon: "cart",
    lat: 44.59545411616069,
    lng: -80.58003539251548,
    link: "https://goo.gl/maps/iPh56AojxMdbps4D6",
  },
  {
    id: 577,
    name: "Loblaws City Market",
    address: "Unit 101 12060-104 Ave, Edmonton, AB T5K 0G6",
    icon: "cart",
    lat: 53.54688897300055,
    lng: -113.52966962893632,
    link: "https://goo.gl/maps/rGkpCTPFtXcY8rUr7",
  },
  {
    id: 578,
    name: "Your Independent Grocer",
    address: "250 Old Airport Road, Yellowknife, NWT X1A 3G3",
    icon: "cart",
    lat: 62.44655009271066,
    lng: -114.40798927270824,
    link: "https://goo.gl/maps/jTeyuDvdX9z7xXSM6",
  },
  {
    id: 579,
    name: "Your Independent Grocer",
    address: "5200 Greater Sudbury Regional Road 80, Hanmer, ON P3P 1Z3",
    icon: "cart",
    lat: 46.529353791999476,
    lng: -80.98096240352571,
    link: "https://goo.gl/maps/5Qa3QbCi7DP3VQnE6",
  },
  {
    id: 580,
    name: "Your Independent Grocer",
    address: "3777 Strandherd Drive, Nepean, ON  K2J 4B1",
    icon: "cart",
    lat: 45.268118199754625,
    lng: -75.74810618879337,
    link: "https://goo.gl/maps/Du9fzbTdx25omoZD6",
  },
  {
    id: 581,
    name: "Maxi",
    address: "5150, boul.De L'Ormière, Québec, QC G1P 4B2",
    icon: "cart",
    lat: 46.82000681258323,
    lng: -71.33079708688489,
    link: "https://goo.gl/maps/27q2PxE23tMGGHn98",
  },
  {
    id: 582,
    name: "Maxi",
    address: "3500, Boul. St-Martin Ouest, Laval, QC H7T 2W4",
    icon: "cart",
    lat: 45.5538987978775,
    lng: -73.75013508693414,
    link: "https://goo.gl/maps/EjkKUt9FCP4JrfzM8",
  },
  {
    id: 583,
    name: "Maxi",
    address: "7605, Maurice Duplessis, Montréal, QC H1E 7N2",
    icon: "cart",
    lat: 45.628004544357104,
    lng: -73.59779654460219,
    link: "https://goo.gl/maps/ZmNoaFuEw5wxhwY97",
  },
  {
    id: 584,
    name: "Maxi",
    address: "800, Boul. Maloney Ouest, Gatineau, QC J8T 3R6",
    icon: "cart",
    lat: 45.48026604380597,
    lng: -75.68268545994974,
    link: "https://goo.gl/maps/sqYegZnmuTEJdtcd9",
  },
  {
    id: 585,
    name: "Maxi",
    address: "130, Chemin Lepine, Gatineau, QC J8L 4M4",
    icon: "cart",
    lat: 45.57429011185383,
    lng: -75.40770995070466,
    link: "https://goo.gl/maps/uw2SzuJtE52EtznQ8",
  },
  {
    id: 586,
    name: "Maxi",
    address: "153, boul. Armand-Frappier, Ste-Julie, QC J3E 0G2",
    icon: "cart",
    lat: 45.57910269484207,
    lng: -73.34992473111043,
    link: "https://goo.gl/maps/BuLZ7o53f1mtLABE9",
  },
  {
    id: 587,
    name: "Extra Foods Franchise",
    address: "7000 27th Street, Grand Forks, BC V0H 1H0",
    icon: "cart",
    lat: 49.022566797444625,
    lng: -118.46932681563247,
    link: "https://goo.gl/maps/c3gcHVBb1wbdXcc37",
  },
  {
    id: 588,
    name: "Extra Foods Franchise",
    address: "2335 Maple Drive, Quesnel, BC V2J 6J6",
    icon: "cart",
    lat: 52.96474737962086,
    lng: -122.44224337498707,
    link: "https://goo.gl/maps/AbGi6dDP4tJRMdQ48",
  },
  {
    id: 589,
    name: "Extra Foods Franchise",
    address: "2760 Forksdale Ave, Merritt, BC V1K 1R9",
    icon: "cart",
    lat: 50.12163046794707,
    lng: -120.76183983092945,
    link: "https://goo.gl/maps/eA1vfnBbTW9WFLmd7",
  },
  {
    id: 590,
    name: "Maxi",
    address: "801, route principale, route 117, Ste-Agathe-des-Monts, QC J8C 1L1",
    icon: "cart",
    lat: 46.04299012304734,
    lng: -74.27090079856059,
    link: "https://goo.gl/maps/Kk3ZsLasE59Q338u7",
  },
  {
    id: 591,
    name: "Maxi",
    address: "14 875, boul. Pierrefonds, Pierrefonds, QC H9H 4M5",
    icon: "cart",
    lat: 45.48492245714773,
    lng: -73.86089352741753,
    link: "https://goo.gl/maps/uD152U3JBJoxFu7N8",
  },
  {
    id: 592,
    name: "Maxi",
    address: "940 Chemin du Sault, St-Romuald, QC G6W 0P9",
    icon: "cart",
    lat: 46.73051574311489,
    lng: -71.27171985268987,
    link: "https://goo.gl/maps/4q9Qhit9jqyHaFiD7",
  },
  {
    id: 593,
    name: "Maxi",
    address: "33, boul. St-Benoit, Amqui, QC G5J 2B8",
    icon: "cart",
    lat: 48.46414231839955,
    lng: -67.42497416962948,
    link: "https://goo.gl/maps/43XHix2MshNBSqSC9",
  },
  {
    id: 594,
    name: "Maxi",
    address: "2840, boul. Des Promenades, SteMarthe Sur Le Lac, QC J0N 1P0",
    icon: "cart",
    lat: 45.53896036255691,
    lng: -73.92442681577008,
    link: "https://goo.gl/maps/TWTDddo4GB3kFbPA6",
  },
  {
    id: 595,
    name: "Maxi",
    address: "2100, rue Bagot, La Baie, QC G7B 3Z3",
    icon: "cart",
    lat: 48.336625091771374,
    lng: -70.89631662915383,
    link: "https://goo.gl/maps/oCFT1N8odMUYEGz59",
  },
  {
    id: 596,
    name: "Maxi",
    address: "472, 4 ieme Rue Est, Amos, QC J9T 1Y4",
    icon: "cart",
    lat: 48.56962386147063,
    lng: -78.10641418866368,
    link: "https://goo.gl/maps/BV6wxHwRpEcRHh266",
  },
  {
    id: 597,
    name: "Maxi",
    address: "390, Montee des Pionniers, Lachenaie, QC J6V 1S6",
    icon: "cart",
    lat: 45.71289828281659,
    lng: -73.5098435445989,
    link: "https://goo.gl/maps/3QqzdhweDTMn42q76",
  },
  {
    id: 598,
    name: "Maxi",
    address: "170, Principale sud, Maniwaki, QC J9E 1Z6",
    icon: "cart",
    lat: 46.375667423733496,
    lng: -75.98383466176338,
    link: "https://goo.gl/maps/QYaC2pmkAd9FqZ7o8",
  },
  {
    id: 599,
    name: "Maxi",
    address: "150, avenue Saint-Alphonse, Roberval, QC G8H 3P8",
    icon: "cart",
    lat: 48.51876453577618,
    lng: -72.22253608681741,
    link: "https://goo.gl/maps/F6NViceqzXTwmvnk7",
  },
  {
    id: 600,
    name: "Maxi",
    address: "500, Boul. Des Laurentides, St-Antoine Laurentides, QC J7Z 4M2",
    icon: "cart",
    lat: 45.762465038936256,
    lng: -73.99441408692599,
    link: "https://goo.gl/maps/ZJAWq799fEWM4C6z7",
  },
  {
    id: 601,
    name: "Maxi",
    address: "44, rue Craig, Richmond, QC J0B 2H0",
    icon: "cart",
    lat: 45.65767946881719,
    lng: -72.14142279986815,
    link: "https://goo.gl/maps/k79TBFJctSnhaQKZ9",
  },
  {
    id: 602,
    name: "Maxi",
    address: "3500, rue Laval, Lac Mégantic, QC G6B 1A4",
    icon: "cart",
    lat: 45.59103090813621,
    lng: -70.905371511516,
    link: "https://goo.gl/maps/DMSWFfcoRLS7b6qt5",
  },
  {
    id: 603,
    name: "Maxi",
    address: "2535, rue Masson, Montréal, QC H1Y 1V7",
    icon: "cart",
    lat: 45.54438497007371,
    lng: -73.57749954460543,
    link: "https://goo.gl/maps/hCAGXUssEeUgA1FA6",
  },
  {
    id: 604,
    name: "Maxi",
    address: "8475, Rue Chartrand, St-Francois, Laval, QC H7A 1M5",
    icon: "cart",
    lat: 45.66839631334405,
    lng: -73.5764325948784,
    link: "https://goo.gl/maps/UQNszcYqSVFXGTyb6",
  },
  {
    id: 605,
    name: "Maxi",
    address: "355, rue Principale, Lachute, QC J8H 2Z7",
    icon: "cart",
    lat: 45.65629496573341,
    lng: -74.34506944275275,
    link: "https://goo.gl/maps/iFH7FKb8pZZr6Xqb7",
  },
  {
    id: 606,
    name: "Maxi",
    address: "50, Boul. Taschereau, La Prairie, QC J5R 4V3",
    icon: "cart",
    lat: 45.42235717634456,
    lng: -73.48659990043272,
    link: "https://goo.gl/maps/6PiMYJpu5udztpQ49",
  },
  {
    id: 607,
    name: "Maxi",
    address: "124, route 132 ouest, New Richmond, QC G0C 2B0",
    icon: "cart",
    lat: 48.17437484688436,
    lng: -65.84508527333766,
    link: "https://goo.gl/maps/tnG35AAH8RGq33xv5",
  },
  {
    id: 608,
    name: "Maxi",
    address: "114, Boul. St-Jean Baptiste, Châteauguay, QC J6K 3A9",
    icon: "cart",
    lat: 45.357819374220156,
    lng: -73.71505000228345,
    link: "https://goo.gl/maps/d3jEWZ42Toh4yRFt6",
  },
  {
    id: 609,
    name: "Maxi",
    address: "1877, rue Bilodeau, Plessiville, QC G6L 5N2",
    icon: "cart",
    lat: 46.21911756208841,
    lng: -71.76338491759222,
    link: "https://goo.gl/maps/pcEx1A5LipB8XB5w8",
  },
  {
    id: 610,
    name: "Maxi",
    address: "7201, boul. Laurier, Laplaine, QC J7M 1V9",
    icon: "cart",
    lat: 45.767350209830916,
    lng: -73.7278698673319,
    link: "https://goo.gl/maps/HgXMNECWFTtLgiDG7",
  },
  {
    id: 611,
    name: "Maxi",
    address: "350, rue Bouvier, Québec, QC G2J 1R8",
    icon: "cart",
    lat: 46.841238770942965,
    lng: -71.27012601756789,
    link: "https://goo.gl/maps/neWn8ZJQyxeubzQC7",
  },
  {
    id: 612,
    name: "Maxi",
    address: "1500, Chemin Sullivan, Local 24, Val D'Or, QC J9P 1M1",
    icon: "cart",
    lat: 48.10603074217436,
    lng: -77.80553828868227,
    link: "https://goo.gl/maps/5QGkVffiUoYV1um96",
  },
  {
    id: 613,
    name: "Maxi",
    address: "1055, Avenue du Pont-Sud, Alma, QC G8B 2V7",
    icon: "cart",
    lat: 48.53427869225338,
    lng: -71.64645300215864,
    link: "https://goo.gl/maps/kVnwGYH7Mbg5k5Nh6",
  },
  {
    id: 614,
    name: "Maxi",
    address: "595, du Phare Est, Matane, QC G4W 1A9",
    icon: "cart",
    lat: 48.85184653087232,
    lng: -67.5075481886523,
    link: "https://goo.gl/maps/oRfVq3WSj5gN3aiM8",
  },
  {
    id: 615,
    name: "Maxi",
    address: "1005, boul. Laure, Sept-îles, QC G4R 1Z1",
    icon: "cart",
    lat: 50.22647116687398,
    lng: -66.39406163092507,
    link: "https://goo.gl/maps/5SPdXShG5JtQndPR7",
  },
  {
    id: 616,
    name: "Maxi",
    address: "681, Ste Geneviève, Chicoutimi-Nord, QC G7G 4Z4",
    icon: "cart",
    lat: 48.444122020934316,
    lng: -71.08032945983322,
    link: "https://goo.gl/maps/mBHJ8kZmNQjwASov8",
  },
  {
    id: 617,
    name: "Maxi",
    address: "370, rue St-Jacques, Granby, QC J2G 3N4",
    icon: "cart",
    lat: 45.39925364698826,
    lng: -72.73519490043364,
    link: "https://goo.gl/maps/HiGMYAGKJ84mUyMf9",
  },
  {
    id: 618,
    name: "Maxi",
    address: "6825, Côte-Des-Neiges, Montréal, QC H3S 2B6",
    icon: "cart",
    lat: 45.505169479940456,
    lng: -73.63763981577145,
    link: "https://goo.gl/maps/TnQ2Eiy869pv7hoH6",
  },
  {
    id: 619,
    name: "Maxi",
    address: "3000, rue Wellington, Verdun, QC H4G 1T1",
    icon: "cart",
    lat: 45.47126602624147,
    lng: -73.56316808693722,
    link: "https://goo.gl/maps/ZvkW6qJ9JPDPdqFLA",
  },
  {
    id: 620,
    name: "Maxi",
    address: "120, boul. Arthabaska Ouest, Victoriaville, QC G6S 0P2",
    icon: "cart",
    lat: 46.067747297977625,
    lng: -71.9303798157497,
    link: "https://goo.gl/maps/TZ9Yzs2739bw1JB49",
  },
  {
    id: 621,
    name: "Maxi",
    address: "30, rue Racine, Baie St-Paul, QC G3Z 2R1",
    icon: "cart",
    lat: 47.44241169202442,
    lng: -70.51034845987326,
    link: "https://goo.gl/maps/dZ8vCkxijegeCNSN6",
  },
  {
    id: 622,
    name: "Maxi",
    address: "2650, avenue de Grand-Mère, Shawinigan, QC G9T 2L4",
    icon: "cart",
    lat: 46.600817267892424,
    lng: -72.71042637339997,
    link: "https://goo.gl/maps/Dxbz7VXhZroFmXGR8",
  },
  {
    id: 623,
    name: "Maxi",
    address: "25, Rue De La Savane, Gatineau, QC J8T 8A4",
    icon: "cart",
    lat: 45.47499067795114,
    lng: -75.70398125810163,
    link: "https://goo.gl/maps/dv1fvHQhSjwppbuc9",
  },
  {
    id: 624,
    name: "Maxi",
    address: "199, 25 ième Avenue, St-Eustache, QC J7P 2V1",
    icon: "cart",
    lat: 45.57527621651569,
    lng: -73.87408445994608,
    link: "https://goo.gl/maps/PDexEuPgiFXFzLKE7",
  },
  {
    id: 625,
    name: "Maxi",
    address: "6750, Jean-Talon Est, St-Léonard, QC H1S 1N1",
    icon: "cart",
    lat: 45.59395866395012,
    lng: -73.56987454460352,
    link: "https://goo.gl/maps/pncDHLU66wnJnaLX6",
  },
  {
    id: 626,
    name: "Maxi",
    address: "8305 Papineau, Montréal, QC H2M 2G2",
    icon: "cart",
    lat: 45.55590617875246,
    lng: -73.62131600227588,
    link: "https://goo.gl/maps/xHbFQ4fTdESaSLNt5",
  },
  {
    id: 627,
    name: "Maxi",
    address: "552, boul. Wilfrid-Hamel, Québec, QC G1M 3E5",
    icon: "cart",
    lat: 46.82298034872728,
    lng: -71.25412613962813,
    link: "https://goo.gl/maps/K4gw5cwqkA9oe48JA",
  },
  {
    id: 628,
    name: "Maxi",
    address: "175, Sir Wilfrid Laurier, Beloeil, QC J3G 4G8",
    icon: "cart",
    lat: 45.55241565317006,
    lng: -73.21454322316279,
    link: "https://goo.gl/maps/w7nkCyqyz8rGbHqbA",
  },
  {
    id: 629,
    name: "Maxi",
    address: "200, Omer-Marcil, St-Jean-Richelieu, QC J2W 2V1",
    icon: "cart",
    lat: 45.33216157145863,
    lng: -73.29329329063921,
    link: "https://goo.gl/maps/SrZonvkky7HKFnpG7",
  },
  {
    id: 630,
    name: "Maxi",
    address: "86 boul. Brien, Repentigny, QC J6A 5K7",
    icon: "cart",
    lat: 45.74219419247392,
    lng: -73.4549632734333,
    link: "https://goo.gl/maps/7b66Zg12XprPoWQB9",
  },
  {
    id: 631,
    name: "Maxi",
    address: "909, Boul Firestone, Joliette, QC J6E 2W4",
    icon: "cart",
    lat: 46.03259677961957,
    lng: -73.43056254458662,
    link: "https://goo.gl/maps/wgqpL8k3ttK89XQ59",
  },
  {
    id: 632,
    name: "Maxi",
    address: "419. boul. Jessop, Rimouski, QC G5L 7Y5",
    icon: "cart",
    lat: 48.467670276235744,
    lng: -68.51269717332592,
    link: "https://goo.gl/maps/pyxhjz1dJsCfSe8B6",
  },
  {
    id: 633,
    name: "Maxi",
    address: "215, boul. Hôtel De Ville, Rivière-du-Loup, QC G5R 4Y2",
    icon: "cart",
    lat: 47.82678994605378,
    lng: -69.54662105375758,
    link: "https://goo.gl/maps/dbuJnpJCtZ14K3799",
  },
  {
    id: 634,
    name: "Maxi",
    address: "150, des Grandes Fourches, Sherbrooke, QC J1H 5G5",
    icon: "cart",
    lat: 45.400037577881825,
    lng: -71.88649145995278,
    link: "https://goo.gl/maps/tDZ7JR92ymicSvgp9",
  },
  {
    id: 635,
    name: "Maxi",
    address: "1601, boul. Périgny, Chambly, QC J3L 1W9",
    icon: "cart",
    lat: 45.44659615615755,
    lng: -73.28465500228012,
    link: "https://goo.gl/maps/XnA7RoZm6x2BQvye8",
  },
  {
    id: 636,
    name: "Maxi",
    address: "1065, boul. Pie XI, Val Bélair, QC G3K 2S5",
    icon: "cart",
    lat: 46.85453408797898,
    lng: -71.41060791756738,
    link: "https://goo.gl/maps/YB2eomdoz88atmJG6",
  },
  {
    id: 637,
    name: "Maxi",
    address: "1201, boul. Duplessis, L'Ancienne-Lorette, QC G2G 2B4",
    icon: "cart",
    lat: 46.78675359581433,
    lng: -71.35108437524096,
    link: "https://goo.gl/maps/5NQhHM8HAgGGAuVM9",
  },
  {
    id: 638,
    name: "Maxi",
    address: "300, Boul St Joseph, Hull, QC J8Y 3Y3",
    icon: "cart",
    lat: 45.439342820678235,
    lng: -75.73294245995127,
    link: "https://goo.gl/maps/NhkdsQ8tjVSciV8z9",
  },
  {
    id: 639,
    name: "Maxi",
    address: "3025, Portland, Sherbrooke, QC J1L 2Y7",
    icon: "cart",
    lat: 45.404737491831334,
    lng: -71.95139118878811,
    link: "https://goo.gl/maps/YUwd3WzfqVTnFJcL7",
  },
  {
    id: 640,
    name: "Maxi",
    address: "10200, boul. Pie Ix, Montréal-Nord, QC H1H 3Z1",
    icon: "cart",
    lat: 45.58391566254305,
    lng: -73.63147464645219,
    link: "https://goo.gl/maps/7VoKK5c8YfhQaFts5",
  },
  {
    id: 641,
    name: "Maxi",
    address: "8200, boul. Lacroix, St-Georges de Beauce, QC G5Y 2B5",
    icon: "cart",
    lat: 46.13395163175722,
    lng: -70.7021963580762,
    link: "https://goo.gl/maps/sndMg9vwCnSKRm7u8",
  },
  {
    id: 642,
    name: "Maxi",
    address: "1100, Montée Masson, Mascouche, QC J7K 2L8",
    icon: "cart",
    lat: 45.74798566138205,
    lng: -73.60700317158478,
    link: "https://goo.gl/maps/uArwsAfKehtptg8c8",
  },
  {
    id: 643,
    name: "Maxi",
    address: "545, chemin Vanier, Gatineau, QC J9J 1Z4",
    icon: "cart",
    lat: 45.41785084481855,
    lng: -75.80923918878757,
    link: "https://goo.gl/maps/uPqCGePXoyJn9mcSA",
  },
  {
    id: 644,
    name: "Maxi",
    address: "1870, boul. St-Joseph, Drummondville, QC J2B 1R2",
    icon: "cart",
    lat: 45.879820767460004,
    lng: -72.48782200226343,
    link: "https://goo.gl/maps/6BzZwxYZJiE9HjUp6",
  },
  {
    id: 645,
    name: "Maxi",
    address: "1035, boul, du Quartier, Brossard, QC J4Y 0B4",
    icon: "cart",
    lat: 45.45703659562521,
    lng: -73.41878654460876,
    link: "https://goo.gl/maps/x9Q14dFmFSqxCfUL7",
  },
  {
    id: 646,
    name: "Maxi",
    address: "2925, rue Sherbrooke Est, Montréal, QC H1W 1B2",
    icon: "cart",
    lat: 45.54129938332848,
    lng: -73.55951100227648,
    link: "https://goo.gl/maps/4MQTWo9XxeHsUSXt5",
  },
  {
    id: 647,
    name: "Maxi",
    address: "550, boul. St-Laurent Est, Louiseville, QC J5V 2R5",
    icon: "cart",
    lat: 46.25656288996725,
    lng: -72.92692585012276,
    link: "https://goo.gl/maps/zVQbHfHL5Azdct348",
  },
  {
    id: 648,
    name: "Maxi",
    address: "1083, Curé-Labelle, Blainville, QC J7C 3M9",
    icon: "cart",
    lat: 45.676975956729756,
    lng: -73.88699147713233,
    link: "https://goo.gl/maps/QHyGRZR2jPKTjSy59",
  },
  {
    id: 649,
    name: "Maxi",
    address: "345, boul. Sir-Wilfrid-Laurier, Mont-Saint-Hilaire, QC J3H 3N8",
    icon: "cart",
    lat: 45.56329520894778,
    lng: -73.1894908157692,
    link: "https://goo.gl/maps/p4bsitCG3vWAgsLW6",
  },
  {
    id: 650,
    name: "Maxi",
    address: "130, Route Racette, St-Augustin, QC G3A 1V9",
    icon: "cart",
    lat: 46.74064443922732,
    lng: -71.46747135805265,
    link: "https://goo.gl/maps/XwfjyT1zLSKJKA3N7",
  },
  {
    id: 651,
    name: "Maxi",
    address: "2101, rue Dollard, B15  Pl. Newman, Lasalle, QC H8N 1S2",
    icon: "cart",
    lat: 45.43284720201727,
    lng: -73.63416800228059,
    link: "https://goo.gl/maps/fmagAiN5F64Vv9Rh9",
  },
  {
    id: 652,
    name: "Maxi",
    address: "1699, Estimauville, Giffard, QC G1J 5E2",
    icon: "cart",
    lat: 46.84402362231185,
    lng: -71.21435932366823,
    link: "https://goo.gl/maps/jeYZeZL79hn8AqEU8",
  },
  {
    id: 653,
    name: "Maxi",
    address: "101, Taché ouest, Montmagny, QC G5V 3T8",
    icon: "cart",
    lat: 46.981190011119985,
    lng: -70.56984208687864,
    link: "https://goo.gl/maps/Su3q57b392cAcbeQA",
  },
  {
    id: 654,
    name: "Maxi",
    address: "1757, Boul Marcel-Laurin, St-Laurent, QC H4R 1J5",
    icon: "cart",
    lat: 45.51745169748192,
    lng: -73.69801139118755,
    link: "https://goo.gl/maps/JfVzmv23X7GJEnsw6",
  },
  {
    id: 655,
    name: "Maxi",
    address: "92, Cardinal Leger, Pincourt, QC J7V 3Y4",
    icon: "cart",
    lat: 45.38395850679969,
    lng: -73.99114091762442,
    link: "https://goo.gl/maps/MCzoXm761uY2Vg5Y6",
  },
  {
    id: 656,
    name: "Maxi",
    address: "7455, rue Sherbrooke Est, Montréal, QC H1N 1E8",
    icon: "cart",
    lat: 45.59240123773243,
    lng: -73.54155854460348,
    link: "https://goo.gl/maps/9cvfZBWnjN6vF42y6",
  },
  {
    id: 657,
    name: "Maxi",
    address: "3398, boul Taschereau, Greenfield Park, QC J4V 2H7",
    icon: "cart",
    lat: 45.49347752054052,
    lng: -73.47916991762017,
    link: "https://goo.gl/maps/KDacpWC2vBtesm9T7",
  },
  {
    id: 658,
    name: "Maxi",
    address: "1345, boul. Moody, Terrebonne, QC J6W 3L1",
    icon: "cart",
    lat: 45.70466324888436,
    lng: -73.6493719176121,
    link: "https://goo.gl/maps/ivCBPxmz3535zLZ2A",
  },
  {
    id: 659,
    name: "Maxi",
    address: "310, Avenue Dorval, Dorval, QC H9S 3H7",
    icon: "cart",
    lat: 45.44478874419934,
    lng: -73.74153835810289,
    link: "https://goo.gl/maps/JJ9AKP5muUAyrjV27",
  },
  {
    id: 660,
    name: "Maxi",
    address: "3150, Remembrance, Lachine, QC H8S 1X8",
    icon: "cart",
    lat: 45.439533123645184,
    lng: -73.69140873111587,
    link: "https://goo.gl/maps/3cQBVchkTKubDDpR9",
  },
  {
    id: 661,
    name: "Maxi",
    address: "2655, chemin Chambly, Longueuil, QC J4L 1M3",
    icon: "cart",
    lat: 45.524924828150155,
    lng: -73.4665926292642,
    link: "https://goo.gl/maps/Twsj9QmFohivFcpq6",
  },
  {
    id: 662,
    name: "Maxi",
    address: "699, rue Clémenceau, Beauport, QC G1C 4N6",
    icon: "cart",
    lat: 46.87404196784873,
    lng: -71.20024325804738,
    link: "https://goo.gl/maps/VJRNPwkd3R5zmpRCA",
  },
  {
    id: 663,
    name: "Maxi",
    address: "900, Boul Grignon, St-Jérôme, QC J7Y 3S7",
    icon: "cart",
    lat: 45.79412695454729,
    lng: -74.01813545993767,
    link: "https://goo.gl/maps/S9dvt39mUko4QBgUA",
  },
  {
    id: 664,
    name: "Maxi",
    address: "1030, boul. Vachon Nord, Ste Marie de Beauce, QC G6E 1M5",
    icon: "cart",
    lat: 46.452323463255,
    lng: -71.03237230778613,
    link: "https://goo.gl/maps/psfy7ny4QJo3d6Qf8",
  },
  {
    id: 665,
    name: "Maxi",
    address: "444, boul. Curé-Labelle, Laval, QC H7P 2P1",
    icon: "cart",
    lat: 45.57860788480658,
    lng: -73.78672430781995,
    link: "https://goo.gl/maps/kSeqTiPeDwAQt3ny7",
  },
  {
    id: 666,
    name: "Maxi",
    address: "7900, boul. Cousineau, St-Hubert, QC J3Z 1H2",
    icon: "cart",
    lat: 45.48173947168023,
    lng: -73.38604408693689,
    link: "https://goo.gl/maps/LfQBDeUpxyJKJfwh9",
  },
  {
    id: 667,
    name: "Maxi",
    address: "205, 1ière Avenue, Val-des-Sources, QC J1T 1Y3",
    icon: "cart",
    lat: 45.774813541354504,
    lng: -71.93773791760943,
    link: "https://goo.gl/maps/zdMajEmuJ82iFYqMA",
  },
  {
    id: 668,
    name: "Maxi",
    address: "7200, boul. Taschereau, Brossard, QC J4W 1N1",
    icon: "cart",
    lat: 45.462239678991274,
    lng: -73.46778391762139,
    link: "https://goo.gl/maps/3c94dj6bfz3gxeZv7",
  },
  {
    id: 669,
    name: "Maxi",
    address: "50, route Président-Kennedey, St 190, Lévis, QC G6V 6W8",
    icon: "cart",
    lat: 46.79417957351765,
    lng: -71.17800373106331,
    link: "https://goo.gl/maps/spaGeRdq38WEsopU6",
  },
  {
    id: 670,
    name: "Maxi",
    address: "2260, Chemin Gascon, Terrebonne, QC J6X 3A5",
    icon: "cart",
    lat: 45.72275117372104,
    lng: -73.6633167311049,
    link: "https://goo.gl/maps/bVRjnMk3uoeyGFWt9",
  },
  {
    id: 671,
    name: "Maxi",
    address: "255, 3 ieme Rue Est, Lasarre, QC J9Z 3N7",
    icon: "cart",
    lat: 48.80620876579501,
    lng: -79.19731230270325,
    link: "https://goo.gl/maps/6pMJYNLcq1JLU28D7",
  },
  {
    id: 672,
    name: "Maxi",
    address: "1200, boul. Ducharme, LaTuque, QC G9X 3Z9",
    icon: "cart",
    lat: 47.41759179685935,
    lng: -72.78409883103882,
    link: "https://goo.gl/maps/K2eTV7jdcqp64ikJA",
  },
  {
    id: 673,
    name: "Maxi",
    address: "2501, boul. du Millénaire, St-Basile-le-Grand, QC J3N 1Z8",
    icon: "cart",
    lat: 45.52647234023341,
    lng: -73.3038103580997,
    link: "https://goo.gl/maps/9M4R5EgLt6nB5NkL8",
  },
  {
    id: 674,
    name: "Maxi",
    address: "520, Fort St-Louis, Boucherville, QC J4B 1S5",
    icon: "cart",
    lat: 45.609047645825335,
    lng: -73.45116503665423,
    link: "https://goo.gl/maps/An6TRpih9qXjd2Kf9",
  },
  {
    id: 675,
    name: "Maxi",
    address: "482, route 138, Donnacona, QC G3M 1C2",
    icon: "cart",
    lat: 46.68136835432495,
    lng: -71.72090781572592,
    link: "https://goo.gl/maps/YdFMmxUutoVvWAm29",
  },
  {
    id: 676,
    name: "Maxi",
    address: "4325, Jean-Talon Est, Montréal, QC H1S 1J9",
    icon: "cart",
    lat: 45.570331253519065,
    lng: -73.59049073111078,
    link: "https://goo.gl/maps/ZUFM4g22Qe8Evu5E9",
  },
  {
    id: 677,
    name: "Maxi",
    address: "339, Boul Labelle, Rosemère, QC J7A 2H7",
    icon: "cart",
    lat: 45.63016555307872,
    lng: -73.81359600227306,
    link: "https://goo.gl/maps/afxtAki64xghMGRq9",
  },
  {
    id: 678,
    name: "Maxi",
    address: "885, 3 rue, Route 167, Chibougamau, QC G8P 1R3",
    icon: "cart",
    lat: 49.913706063022815,
    lng: -74.3842423579251,
    link: "https://goo.gl/maps/eCH2tRKe5Nj2Tvfc9",
  },
  {
    id: 679,
    name: "Maxi",
    address: "3399, rue Queen, Rawdon, QC J0K 1S0",
    icon: "cart",
    lat: 46.03767596570823,
    lng: -73.69988300225727,
    link: "https://goo.gl/maps/t4HeugWynmz79Acd9",
  },
  {
    id: 680,
    name: "Maxi",
    address: "450, boul. Poliquin, St-Pierre-Sorel, QC J3P 7R5",
    icon: "cart",
    lat: 46.023495470868845,
    lng: -73.10374964643525,
    link: "https://goo.gl/maps/EDvQYq8mcAjjGMqx5",
  },
  {
    id: 681,
    name: "Maxi",
    address: "390 route 117, Mont Tremblant, QC J8E 2X3",
    icon: "cart",
    lat: 46.11796521466915,
    lng: -74.57100491759606,
    link: "https://goo.gl/maps/aQCrhFqQDpPvV6SJ7",
  },
  {
    id: 682,
    name: "Maxi",
    address: "1000 Suite 6, boul. Du Séminaire, St-Jean, QC J3A 1E5",
    icon: "cart",
    lat: 45.33715908758003,
    lng: -73.27006663881333,
    link: "https://goo.gl/maps/E72Eq5sY6chQYagw9",
  },
  {
    id: 683,
    name: "Maxi",
    address: "3880, Boul. Harvey, Jonquière, QC G7X 8R6",
    icon: "cart",
    lat: 48.414538135009785,
    lng: -71.25233214097776,
    link: "https://goo.gl/maps/SJYpTi6BV7583gL29",
  },
  {
    id: 684,
    name: "Maxi",
    address: "805, boul. Frontenac Est, Thetford Mines, QC G6G 6L5",
    icon: "cart",
    lat: 46.113021546351405,
    lng: -71.30145827526721,
    link: "https://goo.gl/maps/oEkL3hMcqA76DU2Y7",
  },
  {
    id: 685,
    name: "Maxi",
    address: "15 000, rue St-Louis, St-Hyacinthe, QC J2T 3E2",
    icon: "cart",
    lat: 45.629086562688805,
    lng: -72.93833910412143,
    link: "https://goo.gl/maps/TkUnioyUkhr84dQw6",
  },
  {
    id: 686,
    name: "Maxi",
    address: "2090 Boulevard des Laurentides, Laval, QC H7M 2Y6",
    icon: "cart",
    lat: 45.60704511452473,
    lng: -73.73556627343848,
    link: "https://goo.gl/maps/ef77fkhmAToZpXri8",
  },
  {
    id: 687,
    name: "Maxi",
    address: "1220, boulevard Louis-XIV, Charlesbourg, QC G1H 6V6",
    icon: "cart",
    lat: 46.87034635353204,
    lng: -71.25498800222486,
    link: "https://goo.gl/maps/W5wFjHRgyF9Q8uVD8",
  },
  {
    id: 688,
    name: "Maxi",
    address: "70, rue Dufferin, Valleyfield, QC J6S 1Y2",
    icon: "cart",
    lat: 45.26309697350068,
    lng: -74.12839881578064,
    link: "https://goo.gl/maps/rFPmq2QMUjnbx1rJA",
  },
  {
    id: 689,
    name: "Maxi",
    address: "570, boul. Laflèche, Baie-Comeau, QC G5C 1C3",
    icon: "cart",
    lat: 49.20295922015908,
    lng: -68.25589227329611,
    link: "https://goo.gl/maps/ybpJxUDm8nMRzhbL6",
  },
  {
    id: 690,
    name: "Maxi",
    address: "12 780, rue Sherbrooke Est, Pointe-Aux-Trembles, QC H1A 4Y3",
    icon: "cart",
    lat: 45.6537399609583,
    lng: -73.51042445994311,
    link: "https://goo.gl/maps/rrsUFjApHbPUa35M8",
  },
  {
    id: 691,
    name: "Maxi",
    address: "1643, boul. D'Youville, Shawinigan, QC G9N 6V4",
    icon: "cart",
    lat: 46.55641583823793,
    lng: -72.75197315621152,
    link: "https://goo.gl/maps/NYrSyuViLLGYeaG77",
  },
  {
    id: 692,
    name: "Maxi",
    address: "845, Avenue Du Pont-Nord, Alma, QC G8B 6W6",
    icon: "cart",
    lat: 48.56563717758804,
    lng: -71.64029473099293,
    link: "https://goo.gl/maps/rQfiqHySXrL1mZEL6",
  },
  {
    id: 693,
    name: "Maxi",
    address: "320, rue Barkoff, Cap-de-la-Madeleine, QC G8T 2A3",
    icon: "cart",
    lat: 46.371992460574,
    lng: -72.55895381573792,
    link: "https://goo.gl/maps/cb5ekCCZLXDq51Tc6",
  },
  {
    id: 694,
    name: "Maxi",
    address: "7081, Newman, Lasalle, QC H8N 1X1",
    icon: "cart",
    lat: 45.43767671419507,
    lng: -73.62444827344498,
    link: "https://goo.gl/maps/gssPZv6TnZi8BDxt8",
  },
  {
    id: 695,
    name: "Maxi",
    address: "6381, Route Transcanadienne, Pointe-Claire, QC H9R 5A5",
    icon: "cart",
    lat: 45.48157272926636,
    lng: -73.79035295108265,
    link: "https://goo.gl/maps/A1S1E8b2DheNyNkJA",
  },
  {
    id: 696,
    name: "Maxi",
    address: "5875, boul. Jean XXIII, Trois-Rivières Ouest, QC G8Z 4N8",
    icon: "cart",
    lat: 46.33042481862498,
    lng: -72.5928479179467,
    link: "https://goo.gl/maps/6Ra8ZWSfydaYTNoW9",
  },
  {
    id: 697,
    name: "Extra Foods Corporate",
    address: "5701-50th Ave., St Paul, AB T0A 3A1",
    icon: "cart",
    lat: 53.990453674721074,
    lng: -111.3176986212461,
    link: "https://goo.gl/maps/QsAoa2BG8jyHA4z28",
  },
  {
    id: 698,
    name: "Extra Foods Corporate",
    address: "828-9th St. W., Meadow Lake, SK S9X 1S9",
    icon: "cart",
    lat: 54.12239976231732,
    lng: -108.45196327308872,
    link: "https://goo.gl/maps/N2v6y5HomLiUpCPJ9",
  },
  {
    id: 699,
    name: "Extra Foods Corporate",
    address: "Box 1240 1252 Highway 9 South, Drumheller, AB T0J 0Y0",
    icon: "cart",
    lat: 51.445255273280274,
    lng: -112.70255627320334,
    link: "https://goo.gl/maps/dFTmwLKaUaYzQPpN9",
  },
  {
    id: 700,
    name: "The Sweet Potato",
    address: "108 Vine Avenue, Unit B Toronto, ON M6P 1V7",
    icon: "cart",
    lat: 43.66688819689026,
    lng: -79.46849181620206,
    link: "https://g.page/thesweetpotato?share",
  },
  {
    id: 701,
    name: "Foodland – Port Carling",
    address: "10 Bruce Wilson Drive, Port Carling, ON P0B 1J0",
    icon: "cart",
    lat: 45.117655529918665,
    lng: -79.58598754440187,
    link: "https://goo.gl/maps/J8CSSurKUMGfSnXF9",
  },
  {
    id: 702,
    name: "The Farmers Market",
    address: "83 Bowes Street, Parry Sound ON P2A 2X4",
    icon: "cart",
    lat: 45.342632994193075,
    lng: -80.01889067380932,
    link: "https://goo.gl/maps/cZa6qmrMPdZ8CJZe6",
  },
  {
    id: 703,
    name: "The Local Market",
    address: "1434 Queen Street West, Toronto ON M6K 1M2",
    icon: "cart",
    lat: 43.64108992052572,
    lng: -79.43569386037831,
    link: "https://goo.gl/maps/SsmvKq2VqCn5pe4m6",
  },
  {
    id: 704,
    name: "Organic Garage – Oakville",
    address: "579 Kerr Street, Oakville ON L6K 3E1",
    icon: "cart",
    lat: 43.44895768508862,
    lng: -79.68836185669437,
    link: "https://goo.gl/maps/d3xCvWLJmVp2Vwre8",
  },
  {
    id: 705,
    name: "Organic Garage – The Junction",
    address: "43 Junction Rd, Toronto ON M6N 1B5",
    icon: "cart",
    lat: 43.66781119841079,
    lng: -79.46327981620198,
    link: "https://goo.gl/maps/31U6Vn5pHBdSzcSm9",
  },
  {
    id: 706,
    name: "Organic Garage Thornhill",
    address: "8020 Bathurst St #1, Thornhill ON L4J 0B8",
    icon: "cart",
    lat: 43.81613955199494,
    lng: -79.45278008736139,
    link: "https://goo.gl/maps/6WNhTKSiUCrSgRT48",
  },
  {
    id: 707,
    name: "Fortinos",
    address: "330 Queens Plate Drive, Etobicoke ON M9W 7J7",
    icon: "cart",
    lat: 43.721631824351896,
    lng: -79.59630173096146,
    link: "https://goo.gl/maps/mmvweLMe7YSFAsnp7",
  },
  {
    id: 708,
    name: "Fortinos",
    address: "21 Upper Centennial Pkwy S., Stoney Creek, ON L8J 3W2",
    icon: "cart",
    lat: 43.17547516531232,
    lng: -79.77726164632591,
    link: "https://goo.gl/maps/Rp6iQpTS7E2ujUp47",
  },
  {
    id: 709,
    name: "Fortinos",
    address: "65 Mall Road, Hamilton, ON L8V 5B5",
    icon: "cart",
    lat: 43.22107270976805,
    lng: -79.85941827330697,
    link: "https://goo.gl/maps/1fvM8PWwQn6VB57q9",
  },
  {
    id: 710,
    name: "Fortinos",
    address: "8585 Highway 27, RR3 Vaughan ON L4L 1V2",
    icon: "cart",
    lat: 43.79307365934195,
    lng: -79.62361830026917,
    link: "https://goo.gl/maps/NAJ4k3su6Hvp9BdY7",
  },
  {
    id: 711,
    name: "Fortinos",
    address: "1550 Upper James Street, Hamilton ON L9B 1K3",
    icon: "cart",
    lat: 43.2038355943823,
    lng: -79.89544135796281,
    link: "https://goo.gl/maps/zFDrg4nt99JD5Rj38",
  },
  {
    id: 712,
    name: "Fortinos",
    address: "1579 Main Street West, Hamilton ON L8S 1E6",
    icon: "cart",
    lat: 43.25548544282393,
    lng: -79.92883581563335,
    link: "https://goo.gl/maps/3QArAbi5qDRXfPyj8",
  },
  {
    id: 713,
    name: "Fortinos",
    address: "1059 Plains Road East, Burlington ON L7T 4K1",
    icon: "cart",
    lat: 43.32672612725932,
    lng: -79.83083367938046,
    link: "https://goo.gl/maps/j63Vwj3E62336jmLA",
  },
  {
    id: 714,
    name: "Fortinos",
    address: "2911 Major Mackenzie Drive, Vaughn ON L6A 3N9",
    icon: "cart",
    lat: 43.84984969425088,
    lng: -79.53269390026719,
    link: "https://goo.gl/maps/uxwynpEuaLroE6857",
  },
  {
    id: 715,
    name: "Fortinos",
    address: "54 Wilson Street West, Ancaster ON L9G 1N2",
    icon: "cart",
    lat: 43.21676426920048,
    lng: -79.98914981563472,
    link: "https://goo.gl/maps/7zjmJeVEdmUsfwfq7",
  },
  {
    id: 716,
    name: "Fortinos",
    address: "3940 Highway 7 R.R. #2 Vaughan ON L4L 1A6",
    icon: "cart",
    lat: 43.789134645198295,
    lng: -79.55519361324392,
    link: "https://goo.gl/maps/FikB58AwYurdDuAk8",
  },
  {
    id: 717,
    name: "Fortinos",
    address: "102 Highway #8 Stoney Creek ON L8G 4H3",
    icon: "cart",
    lat: 43.224435061503826,
    lng: -79.74857227330686,
    link: "https://goo.gl/maps/QjBKokBGFH5ymvXc7",
  },
  {
    id: 718,
    name: "Fortinos",
    address: "493 Dundas St. West Oakville ON L6M 4M2",
    icon: "cart",
    lat: 43.467128966010975,
    lng: -79.7457630537295,
    link: "https://goo.gl/maps/R1xeDNit3CwRmpK67",
  },
  {
    id: 719,
    name: "Fortinos",
    address: "75 Centennial Pkwy North, Hamilton ON L8E 2P2",
    icon: "cart",
    lat: 43.23102567275117,
    lng: -79.76592191748773,
    link: "https://goo.gl/maps/jRZofcPhBxUsw5R9A",
  },
  {
    id: 720,
    name: "Fortinos",
    address: "60 Quarry Edge Drive, Brampton ON L6V 4K2",
    icon: "cart",
    lat: 43.70776756870186,
    lng: -79.78247754445333,
    link: "https://goo.gl/maps/MXRY31kDGyHC226x6",
  },
  {
    id: 721,
    name: "Fortinos",
    address: "2025 Guelph Line, Burlington ON L7P 4M8",
    icon: "cart",
    lat: 43.366997366790685,
    lng: -79.82253791748288,
    link: "https://goo.gl/maps/GrmywmJWtWf6uWwm6",
  },
  {
    id: 722,
    name: "Fortinos",
    address: "115 Hamilton Street, North Waterdown ON L0R 2H6",
    icon: "cart",
    lat: 43.33591026441235,
    lng: -79.9034765616651,
    link: "https://goo.gl/maps/kyjDdJw7zYu8EM4a6",
  },
  {
    id: 723,
    name: "Fortinos",
    address: "700 Lawrence Ave. West, North York ON M6A 3B4",
    icon: "cart",
    lat: 43.71678780446652,
    lng: -79.44724627328917,
    link: "https://goo.gl/maps/Pz8sCw9WMShwVDXB8",
  },
  {
    id: 724,
    name: "Fortinos",
    address: "35 Worthington Avenue, Brampton ON L7A 2Y7",
    icon: "cart",
    lat: 43.68372339462477,
    lng: -79.8143957468244,
    link: "https://goo.gl/maps/aYjsuoxzCWeZA6826",
  },
  {
    id: 725,
    name: "Fortinos",
    address: "2515 Appleby Line, Burlington ON L7R 0B6",
    icon: "cart",
    lat: 43.407586043202436,
    lng: -79.80502837515384,
    link: "https://goo.gl/maps/8r6WnRGuixWmNFA7A",
  },
  {
    id: 726,
    name: "Fortinos",
    address: "B-50 Dundurn Street, Hamilton ON L8P 4W3",
    icon: "cart",
    lat: 43.26190186637726,
    lng: -79.88959945981416,
    link: "https://goo.gl/maps/bNc86UcD1ps5cizR9",
  },
  {
    id: 727,
    name: "Fortinos",
    address: "5111 New Street Burlington, ON L7L 1V2",
    icon: "cart",
    lat: 43.369689357098245,
    lng: -79.75327335795684,
    link: "https://goo.gl/maps/vAuxr9K2btLTNhDr9",
  },
  {
    id: 728,
    name: "Fortinos",
    address: "173 Lakeshore Road West, Oakville ON L6K 1E7",
    icon: "cart",
    lat: 43.4382197389754,
    lng: -79.67718145980791,
    link: "https://goo.gl/maps/wWzG1DtFWs6RrpRZ9",
  },
  {
    id: 729,
    name: "The Organic Grocer",
    address: "508–7380 King George Blvd, Surrey BC V3W 5A5",
    icon: "cart",
    lat: 49.13674166881936, 
    lng: -122.84476554105048,
    link: "https://goo.gl/maps/kVUnv3aozJRz5o2t5",
  },
  {
    id: 730,
    name: "Pomme Natural Marker",
    address: "#9 - 2755 Lougheed Hwy, Port Coquitlam BC V3B 5Y9",
    icon: "cart",
    lat: 49.27482598795116, 
    lng: -122.78920023863516,
    link: "https://g.page/pomme-natural-market-poco?share",
  },
  {
    id: 731,
    name: "Pomme Natural Marker",
    address: "6560 Metral Drive, Nanaimo BC V9T 2L9",
    icon: "cart",
    lat: 49.234098020119305, 
    lng: -124.04774103863515,
    link: "https://goo.gl/maps/NEAmxuJzYpYGcTxs5",
  },
 


];
